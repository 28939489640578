// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum RestoreSessionType {
    Exchange = 0,
    OneDrive = 1,
    SharePoint = 2,
    Teams = 3
}
