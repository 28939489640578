/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTJobSession,
    RESTExceptionInfo,
    RESTJobSession,
    RESTJobSessionType,
} from '../models';

export interface JobSessionApiJobSessionGetRequest {
    limit: number;
    offset: number;
    jobId?: string;
    jobType?: RESTJobSessionType;
    endTimeLowerBound?: string;
    endTimeUpperBound?: string;
    status?: JobSessionGetStatusEnum;
}

export interface JobSessionApiJobSessionGetByIdRequest {
    jobSessionsId: string;
}

/**
 * no description
 */
export const jobSessionApi = ({

    /**
     * Returns a collection of all job sessions created for backup and backup copy jobs or a backup job with the specified ID.
     * Get Job Sessions
     */
    jobSessionGet: ({ limit, offset, jobId, jobType, endTimeLowerBound, endTimeUpperBound, status }: JobSessionApiJobSessionGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTJobSession>> => {
        throwIfNullOrUndefined(limit, 'limit', 'jobSessionGet');
        throwIfNullOrUndefined(offset, 'offset', 'jobSessionGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (jobId != null) { query['jobId'] = jobId; }
        if (jobType != null) { query['jobType'] = jobType; }
        if (endTimeLowerBound != null) { query['endTimeLowerBound'] = (endTimeLowerBound as any).toISOString(); }
        if (endTimeUpperBound != null) { query['endTimeUpperBound'] = (endTimeUpperBound as any).toISOString(); }
        if (status != null) { query['status'] = status; }

        return requestAuthenticated<PageOfRESTJobSession>({
            url: '/v8/JobSessions',
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a job session with the specified ID.
     * Get Job Session by Session ID
     */
    jobSessionGetById: ({ jobSessionsId }: JobSessionApiJobSessionGetByIdRequest, option?: RequestOption): Observable<Optional<RESTJobSession>> => {
        throwIfNullOrUndefined(jobSessionsId, 'jobSessionsId', 'jobSessionGetById');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTJobSession>({
            url: '/v8/JobSessions/{jobSessionsId}'.replace('{jobSessionsId}', encodeURI(jobSessionsId)),
            method: 'GET',
            headers,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum JobSessionGetStatusEnum {
    Stopped = 'Stopped',
    Running = 'Running',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
    Warning = 'Warning'
}
