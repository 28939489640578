/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRestTeamsTab,
    RESTAsyncRestoreResponse,
    RESTExceptionInfo,
    RESTOperatorRestoreTabsOptions,
    RESTRestoreTabOptions,
    RESTRestoreTabsOptions,
    RESTSearchOptions,
    RESTTeamsOperatorRestoreSessionResponse,
    RestTeamsTab,
} from '../models';

export interface TeamsTabApiTeamsTabGetRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    offset: number;
    limit: number;
}

export interface TeamsTabApiTeamsTabGetTabRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    tabId: string;
}

export interface TeamsTabApiTeamsTabOperatorRestoreRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    action: RESTOperatorRestoreTabsOptions;
}

export interface TeamsTabApiTeamsTabOperatorRestoreByMultipleChannelsRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTOperatorRestoreTabsOptions;
}

export interface TeamsTabApiTeamsTabRestoreRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    action: RESTRestoreTabsOptions;
}

export interface TeamsTabApiTeamsTabRestoreByIdRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    tabId: string;
    action: RESTRestoreTabOptions;
}

export interface TeamsTabApiTeamsTabSearchRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    offset: number;
    limit: number;
    action: RESTSearchOptions;
}

/**
 * no description
 */
export const teamsTabApi = ({

    /**
     * Returns a collection of backed-up Microsoft Teams channel tabs to explore and restore.
     * Get Tabs
     */
    teamsTabGet: ({ restoreSessionId, teamId, channelId, offset, limit }: TeamsTabApiTeamsTabGetRequest, option?: RequestOption): Observable<Optional<PageOfRestTeamsTab>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTabGet');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTabGet');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsTabGet');
        throwIfNullOrUndefined(offset, 'offset', 'teamsTabGet');
        throwIfNullOrUndefined(limit, 'limit', 'teamsTabGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRestTeamsTab>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/tabs'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up Microsoft Teams channel tab with the specified ID.
     * Get Tab
     */
    teamsTabGetTab: ({ restoreSessionId, teamId, channelId, tabId }: TeamsTabApiTeamsTabGetTabRequest, option?: RequestOption): Observable<Optional<RestTeamsTab>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTabGetTab');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTabGetTab');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsTabGetTab');
        throwIfNullOrUndefined(tabId, 'tabId', 'teamsTabGetTab');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RestTeamsTab>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/tabs/{tabId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)).replace('{tabId}', encodeURI(tabId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Restores backed-up Microsoft Teams tabs of a channel with the specified ID using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=80) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong> </br> To restore data using Restore Portal, you must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div> 
     * Restore Channel Tabs by Restore Operator
     */
    teamsTabOperatorRestore: ({ restoreSessionId, teamId, channelId, action }: TeamsTabApiTeamsTabOperatorRestoreRequest, option?: RequestOption): Observable<Optional<RESTTeamsOperatorRestoreSessionResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTabOperatorRestore');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTabOperatorRestore');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsTabOperatorRestore');
        throwIfNullOrUndefined(action, 'action', 'teamsTabOperatorRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTTeamsOperatorRestoreSessionResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/tabs/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Restores backed-up Microsoft Teams tabs of multiple channels using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=80) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong> </br> To restore data using Restore Portal, you must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div> 
     * Restore Tabs of Multiple Channels by Restore Operator
     */
    teamsTabOperatorRestoreByMultipleChannels: ({ restoreSessionId, teamId, action }: TeamsTabApiTeamsTabOperatorRestoreByMultipleChannelsRequest, option?: RequestOption): Observable<Optional<RESTTeamsOperatorRestoreSessionResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTabOperatorRestoreByMultipleChannels');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTabOperatorRestoreByMultipleChannels');
        throwIfNullOrUndefined(action, 'action', 'teamsTabOperatorRestoreByMultipleChannels');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTTeamsOperatorRestoreSessionResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/tabs/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Restores backed-up Microsoft Teams channel tabs.
     * Restore Tabs
     */
    teamsTabRestore: ({ restoreSessionId, teamId, channelId, action }: TeamsTabApiTeamsTabRestoreRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTabRestore');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTabRestore');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsTabRestore');
        throwIfNullOrUndefined(action, 'action', 'teamsTabRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/tabs/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Restores a backed-up Microsoft Teams channel tab with the specified ID.
     * Restore Tab
     */
    teamsTabRestoreById: ({ restoreSessionId, teamId, channelId, tabId, action }: TeamsTabApiTeamsTabRestoreByIdRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTabRestoreById');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTabRestoreById');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsTabRestoreById');
        throwIfNullOrUndefined(tabId, 'tabId', 'teamsTabRestoreById');
        throwIfNullOrUndefined(action, 'action', 'teamsTabRestoreById');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/tabs/{tabId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)).replace('{tabId}', encodeURI(tabId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Searches for backed-up Microsoft Teams channel tabs.
     * Search for Tabs
     */
    teamsTabSearch: ({ restoreSessionId, teamId, channelId, offset, limit, action }: TeamsTabApiTeamsTabSearchRequest, option?: RequestOption): Observable<Optional<PageOfRestTeamsTab>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTabSearch');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTabSearch');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsTabSearch');
        throwIfNullOrUndefined(offset, 'offset', 'teamsTabSearch');
        throwIfNullOrUndefined(limit, 'limit', 'teamsTabSearch');
        throwIfNullOrUndefined(action, 'action', 'teamsTabSearch');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRestTeamsTab>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/tabs/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'POST',
            headers,
            query,
            body: action,
        }, option);
    },

})
