// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTRbacGroup
 */
export interface RESTRbacGroup {
    /**
     * ID of the organization group.
     * @type {string}
     * @memberof RESTRbacGroup
     */
    id: string;
    /**
     * ID of the organization group in the on-premises organization.
     * @type {string}
     * @memberof RESTRbacGroup
     */
    onPremisesSid?: string | null;
    /**
     * Display name of the organization group.
     * @type {string}
     * @memberof RESTRbacGroup
     */
    displayName: string;
    /**
     * Name of the organization group.
     * @type {string}
     * @memberof RESTRbacGroup
     */
    name: string | null;
    /**
     * Type of the organization group.
     * @type {string}
     * @memberof RESTRbacGroup
     */
    type: RESTRbacGroupTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRbacGroupTypeEnum {
    Office365 = 'Office365',
    Security = 'Security',
    Distribution = 'Distribution',
    DynamicDistribution = 'DynamicDistribution',
    Unknown = 'Unknown'
}

