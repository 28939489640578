// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTM365BackupStorageRestoreSession
 */
export interface RESTM365BackupStorageRestoreSession {
    /**
     * Restore session ID.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    id: string;
    /**
     * Name of the restore session.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    name?: string | null;
    /**
     * Name of the Microsoft 365 organization.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    organization?: string | null;
    /**
     * Date and time when the restore session was started.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    creationTime: string;
    /**
     * Date and time when the restore session ended.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    endTime?: string | null;
    /**
     * State of the restore session.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    state: RESTM365BackupStorageRestoreSessionStateEnum;
    /**
     * Result of the restore session.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    result: RESTM365BackupStorageRestoreSessionResultEnum;
    /**
     * Status of the restore session reported by Microsoft.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    msStatus?: RESTM365BackupStorageRestoreSessionMsStatusEnum;
    /**
     * Name of the user that initiated the restore session.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    initiatedBy?: string | null;
    /**
     * Restore session details.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    details?: string | null;
    /**
     * Reason to perform restore operation.
     * @type {string}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    reason?: string | null;
    /**
     * Related resources.
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTM365BackupStorageRestoreSession
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStorageRestoreSessionStateEnum {
    Working = 'Working',
    Stopped = 'Stopped'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStorageRestoreSessionResultEnum {
    Success = 'Success',
    Warning = 'Warning',
    Failed = 'Failed'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStorageRestoreSessionMsStatusEnum {
    None = 'None',
    Draft = 'Draft',
    Activating = 'Activating',
    Active = 'Active',
    CompletedWithError = 'CompletedWithError',
    Completed = 'Completed',
    Failed = 'Failed'
}

