// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTMonthlyReportInterval,
} from './';

/**
 * @export
 * @interface RESTReportParameters
 */
export interface RESTReportParameters {
    /**
     * ID of the report.
     * @type {number}
     * @memberof RESTReportParameters
     */
    reportID?: number;
    /**
     * Status of the report. The following values are available: <ul> <li>*Draft* - the report was not sent to Veeam.</li> <li>*Approved* - the report was sent to Veeam.</li> </ul> 
     * @type {string}
     * @memberof RESTReportParameters
     */
    reportStatus?: RESTReportParametersReportStatusEnum;
    /**
     * Name of the company to which the license was issued.
     * @type {string}
     * @memberof RESTReportParameters
     */
    companyName?: string;
    /**
     * Date and time when the license expires.
     * @type {string}
     * @memberof RESTReportParameters
     */
    licenseExpirationDate?: string;
    /**
     * ID of the installed license.
     * @type {string}
     * @memberof RESTReportParameters
     */
    licenseID?: string;
    /**
     * Support ID of the installed license.
     * @type {string}
     * @memberof RESTReportParameters
     */
    supportID?: string;
    /**
     * Date and time when the report was generated.
     * @type {string}
     * @memberof RESTReportParameters
     */
    reportGenerationDate?: string;
    /**
     * @type {RESTMonthlyReportInterval}
     * @memberof RESTReportParameters
     */
    reportingInterval?: RESTMonthlyReportInterval;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTReportParametersReportStatusEnum {
    Approved = 'Approved',
    Draft = 'Draft'
}

