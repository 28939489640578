// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTExploreOptions
 */
export interface RESTExploreOptions {
    /**
     * Specifies the date and time.
     * @type {string}
     * @memberof RESTExploreOptions
     */
    dateTime?: string | null;
    /**
     * Specifies a type of the restore session to start.
     * @type {string}
     * @memberof RESTExploreOptions
     */
    type?: RESTExploreOptionsTypeEnum;
    /**
     * Defines whether the restore session will show items that have been removed by the user before the specified date.
     * @type {boolean}
     * @memberof RESTExploreOptions
     */
    showDeleted?: boolean | null;
    /**
     * Defines whether the restore session will show all versions of items that have been modified by the user before the specified date.
     * @type {boolean}
     * @memberof RESTExploreOptions
     */
    showAllVersions?: boolean | null;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTExploreOptionsTypeEnum {
    Vex = 'Vex',
    Veod = 'Veod',
    Vesp = 'Vesp',
    Vet = 'Vet'
}

