// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RESTProxyRole {
    Unknown = 'Unknown',
    Orchestrator = 'Orchestrator',
    Scheduler = 'Scheduler',
    ApplianceProcessor = 'ApplianceProcessor',
    Processor = 'Processor'
}

