// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTTeamsFile,
} from './';

/**
 * @export
 * @interface RESTRestoreFilesOptions
 */
export interface RESTRestoreFilesOptions {
    /**
     * Specifies the ID of the channel whose files you want to restore. Veeam Explorer for Microsoft Teams will restore all files of this channel. For more information on how to get this parameter, see [Get Team Channels](#tag/TeamsChannel/operation/TeamsChannel_Get).  **Note**: You do not need to use this property if you use the `files` property to specify what files to restore. 
     * @type {string}
     * @memberof RESTRestoreFilesOptions
     */
    channelId?: string;
    /**
     * Specifies IDs of the files that you want to restore. The files must reside in the same channel. For more information on how to get such IDs, see [Get Files](#tag/TeamsFile/operation/TeamsFile_GetPage).  **Note**: You do not need to use this property if you use the `channelId` property to specify a channel whose files to restore. 
     * @type {Array<RESTTeamsFile>}
     * @memberof RESTRestoreFilesOptions
     */
    files?: Array<RESTTeamsFile>;
    /**
     * Defines whether to restore files that have been modified in the original location since the time when the backup was created.
     * @type {boolean}
     * @memberof RESTRestoreFilesOptions
     */
    restoreChangedItems: boolean | null;
    /**
     * Defines whether to restore files that are missed in the original location.
     * @type {boolean}
     * @memberof RESTRestoreFilesOptions
     */
    restoreMissingItems: boolean | null;
    /**
     * Specifies what version of files will be restored.
     * @type {string}
     * @memberof RESTRestoreFilesOptions
     */
    fileVersion: RESTRestoreFilesOptionsFileVersionEnum;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreFilesOptions
     */
    userCode?: string;
    /**
     * Specifies the ID of the Micrisoft Entra application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreFilesOptions
     */
    applicationId?: string | null;
    /**
     * Specifies the SSL certificate configured for the Microsoft Entra application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreFilesOptions
     */
    applicationCertificate?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreFilesOptions
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTRestoreFilesOptions
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreFilesOptions
     */
    userPassword?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreFilesOptionsFileVersionEnum {
    All = 'All',
    Last = 'Last'
}

