// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum RestorePointOrigin {
    Backup = 0,
    ArchiveCopy = 1,
    Retrieve = 2,
    HotCopy = 3,
    ArchiveCopyExplorable = 4
}
