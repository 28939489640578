/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    AzureStorageEndpoint,
    RESTAzureContainer,
    RESTAzureFolderToReceive,
    RESTAzureFolderToSend,
    RESTExceptionInfo,
} from '../models';

export interface AzureObjectStorageApiAzureObjectStorageAddFolderRequest {
    containerName: string;
    accountId: string;
    regionType: AzureStorageEndpoint;
    folderJson: RESTAzureFolderToSend;
}

export interface AzureObjectStorageApiAzureObjectStorageGetContainerRequest {
    name: string;
    accountId: string;
    regionType: AzureStorageEndpoint;
}

export interface AzureObjectStorageApiAzureObjectStorageGetContainersRequest {
    accountId: string;
    regionType: AzureStorageEndpoint;
    name?: string;
}

export interface AzureObjectStorageApiAzureObjectStorageGetFolderRequest {
    containerName: string;
    name: string;
    accountId: string;
    regionType: AzureStorageEndpoint;
}

export interface AzureObjectStorageApiAzureObjectStorageGetFoldersRequest {
    containerName: string;
    accountId: string;
    regionType: AzureStorageEndpoint;
    name?: string;
}

/**
 * no description
 */
export const azureObjectStorageApi = ({

    /**
     * Creates a new folder in the specified Azure container.
     * Create Folders
     */
    azureObjectStorageAddFolder: ({ containerName, accountId, regionType, folderJson }: AzureObjectStorageApiAzureObjectStorageAddFolderRequest, option?: RequestOption): Observable<Optional<RESTAzureFolderToReceive>> => {
        throwIfNullOrUndefined(containerName, 'containerName', 'azureObjectStorageAddFolder');
        throwIfNullOrUndefined(accountId, 'accountId', 'azureObjectStorageAddFolder');
        throwIfNullOrUndefined(regionType, 'regionType', 'azureObjectStorageAddFolder');
        throwIfNullOrUndefined(folderJson, 'folderJson', 'azureObjectStorageAddFolder');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        return requestAuthenticated<RESTAzureFolderToReceive>({
            url: '/v8/AzureResources/containers/{containerName}/folders'.replace('{containerName}', encodeURI(containerName)),
            method: 'POST',
            headers,
            query,
            body: folderJson,
        }, option);
    },

    /**
     * Returns information about an Azure container with the specified name.
     * Get Container by Name
     */
    azureObjectStorageGetContainer: ({ name, accountId, regionType }: AzureObjectStorageApiAzureObjectStorageGetContainerRequest, option?: RequestOption): Observable<Optional<RESTAzureContainer>> => {
        throwIfNullOrUndefined(name, 'name', 'azureObjectStorageGetContainer');
        throwIfNullOrUndefined(accountId, 'accountId', 'azureObjectStorageGetContainer');
        throwIfNullOrUndefined(regionType, 'regionType', 'azureObjectStorageGetContainer');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        return requestAuthenticated<RESTAzureContainer>({
            url: '/v8/AzureResources/containers/{Name}'.replace('{Name}', encodeURI(name)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a list of Azure containers.
     * Get Containers
     */
    azureObjectStorageGetContainers: ({ accountId, regionType, name }: AzureObjectStorageApiAzureObjectStorageGetContainersRequest, option?: RequestOption): Observable<Optional<Array<RESTAzureContainer>>> => {
        throwIfNullOrUndefined(accountId, 'accountId', 'azureObjectStorageGetContainers');
        throwIfNullOrUndefined(regionType, 'regionType', 'azureObjectStorageGetContainers');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        if (name != null) { query['Name'] = name; }

        return requestAuthenticated<Array<RESTAzureContainer>>({
            url: '/v8/AzureResources/containers',
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns information about an Azure storage folder with the specified name.
     * Get Folder by Name
     */
    azureObjectStorageGetFolder: ({ containerName, name, accountId, regionType }: AzureObjectStorageApiAzureObjectStorageGetFolderRequest, option?: RequestOption): Observable<Optional<RESTAzureFolderToReceive>> => {
        throwIfNullOrUndefined(containerName, 'containerName', 'azureObjectStorageGetFolder');
        throwIfNullOrUndefined(name, 'name', 'azureObjectStorageGetFolder');
        throwIfNullOrUndefined(accountId, 'accountId', 'azureObjectStorageGetFolder');
        throwIfNullOrUndefined(regionType, 'regionType', 'azureObjectStorageGetFolder');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        return requestAuthenticated<RESTAzureFolderToReceive>({
            url: '/v8/AzureResources/containers/{containerName}/folders/{Name}'.replace('{containerName}', encodeURI(containerName)).replace('{Name}', encodeURI(name)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a list of Azure storage folders created in the specified container.
     * Get Folders
     */
    azureObjectStorageGetFolders: ({ containerName, accountId, regionType, name }: AzureObjectStorageApiAzureObjectStorageGetFoldersRequest, option?: RequestOption): Observable<Optional<Array<RESTAzureFolderToReceive>>> => {
        throwIfNullOrUndefined(containerName, 'containerName', 'azureObjectStorageGetFolders');
        throwIfNullOrUndefined(accountId, 'accountId', 'azureObjectStorageGetFolders');
        throwIfNullOrUndefined(regionType, 'regionType', 'azureObjectStorageGetFolders');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        if (name != null) { query['Name'] = name; }

        return requestAuthenticated<Array<RESTAzureFolderToReceive>>({
            url: '/v8/AzureResources/containers/{containerName}/folders'.replace('{containerName}', encodeURI(containerName)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

})
