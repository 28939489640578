// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';

import { Batch, loadPages } from '../../../../infrastructure/rxjs';
import { exploreSessionService } from '../../../exploreSessions';
import {
    convertChannelNodeFromRest,
    convertFileNodeFromRest,
    convertFolderNodeFromRest,
    convertPostNodeFromRest,
    convertTabNodeFromRest,
    convertTeamNodeFromRest,
} from './converters';
import { teamsApi } from './wrapped-teams-api';
import { TeamsNodeType } from '../../../models/Teams';
import { RESTTeamsNodeTypeEnum } from '../../../../api/rxjs';

import type { TeamsNode } from '../../../models/Teams';
import type { Node } from '../../../models';
import type { LoadPagesConfig } from '../../../../infrastructure/rxjs';

export const getTeamsNodes = (parent: TeamsNode, config: LoadPagesConfig | undefined): Observable<Batch<Node>> => {
    const { vetSession } = exploreSessionService.getSessions();

    if (!vetSession) {
        return of(Batch.empty());
    }

    switch (parent.nodeType) {
        case TeamsNodeType.Teams:
            return loadPages(teamsApi.getTeams)({
                restoreSessionId: vetSession,
            }).pipe(
                map(teams => teams.mapData<Node>( item => convertTeamNodeFromRest(item, parent))),
            );
        case TeamsNodeType.Team:
            return loadPages(teamsApi.getTeamChildren, config)({
                teamId: parent.id,
                restoreSessionId: vetSession,
            }).pipe(
                map(channels => channels.mapData<Node>(item => convertChannelNodeFromRest(item, parent))),
            );
        case TeamsNodeType.Channel:
            return loadPages(teamsApi.getChannelChildren, config)({
                teamId: parent.teamId,
                restoreSessionId: vetSession,
                channelId: parent.id,
            }).pipe(
                map(channels => channels.mapData<Node>((node) => {
                    if (node.type === RESTTeamsNodeTypeEnum.Files) return convertFileNodeFromRest(node, parent);
                    if (node.type === RESTTeamsNodeTypeEnum.Posts) return convertPostNodeFromRest(node, parent);
                    if (node.type === RESTTeamsNodeTypeEnum.Tabs) return convertTabNodeFromRest(node, parent);
                })),
            );
        case TeamsNodeType.Posts:
            return of(Batch.empty());
        case TeamsNodeType.Tabs:
            return of(Batch.empty());
        case TeamsNodeType.Files:
            return loadPages(teamsApi.getFileItems, config)({
                teamId: parent.teamId,
                restoreSessionId: vetSession,
                channelId: parent.channelId,
                foldersOnly: true,
                parentId: 'root',
            })
                .pipe(
                    map(folders => folders.mapData(folder => convertFolderNodeFromRest(folder, parent))),
                );
        case TeamsNodeType.Folder:
            return loadPages(teamsApi.getFileItems, config)({
                teamId: parent.teamId,
                restoreSessionId: vetSession,
                channelId: parent.channelId,
                foldersOnly: true,
                parentId: parent.id,
            })
                .pipe(
                    map(folders => folders.mapData(folder => convertFolderNodeFromRest(folder, parent))),
                );
        default:
            return of(Batch.empty());
    }
};
