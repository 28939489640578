/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTItemComposed,
    PageOfRESTSharePointSite,
    RESTAsyncRestoreResponse,
    RESTExceptionInfo,
    RESTRestoreSiteConfig,
    RESTRestoreToSiteConfig,
    RESTSharePointSite,
    RESTVESPSearchOptions,
} from '../models';

export interface SharePointSiteApiSharePointSiteGetRequest {
    restoreSessionId: string;
    offset: number;
    limit: number;
    parentId?: string;
}

export interface SharePointSiteApiSharePointSiteGetByIdRequest {
    restoreSessionId: string;
    siteId: string;
}

export interface SharePointSiteApiSharePointSiteRestoreSiteActionRequest {
    restoreSessionId: string;
    siteId: string;
    restRestoreConfig: RESTRestoreSiteConfig;
}

export interface SharePointSiteApiSharePointSiteRestoreSiteActionToRequest {
    restoreSessionId: string;
    siteId: string;
    restRestoreConfig: RESTRestoreToSiteConfig;
}

export interface SharePointSiteApiSharePointSiteSearchSiteByOptionsRequest {
    restoreSessionId: string;
    siteId: string;
    offset: number;
    limit: number;
    searchOptions: RESTVESPSearchOptions;
    setId?: string;
    itemType?: SharePointSiteSearchSiteByOptionsItemTypeEnum;
}

export interface SharePointSiteApiSharePointSiteSearchSitesByOptionsRequest {
    restoreSessionId: string;
    offset: number;
    limit: number;
    searchOptions: RESTVESPSearchOptions;
    setId?: string;
    itemType?: SharePointSiteSearchSitesByOptionsItemTypeEnum;
}

/**
 * no description
 */
export const sharePointSiteApi = ({

    /**
     * Returns a collection of backed-up SharePoint sites to explore and restore.
     * Get SharePoint Sites
     */
    sharePointSiteGet: ({ restoreSessionId, offset, limit, parentId }: SharePointSiteApiSharePointSiteGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTSharePointSite>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointSiteGet');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointSiteGet');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointSiteGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (parentId != null) { query['parentId'] = parentId; }

        return requestAuthenticated<PageOfRESTSharePointSite>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a SharePoint site with the specified ID.
     * Get SharePoint Site
     */
    sharePointSiteGetById: ({ restoreSessionId, siteId }: SharePointSiteApiSharePointSiteGetByIdRequest, option?: RequestOption): Observable<Optional<RESTSharePointSite>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointSiteGetById');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointSiteGetById');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTSharePointSite>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint site with the specified ID to the original location.
     * Restore SharePoint Site to Original Location
     */
    sharePointSiteRestoreSiteAction: ({ restoreSessionId, siteId, restRestoreConfig }: SharePointSiteApiSharePointSiteRestoreSiteActionRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointSiteRestoreSiteAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointSiteRestoreSiteAction');
        throwIfNullOrUndefined(restRestoreConfig, 'restRestoreConfig', 'sharePointSiteRestoreSiteAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: restRestoreConfig,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint site with the specified ID to another location.
     * Restore SharePoint Site to Another Location
     */
    sharePointSiteRestoreSiteActionTo: ({ restoreSessionId, siteId, restRestoreConfig }: SharePointSiteApiSharePointSiteRestoreSiteActionToRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointSiteRestoreSiteActionTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointSiteRestoreSiteActionTo');
        throwIfNullOrUndefined(restRestoreConfig, 'restRestoreConfig', 'sharePointSiteRestoreSiteActionTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: restRestoreConfig,
        }, option);
    },

    /**
     * Searches for SharePoint items in a backed-up SharePoint site with the specified ID.
     * Search for SharePoint Items in SharePoint Site
     */
    sharePointSiteSearchSiteByOptions: ({ restoreSessionId, siteId, offset, limit, searchOptions, setId, itemType }: SharePointSiteApiSharePointSiteSearchSiteByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointSiteSearchSiteByOptions');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointSiteSearchSiteByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointSiteSearchSiteByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointSiteSearchSiteByOptions');
        throwIfNullOrUndefined(searchOptions, 'searchOptions', 'sharePointSiteSearchSiteByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }
        if (itemType != null) { query['itemType'] = itemType; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            query,
            body: searchOptions,
        }, option);
    },

    /**
     * Searches for SharePoint items in backed-up SharePoint sites.
     * Search for SharePoint Items in SharePoint Sites
     */
    sharePointSiteSearchSitesByOptions: ({ restoreSessionId, offset, limit, searchOptions, setId, itemType }: SharePointSiteApiSharePointSiteSearchSitesByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointSiteSearchSitesByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointSiteSearchSitesByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointSiteSearchSitesByOptions');
        throwIfNullOrUndefined(searchOptions, 'searchOptions', 'sharePointSiteSearchSitesByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }
        if (itemType != null) { query['itemType'] = itemType; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
            query,
            body: searchOptions,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum SharePointSiteSearchSiteByOptionsItemTypeEnum {
    All = 'All',
    Folders = 'Folders',
    Items = 'Items'
}
/**
 * @export
 * @enum {string}
 */
export enum SharePointSiteSearchSitesByOptionsItemTypeEnum {
    All = 'All',
    Folders = 'Folders',
    Items = 'Items'
}
