/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTGroupMember,
    RESTExceptionInfo,
} from '../models';

export interface OrganizationGroupMemberApiOrganizationGroupMemberGetRequest {
    organizationId: string;
    groupId: string;
    limit: number;
    offset: number;
    setId?: string;
    dataSource?: OrganizationGroupMemberGetDataSourceEnum;
}

/**
 * no description
 */
export const organizationGroupMemberApi = ({

    /**
     * Returns a a resource representation of members of an organization group with the specified ID.
     * Get Organization Group Members
     */
    organizationGroupMemberGet: ({ organizationId, groupId, limit, offset, setId, dataSource }: OrganizationGroupMemberApiOrganizationGroupMemberGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTGroupMember>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationGroupMemberGet');
        throwIfNullOrUndefined(groupId, 'groupId', 'organizationGroupMemberGet');
        throwIfNullOrUndefined(limit, 'limit', 'organizationGroupMemberGet');
        throwIfNullOrUndefined(offset, 'offset', 'organizationGroupMemberGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (setId != null) { query['setId'] = setId; }
        if (dataSource != null) { query['dataSource'] = dataSource; }

        return requestAuthenticated<PageOfRESTGroupMember>({
            url: '/v8/Organizations/{organizationId}/Groups/{groupId}/Members'.replace('{organizationId}', encodeURI(organizationId)).replace('{groupId}', encodeURI(groupId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum OrganizationGroupMemberGetDataSourceEnum {
    PreferLocal = 'PreferLocal',
    Production = 'Production',
    PreferLocalResynced = 'PreferLocalResynced'
}
