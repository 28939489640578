// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
    RESTOfficeLicense,
} from './';

/**
 * @export
 * @interface RESTM365BackupStoragePolicyUser
 */
export interface RESTM365BackupStoragePolicyUser {
    /**
     * Organization user ID.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    id?: string;
    /**
     * Version number that Veeam Backup for Microsoft 365 assigns if the organization user was modified.
     * @type {number}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    eTag?: number | null;
    /**
     * Display name of the organization user.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    displayName?: string;
    /**
     * Email address of the organization user.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    name?: string;
    /**
     * Type of the organization user.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    type?: RESTM365BackupStoragePolicyUserTypeEnum;
    /**
     * Microsoft 365 organization deployment type.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    locationType?: RESTM365BackupStoragePolicyUserLocationTypeEnum;
    /**
     * Office location in the place of business of the organization user.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    office?: string;
    /**
     * Array of licenses assigned to the organization user.
     * @type {Array<RESTOfficeLicense>}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    assignedLicenses?: Array<RESTOfficeLicense> | null;
    /**
     * ID of the organization user assigned by Microsoft.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    msid?: string | null;
    /**
     * Related resources.
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTM365BackupStoragePolicyUser
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStoragePolicyUserTypeEnum {
    User = 'User',
    Shared = 'Shared',
    Public = 'Public'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStoragePolicyUserLocationTypeEnum {
    OnPremises = 'OnPremises',
    Cloud = 'Cloud',
    Hybrid = 'Hybrid'
}

