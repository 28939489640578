// Copyright © Veeam Software Group GmbH

import { ACTION_VIEW, DataGrid, Dialog, DIALOG_SIZE, doubleToolbar, SEARCH_MODE, toolbarAction } from '@veeam/components';
import React, { useMemo, useState } from 'react';

import type { SelectionSubheaderRendererProps } from '@veeam/components';
import type { Action0 } from 'infrastructure/types';
import type { TeamsPostItem , TeamsItem } from 'services/models/Teams/TeamsItem';

import { useResources } from 'infrastructure/resources';
import { restoreToolbarAction } from '../../../../features/Restore';
import { getTeamsPostsColumns } from '../ExploreGrid/helpers/getColumns';
import { useRepliesGridModel } from './use-replies-grid-model';
import { addToRestoreListAction } from '../../helpers/addToRestoreListAction';
import { downloadSrc } from 'assets';
import { TeamsItemType } from 'services/models/Teams/TeamsItem';
import { exploreSessionService } from '../../../../services/exploreSessions';
import { authApi, teamsPostApi } from '../../../../api/rxjs';
import { downloadFile } from '../ExploreGrid/helpers/getToolbar';
import { Subheader } from '../ExploreGrid/components/Subheader';
import { SubheaderType } from '../ExploreGrid/enums/subheader-type';
import { createConfigMaker, createToolbarFilters } from '../../../../infrastructure/grid';

import type { RESTAuthCode, RESTTeamsPost, ResultKeeper } from '../../../../api/rxjs';
import type { ExploreItemsFilter } from '../../../../services/explore';

export const RepliesDialog: React.FC<{
    item: TeamsPostItem;
    deactivate: Action0;
}> = ({ item, deactivate }) => {
    const resources = useResources();
    const exploreGridResources = resources.features.Explore.ExploreGrid;
    const [subheaderType, setSubheaderType] = useState(SubheaderType.Default);
    const [asyncLoading, setAsyncLoading] = useState(false);
    const model = useRepliesGridModel(item, setAsyncLoading, setSubheaderType);
    const columns = useMemo(
        () =>
            getTeamsPostsColumns(exploreGridResources)
                .filter(item => item.id !== 'replies')
                .map((item) => {
                    if (item.id === 'subject') {
                        item.hidden = true;
                    }
                    return item;
                }),
        []);

    const exportAction = toolbarAction({
        title: exploreGridResources.toolbar.exportToHtml,
        icon: downloadSrc,
        decorators: [
            (payload) => {
                let disabled = false;

                if (payload.selectedItems.length === 0) {
                    disabled = true;
                }

                if (payload.selectedItems.length > 0) {
                    const selectedItems = payload.selectedItems as unknown as TeamsItem[];

                    disabled = selectedItems.every(
                        item => item.itemType !== TeamsItemType.Post || (item as TeamsPostItem).isDeleted
                    );
                }

                return {
                    disabled,
                };
            },
        ],
        handler: async() => {
            const { vetSession } = exploreSessionService.getSessions();
            const posts = model.getItems().filter(
                (item: TeamsPostItem) => item.itemType === TeamsItemType.Post && !item.isDeleted
            ) as RESTTeamsPost[];
            const teamId = (posts[0] as RESTTeamsPost).teamId;
            const { data } = await authApi.authCode().toPromise() as ResultKeeper<RESTAuthCode>;
            const { authCode } = data;

            const { exportTaskId } = await teamsPostApi.teamsPostOperatorStartExportTask({
                restoreSessionId: vetSession,
                teamId,
                exportOptions: {
                    posts,
                },
            }).toPromise()
                .then(res => res.getResultOrThrow());

            downloadFile(
                `/v8/RestoreSessions/${vetSession}/organization/teams/${teamId}/posts/operatorExport/${exportTaskId}?authCode=${authCode}`
            );
        },
    });

    const searchConfig = createConfigMaker<ExploreItemsFilter>().search('search', {
        fieldConfig: {
            mode: SEARCH_MODE.compulsory,
        },
    });
    const filters = createToolbarFilters(searchConfig);

    return (
        <Dialog
            header={resources.features.Explore.RepliesDialog.replies}
            hasTopPadding={false}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: resources.features.Explore.RepliesDialog.close, onClick: deactivate, view: ACTION_VIEW.primary },
            ]}
        >
            <DataGrid
                model={model}
                columns={columns}
                isRowDisabled={({ rowData }) => (rowData as TeamsPostItem).isDeleted}
                toolbar={{
                    items: [
                        ...filters,
                        restoreToolbarAction(() => model.getItems()),
                        addToRestoreListAction(() => model.getItems(), model.unselectAll),
                        exportAction,
                    ],
                    decorator: doubleToolbar(),
                }}
                config={{
                    canResizeColumns: true,
                    columnsToggleable: true,
                    selection: {
                        multi: true,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore veeam/components has wrong typings
                        subheaderRenderer: ({ selected, total }: SelectionSubheaderRendererProps<{}>) =>
                            <Subheader
                                subheaderType={subheaderType}
                                selected={selected}
                                total={total}
                                loadAllItems={model.loadAllItems.bind(model)}
                                resources={resources.features.Explore.ExploreGrid}
                            />,
                    },
                }}
                loading={asyncLoading}
                disabled={asyncLoading}
            />
        </Dialog>
    );
};
