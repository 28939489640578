// Copyright © Veeam Software Group GmbH

import { of, zip } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { LoadPagesConfig } from 'infrastructure/rxjs';
import type {
    SharePointContent,
    SharePointFolder,
    SharePointLibrary,
    SharePointList,
    SharePointNode,
    SharePointSite,
    SharePointSubSites,
    VespSession,
} from 'services/models';

import { never } from 'infrastructure/never';
import { Batch, loadPages, mergeBatches } from 'infrastructure/rxjs';
import { convert } from './converters';
import { sharePointApi } from './wrappedSharePointApi';
import { exploreSessionService } from 'services/exploreSessions';
import { SharePointNodeType } from 'services/models';
import { sortTreeNodesByTitle } from '../../../../pages/ExplorePage/models/explore-tree-model';

const loadSharePointSites = (
    session: VespSession,
    config: LoadPagesConfig | undefined,
    parent?: SharePointSubSites
): Observable<Batch<SharePointSite>> =>
    loadPages(sharePointApi.getSites, config)({
        restoreSessionId: session,
        parentId: parent ? parent.siteId : 'root',
    }).pipe(
        map(sites => sites.mapData(site => convert.site.fromRest(site, parent))),
        map(sortTreeNodesByTitle)
    );

const getFictionSitesFolder = (parent: SharePointSite): Observable<Batch<SharePointNode>> => of([
    convert.subsites.fromSite(parent),
    convert.content.fromSite(parent),
]).pipe(
    map(data => Batch.from(data)),
);

const loadSharePointLists = (
    session: VespSession,
    parent: SharePointContent,
    config: LoadPagesConfig | undefined
): Observable<Batch<SharePointList>> =>
    loadPages(sharePointApi.getLists, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
    }).pipe(
        map(lists => lists.mapData(list => convert.list.fromRest(list, parent)))
    );

const loadSharePointLibrary = (
    session: VespSession,
    parent: SharePointContent,
    config: LoadPagesConfig | undefined
): Observable<Batch<SharePointLibrary>> =>
    loadPages(sharePointApi.getLibraries, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
    }).pipe(
        map(libraries => libraries.mapData(library => convert.library.fromRest(library, parent)))
    );

const loadSiteContent = (
    session: VespSession,
    parent: SharePointContent,
    config: LoadPagesConfig | undefined
): Observable<Batch<SharePointNode>> => zip(
    loadSharePointLists(session, parent, config),
    loadSharePointLibrary(session, parent, config),
).pipe(
    map(batches => mergeBatches<SharePointNode>(batches)),
    map(sortTreeNodesByTitle),
);

const loadSharePointFolder = (
    session: VespSession,
    parent: SharePointFolder,
    config: LoadPagesConfig | undefined
): Observable<Batch<SharePointFolder>> =>
    loadPages(sharePointApi.getFolders, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        parentId: parent.id,
    }).pipe(
        map(folders => folders.mapData(folder => convert.folder.fromRest(folder, parent))),
        map(sortTreeNodesByTitle)
    );

export const getSharePointNodes = (parent: SharePointNode, config: LoadPagesConfig | undefined): Observable<Batch<SharePointNode>> => {
    const { vespSession } = exploreSessionService.getSessions();
    if (!vespSession) {
        console.error('');
        return of(Batch.empty(config?.prevState));
    }
    switch (parent.nodeType) {
        case SharePointNodeType.Site: return getFictionSitesFolder(parent);
        case SharePointNodeType.SubSites: return loadSharePointSites(vespSession, config, parent);
        case SharePointNodeType.Content: return loadSiteContent(vespSession, parent, config);
        case SharePointNodeType.Library:
        case SharePointNodeType.List:
        case SharePointNodeType.ListFolder:
        case SharePointNodeType.LibraryFolder: return loadSharePointFolder(vespSession, parent, config);
        default: return never(parent);
    }
};

export const getRootSites = (config: LoadPagesConfig | undefined): Observable<Batch<SharePointNode>> => {
    const { vespSession } = exploreSessionService.getSessions();
    if (!vespSession) return of(Batch.empty());
    return loadSharePointSites(vespSession, config).pipe(
        catchError(() => of(Batch.empty<SharePointNode>())),
    );
};
