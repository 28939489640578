// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';
import { doubleToolbar, TOOLBAR_VIEW_TYPE, toolbarAction, toolbarFillSpace } from '@veeam/components';

import type { CollectionToolbarConfig, CollectionToolbarItem } from '@veeam/components';
import type { LocalizableString } from 'infrastructure/resources';
import type { Action0 } from 'infrastructure/types';
import type { RbacScopeFilter } from 'services/rbac';

import { RbacScopeType } from 'services/rbac';
import { refreshSrc } from 'assets';
import { getTypeToIconMap, getTypeToTitleMap } from './mappers';
import { createConfigMaker, createToolbarFilters } from 'infrastructure/grid';

import type { GridResources } from './resources';


function createFilter(resources: GridResources): CollectionToolbarItem[] {
    const titlesMap = getTypeToTitleMap(resources.types);
    const iconsMap = getTypeToIconMap();
    const configMaker = createConfigMaker<RbacScopeFilter>();
    const searchConfig = configMaker.search('search', {
        fieldConfig: { placeholder: resources.filter.scope },
    });
    const advancedConfig = configMaker
        .advanced({
            valueTitle: resources.filter.advancedTitle,
            filtersTitle: '' as LocalizableString,
        })
        .group({
            columnIndex: 0,
            title: resources.filter.typeTitle,
            property: 'type',
            items: [
                { title: titlesMap.User, icon: iconsMap.User, value: RbacScopeType.User },
                { title: titlesMap.PublicMailbox, icon: iconsMap.PublicMailbox, value: RbacScopeType.PublicMailbox },
                { title: titlesMap.SharedMailbox, icon: iconsMap.SharedMailbox, value: RbacScopeType.SharedMailbox },
                { title: titlesMap.Site, icon: iconsMap.Site, value: RbacScopeType.Site },
                { title: titlesMap.Team, icon: iconsMap.Team, value: RbacScopeType.Team },
            ],
        });
    return createToolbarFilters(searchConfig, advancedConfig);
}

const reloadScopesGridAction = (resources: GridResources, reloadScopesGrid: Action0): CollectionToolbarItem =>
    toolbarAction({
        isAction: false,
        title: resources.reloadScopesGridActionTitle,
        icon: refreshSrc,
        handler: reloadScopesGrid,
    });

export const useToolbar = (
    resources: GridResources,
    changeScope: Action0,
    reloadScopesGrid: Action0
): CollectionToolbarConfig =>
    useMemo(
        () => ({
            decorator: doubleToolbar({ viewFilters: TOOLBAR_VIEW_TYPE.spotted }),
            items: [
                ...createFilter(resources),
                toolbarFillSpace({ isAction: false }),
                reloadScopesGridAction(resources, reloadScopesGrid),
            ],
        }),
        [resources]
    );
