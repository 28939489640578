/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTTeamsFile,
    RESTAsyncRestoreResponse,
    RESTExceptionInfo,
    RESTFileSaveOptions,
    RESTFilesSaveOptions,
    RESTFilesSendOptions,
    RESTOperatorRestoreFilesOptions,
    RESTRestoreFileOptions,
    RESTRestoreFilesOptions,
    RESTSearchOptions,
    RESTSendAsMsgOptions,
    RESTTeamsFile,
    RESTTeamsOperatorRestoreSessionResponse,
} from '../models';

export interface TeamsFileApiTeamsFileGetRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    fileId: string;
}

export interface TeamsFileApiTeamsFileGetPageRequest {
    restoreSessionId: string;
    teamId: string;
    offset: number;
    limit: number;
    channelId?: string;
    parentId?: string;
    foldersOnly?: boolean;
}

export interface TeamsFileApiTeamsFileGetVersionsRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    fileId: string;
    offset: number;
    limit: number;
}

export interface TeamsFileApiTeamsFileOperatorRestoreRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTOperatorRestoreFilesOptions;
}

export interface TeamsFileApiTeamsFileRestoreRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTRestoreFilesOptions;
}

export interface TeamsFileApiTeamsFileRestoreByIdRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    fileId: string;
    action: RESTRestoreFileOptions;
}

export interface TeamsFileApiTeamsFileSaveRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTFilesSaveOptions;
}

export interface TeamsFileApiTeamsFileSaveByIdRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    fileId: string;
    action: RESTFileSaveOptions;
}

export interface TeamsFileApiTeamsFileSearchRequest {
    restoreSessionId: string;
    teamId: string;
    offset: number;
    limit: number;
    action: RESTSearchOptions;
    channelId?: string;
    parentId?: string;
}

export interface TeamsFileApiTeamsFileSendRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTFilesSendOptions;
}

export interface TeamsFileApiTeamsFileSendByIdRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    fileId: string;
    action: RESTSendAsMsgOptions;
}

/**
 * no description
 */
export const teamsFileApi = ({

    /**
     * Returns a resource representation of a backed-up Microsoft Teams file with the specified ID.
     * Get File
     */
    teamsFileGet: ({ restoreSessionId, teamId, channelId, fileId }: TeamsFileApiTeamsFileGetRequest, option?: RequestOption): Observable<Optional<RESTTeamsFile>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileGet');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileGet');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsFileGet');
        throwIfNullOrUndefined(fileId, 'fileId', 'teamsFileGet');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTTeamsFile>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/files/{fileId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)).replace('{fileId}', encodeURI(fileId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a collection of backed-up files of a Microsoft Teams team with the specified ID to explore and restore.
     * Get Files
     */
    teamsFileGetPage: ({ restoreSessionId, teamId, offset, limit, channelId, parentId, foldersOnly }: TeamsFileApiTeamsFileGetPageRequest, option?: RequestOption): Observable<Optional<PageOfRESTTeamsFile>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileGetPage');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileGetPage');
        throwIfNullOrUndefined(offset, 'offset', 'teamsFileGetPage');
        throwIfNullOrUndefined(limit, 'limit', 'teamsFileGetPage');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (channelId != null) { query['channelId'] = channelId; }
        if (parentId != null) { query['parentId'] = parentId; }
        if (foldersOnly != null) { query['foldersOnly'] = foldersOnly; }

        return requestAuthenticated<PageOfRESTTeamsFile>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/files'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a collection of versions of a backed-up Microsoft Teams file with the specified ID.
     * Get Previous Versions of File
     */
    teamsFileGetVersions: ({ restoreSessionId, teamId, channelId, fileId, offset, limit }: TeamsFileApiTeamsFileGetVersionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTTeamsFile>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileGetVersions');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileGetVersions');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsFileGetVersions');
        throwIfNullOrUndefined(fileId, 'fileId', 'teamsFileGetVersions');
        throwIfNullOrUndefined(offset, 'offset', 'teamsFileGetVersions');
        throwIfNullOrUndefined(limit, 'limit', 'teamsFileGetVersions');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTTeamsFile>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/files/{fileId}/versions'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)).replace('{fileId}', encodeURI(fileId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Restores backed-up Microsoft Teams files using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=80) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong> </br> To restore data using Restore Portal, you must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div> 
     * Restore Files by Restore Operator
     */
    teamsFileOperatorRestore: ({ restoreSessionId, teamId, action }: TeamsFileApiTeamsFileOperatorRestoreRequest, option?: RequestOption): Observable<Optional<RESTTeamsOperatorRestoreSessionResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileOperatorRestore');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileOperatorRestore');
        throwIfNullOrUndefined(action, 'action', 'teamsFileOperatorRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTTeamsOperatorRestoreSessionResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/files/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Restores backed-up Microsoft Teams files.
     * Restore Files
     */
    teamsFileRestore: ({ restoreSessionId, teamId, action }: TeamsFileApiTeamsFileRestoreRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileRestore');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileRestore');
        throwIfNullOrUndefined(action, 'action', 'teamsFileRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/files/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Restores a backed-up Microsoft Teams file with the specified ID.
     * Restore File
     */
    teamsFileRestoreById: ({ restoreSessionId, teamId, channelId, fileId, action }: TeamsFileApiTeamsFileRestoreByIdRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileRestoreById');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileRestoreById');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsFileRestoreById');
        throwIfNullOrUndefined(fileId, 'fileId', 'teamsFileRestoreById');
        throwIfNullOrUndefined(action, 'action', 'teamsFileRestoreById');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/files/{fileId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)).replace('{fileId}', encodeURI(fileId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Saves backed-up Microsoft Teams files.  When you save files, the request command archives the files and places the ZIP archive to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save Files
     */
    teamsFileSave: ({ restoreSessionId, teamId, action }: TeamsFileApiTeamsFileSaveRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileSave');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileSave');
        throwIfNullOrUndefined(action, 'action', 'teamsFileSave');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/files/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Saves a backed-up Microsoft Teams file with the specified ID.  When you save a file, the request command places the file to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the file is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save File
     */
    teamsFileSaveById: ({ restoreSessionId, teamId, channelId, fileId, action }: TeamsFileApiTeamsFileSaveByIdRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileSaveById');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileSaveById');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsFileSaveById');
        throwIfNullOrUndefined(fileId, 'fileId', 'teamsFileSaveById');
        throwIfNullOrUndefined(action, 'action', 'teamsFileSaveById');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/files/{fileId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)).replace('{fileId}', encodeURI(fileId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Searches for backed-up Microsoft Teams files.
     * Search for Files
     */
    teamsFileSearch: ({ restoreSessionId, teamId, offset, limit, action, channelId, parentId }: TeamsFileApiTeamsFileSearchRequest, option?: RequestOption): Observable<Optional<PageOfRESTTeamsFile>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileSearch');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileSearch');
        throwIfNullOrUndefined(offset, 'offset', 'teamsFileSearch');
        throwIfNullOrUndefined(limit, 'limit', 'teamsFileSearch');
        throwIfNullOrUndefined(action, 'action', 'teamsFileSearch');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (channelId != null) { query['channelId'] = channelId; }
        if (parentId != null) { query['parentId'] = parentId; }

        return requestAuthenticated<PageOfRESTTeamsFile>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/files/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            query,
            body: action,
        }, option);
    },

    /**
     * Sends backed-up Microsoft Teams files as attachments in an email message. <div class=\"note\"><strong>NOTE</strong> </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft Teams email settings. For more information, see [Edit Email Settings](#tag/VetEmailSettings/operation/VetEmailSettings_Update). </div> 
     * Send Files
     */
    teamsFileSend: ({ restoreSessionId, teamId, action }: TeamsFileApiTeamsFileSendRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileSend');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileSend');
        throwIfNullOrUndefined(action, 'action', 'teamsFileSend');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/files/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Sends a backed-up Microsoft Teams file with the specified ID as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong> </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft Teams email settings. For more information, see [Edit Email Settings](#tag/VetEmailSettings/operation/VetEmailSettings_Update). </div> 
     * Send File
     */
    teamsFileSendById: ({ restoreSessionId, teamId, channelId, fileId, action }: TeamsFileApiTeamsFileSendByIdRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsFileSendById');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsFileSendById');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsFileSendById');
        throwIfNullOrUndefined(fileId, 'fileId', 'teamsFileSendById');
        throwIfNullOrUndefined(action, 'action', 'teamsFileSendById');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/files/{fileId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)).replace('{fileId}', encodeURI(fileId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

})
