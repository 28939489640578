// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTBulkMailboxRestoreStatistics,
} from './';

/**
 * @export
 * @interface RESTBulkRestoreStatistics
 */
export interface RESTBulkRestoreStatistics {
    /**
     * Status of the restore operation.
     * @type {string}
     * @memberof RESTBulkRestoreStatistics
     */
    status?: RESTBulkRestoreStatisticsStatusEnum;
    /**
     * Error occurred during the restore operation.
     * @type {string}
     * @memberof RESTBulkRestoreStatistics
     */
    error?: string;
    /**
     * Restore operation details.
     * @type {Array<RESTBulkMailboxRestoreStatistics>}
     * @memberof RESTBulkRestoreStatistics
     */
    details?: Array<RESTBulkMailboxRestoreStatistics>;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTBulkRestoreStatisticsStatusEnum {
    Failed = 'Failed',
    Warning = 'Warning',
    Success = 'Success'
}

