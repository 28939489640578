// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTAttachInfo,
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTChannelEntity
 */
export interface RESTChannelEntity {
    /**
     * File ID.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    id?: string;
    /**
     * Name of the file.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    name?: string;
    /**
     * File size.
     * @type {number}
     * @memberof RESTChannelEntity
     */
    sizeBytes?: number;
    /**
     * Version of the file in the backup.
     * @type {number}
     * @memberof RESTChannelEntity
     */
    version?: number;
    /**
     * Version of the file.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    uiVersion?: string;
    /**
     * Date and time of the last modification of the file.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    modified?: string;
    /**
     * Name of the user who performed the last modification of the file.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    modifiedBy?: string;
    /**
     * Channel ID.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    channelId?: string;
    /**
     * Team ID.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    teamId?: string;
    /**
     * Type of the Microsoft Teams item.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    fileType?: RESTChannelEntityFileTypeEnum;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTChannelEntity
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
    /**
     * Post ID.
     * @type {number}
     * @memberof RESTChannelEntity
     */
    postId?: number;
    /**
     * Change key.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    changeKey?: string;
    /**
     * Defines whether the post is marked as important.
     * @type {boolean}
     * @memberof RESTChannelEntity
     */
    isImportant?: boolean;
    /**
     * User name of the author of the post.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    author?: string;
    /**
     * Post subject.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    subject?: string;
    /**
     * Date and time when the post was created.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    createdTime?: string;
    /**
     * Date and time of the last modification of the post.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    lastModifiedTime?: string;
    /**
     * Defines whether the post is marked as deleted or soft deleted.
     * @type {boolean}
     * @memberof RESTChannelEntity
     */
    isDeleted?: boolean;
    /**
     * Parent post ID.
     * @type {number}
     * @memberof RESTChannelEntity
     */
    parentId?: number | null;
    /**
     * Parent change key.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    parentChangeKey?: string;
    /**
     * Array of attachment items for the post.
     * @type {Array<RESTAttachInfo>}
     * @memberof RESTChannelEntity
     */
    attachments?: Array<RESTAttachInfo>;
    /**
     * Display name of the tab.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    displayName?: string;
    /**
     * Path to the object published on the tab.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    contentUrl?: string;
    /**
     * Type of the tab.
     * @type {string}
     * @memberof RESTChannelEntity
     */
    type?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTChannelEntityFileTypeEnum {
    File = 'File',
    Folder = 'Folder'
}

