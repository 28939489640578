// Copyright © Veeam Software Group GmbH

import type { LocalizableString } from 'infrastructure/resources';
import type { EnumMap } from 'infrastructure/types';
import type { RbacScopeType } from 'services/rbac';

import { mailboxSrc, spSiteSrc, userSrc, sharedMailboxSrc, teamFolderSrc } from 'assets';

import type { GridResources } from './resources';


export const getTypeToTitleMap = (resources: GridResources['types']): EnumMap<RbacScopeType, LocalizableString> => ({
    User: resources.user,
    Site: resources.site,
    Team: resources.team,
    PublicMailbox: resources.publicMailbox,
    SharedMailbox: resources.sharedMailbox,
});

export const getTypeToIconMap = (): EnumMap<RbacScopeType, string> => ({
    User: userSrc,
    Site: spSiteSrc,
    Team: teamFolderSrc,
    PublicMailbox: mailboxSrc,
    SharedMailbox: sharedMailboxSrc,
});
