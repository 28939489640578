// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTOfficeLicense
 */
export interface RESTOfficeLicense {
    /**
     * Unique ID of the service SKU.
     * @type {string}
     * @memberof RESTOfficeLicense
     */
    skuId?: string;
    /**
     * Unique display name of the service SKU.
     * @type {string}
     * @memberof RESTOfficeLicense
     */
    skuPartNumber?: string;
    /**
     * Type of the service SKU.
     * @type {string}
     * @memberof RESTOfficeLicense
     */
    detectedSkuType?: RESTOfficeLicenseDetectedSkuTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOfficeLicenseDetectedSkuTypeEnum {
    Other = 'Other',
    Education = 'Education'
}

