// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTRbacRole
 */
export interface RESTRbacRole {
    /**
     * ID of the restore operator role.
     * @type {string}
     * @memberof RESTRbacRole
     */
    id?: string | null;
    /**
     * Backed-up organization ID.
     * @type {string}
     * @memberof RESTRbacRole
     */
    organizationId?: string;
    /**
     * Name of the restore operator role.
     * @type {string}
     * @memberof RESTRbacRole
     */
    name?: string;
    /**
     * Description of the restore operator role.
     * @type {string}
     * @memberof RESTRbacRole
     */
    description?: string;
    /**
     * Type of the restore operator role. <ul> <li>*EntireOrganization*. Restore operators are allowed to explore and restore backed-up data of all objects within the specified Microsoft 365 organization.</li> <li>*SpecificObjects*. Restore operators are allowed to explore and restore backed-up data of the specified objects.</li> </ul> 
     * @type {string}
     * @memberof RESTRbacRole
     */
    roleType?: RESTRbacRoleRoleTypeEnum;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTRbacRole
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRbacRoleRoleTypeEnum {
    EntireOrganization = 'EntireOrganization',
    SpecificObjects = 'SpecificObjects'
}

