/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTTeam,
    RESTExceptionInfo,
    RESTTeam,
} from '../models';

export interface OrganizationTeamApiOrganizationTeamGetRequest {
    organizationId: string;
    limit: number;
    offset: number;
    setId?: string;
    displayName?: string;
    dataSource?: OrganizationTeamGetDataSourceEnum;
}

export interface OrganizationTeamApiOrganizationTeamGetByIdRequest {
    organizationId: string;
    teamId: string;
    dataSource?: OrganizationTeamGetByIdDataSourceEnum;
}

/**
 * no description
 */
export const organizationTeamApi = ({

    /**
     * Returns a collection of organization teams.
     * Get Teams
     */
    organizationTeamGet: ({ organizationId, limit, offset, setId, displayName, dataSource }: OrganizationTeamApiOrganizationTeamGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTTeam>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationTeamGet');
        throwIfNullOrUndefined(limit, 'limit', 'organizationTeamGet');
        throwIfNullOrUndefined(offset, 'offset', 'organizationTeamGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (setId != null) { query['setId'] = setId; }
        if (displayName != null) { query['displayName'] = displayName; }
        if (dataSource != null) { query['dataSource'] = dataSource; }

        return requestAuthenticated<PageOfRESTTeam>({
            url: '/v8/Organizations/{organizationId}/Teams'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a resource representation of an organization team with the specified ID.
     * Get Team
     */
    organizationTeamGetById: ({ organizationId, teamId, dataSource }: OrganizationTeamApiOrganizationTeamGetByIdRequest, option?: RequestOption): Observable<Optional<RESTTeam>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationTeamGetById');
        throwIfNullOrUndefined(teamId, 'teamId', 'organizationTeamGetById');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = {};

        if (dataSource != null) { query['dataSource'] = dataSource; }

        return requestAuthenticated<RESTTeam>({
            url: '/v8/Organizations/{organizationId}/Teams/{teamId}'.replace('{organizationId}', encodeURI(organizationId)).replace('{teamId}', encodeURI(teamId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum OrganizationTeamGetDataSourceEnum {
    PreferLocal = 'PreferLocal',
    Production = 'Production',
    PreferLocalResynced = 'PreferLocalResynced'
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationTeamGetByIdDataSourceEnum {
    PreferLocal = 'PreferLocal',
    Production = 'Production',
    PreferLocalResynced = 'PreferLocalResynced'
}
