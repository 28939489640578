// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';

import type { CollectionToolbarItem } from '@veeam/components';
import type { RestoreSessionsFilter } from 'services/restoreService';
import type { LocalizableString } from 'infrastructure/resources';

import { createConfigMaker, createToolbarFilters } from 'infrastructure/grid';
import { getTypeToTitleMap, restoreSessionStatusIconMap } from './mappers';
import { RestoreSessionStatus, RestoreSessionType, GetRestoreSessionsPeriod } from 'services/restoreService';
import { useRbac } from '../../Rbac';

import type { GridResources } from './resources';


const createFilter = (resources: GridResources, isOperator: boolean): CollectionToolbarItem[] => {
    const types = getTypeToTitleMap(resources.types);
    const configMaker = createConfigMaker<RestoreSessionsFilter>();
    const searchConfig = configMaker.search('search', { fieldConfig: { placeholder: resources.filter.reason } });
    const periodConfig = configMaker.combobox('period', {
        title: resources.filter.periodTitle,
        items: [
            { title: resources.period.lastDay, value: GetRestoreSessionsPeriod.LastDay },
            { title: resources.period.lastWeek, value: GetRestoreSessionsPeriod.LastWeek },
            { title: resources.period.lastMonth, value: GetRestoreSessionsPeriod.LastMonth },
        ],
    });
    const statusesConfig = configMaker.basic('status', {
        title: resources.filter.statusTitle,
        items: [
            { title: resources.statuses.running, icon: restoreSessionStatusIconMap.Running, value: RestoreSessionStatus.Running },
            { title: resources.statuses.success, icon: restoreSessionStatusIconMap.Success, value: RestoreSessionStatus.Success },
            { title: resources.statuses.warning, icon: restoreSessionStatusIconMap.Warning, value: RestoreSessionStatus.Warning },
            { title: resources.statuses.failed, icon: restoreSessionStatusIconMap.Failed, value: RestoreSessionStatus.Failed },
        ],
    });

    const advancedConfig = configMaker.advanced({
        valueTitle: resources.filter.title,
        filtersTitle: '' as LocalizableString,
    }).group({
        columnIndex: 0,
        title: resources.filter.typeTitle,
        property: 'type',
        items: [
            { title: types.Vex, value: RestoreSessionType.Vex },
            { title: types.Vesp, value: RestoreSessionType.Vesp },
            { title: types.Veod, value: RestoreSessionType.Veod },
            isOperator && { title: types.Vet, value: RestoreSessionType.Vet },
        ].filter(Boolean),
    });

    return createToolbarFilters(searchConfig, periodConfig, statusesConfig, advancedConfig);
};

export const useFilter = (resources: GridResources): CollectionToolbarItem[] => {
    const rbac = useRbac();

    return useMemo(() => createFilter(resources, rbac.isOperator), [resources, rbac.isOperator]);
};

