// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTOrganizationSyncOptions
 */
export interface RESTOrganizationSyncOptions {
    /**
     * Specifies the synchronization type. The following values are available: <ul> <li>*Full* - all objects in the Microsoft organization will be synchronized with the organization cache database.</li> <li>*Incremental* - only new and modified objects in the Microsoft organization will be synchronized with the organization cache database.</li> </ul> 
     * @type {string}
     * @memberof RESTOrganizationSyncOptions
     */
    type?: RESTOrganizationSyncOptionsTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOrganizationSyncOptionsTypeEnum {
    Incremental = 'Incremental',
    Full = 'Full'
}

