// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of the job session.
 * @export
 * @enum {string}
 */
export enum RESTJobSessionUpdateEventStatus {
    Stopped = 'Stopped',
    Running = 'Running',
    Success = 'Success',
    Failed = 'Failed',
    Warning = 'Warning',
    NotConfigured = 'NotConfigured'
}

