// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Retrieval policy that is selected for Azure Blob Storage Archive.
 * @export
 * @enum {string}
 */
export enum RESTAzureArchiveRetrievalPolicy {
    StandardPriority = 'StandardPriority',
    HighPriority = 'HighPriority'
}

