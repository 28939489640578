// Copyright © Veeam Software Group GmbH

import type { RESTRbacItemComposed as RbacScopeItem } from 'api/rxjs';

export enum RbacScopeType {
    User = 'User',
    Site = 'Site',
    Team = 'Team',
    PublicMailbox = 'PublicMailbox',
    SharedMailbox = 'SharedMailbox',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type RbacScopeId = string & { __rbac_scope_id: never; };
export const defaultRbacScopeId = '' as RbacScopeId;

export interface RbacScope {
    id: RbacScopeId;
    type: RbacScopeType;
    title: string;
    details: string;
    item?: RbacScopeItem;
    has: {
        exchange: boolean;
        oneDrive: boolean;
        sharePoint: boolean;
        teams: boolean;
    };
}
