/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    RESTExceptionInfo,
    RESTOrganizationSyncOptions,
    RESTOrganizationSyncState,
} from '../models';

export interface OrganizationSyncApiOrganizationSyncGetStateRequest {
    organizationId: string;
}

export interface OrganizationSyncApiOrganizationSyncStartRequest {
    organizationId: string;
    options: RESTOrganizationSyncOptions;
}

/**
 * no description
 */
export const organizationSyncApi = ({

    /**
     * Returns status of synchronization of Microsoft organization objects with the organization cache database.
     * Get Synchronization Status by Organization ID
     */
    organizationSyncGetState: ({ organizationId }: OrganizationSyncApiOrganizationSyncGetStateRequest, option?: RequestOption): Observable<Optional<RESTOrganizationSyncState>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationSyncGetState');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTOrganizationSyncState>({
            url: '/v8/Organizations/{organizationId}/SyncState'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns status of synchronization of Microsoft organization objects with the organization cache database.
     * Get Synchronization Status
     */
    organizationSyncGetStates: (option?: RequestOption): Observable<Optional<Array<RESTOrganizationSyncState>>> => {
        const headers: HttpHeaders = {
        };

        return requestAuthenticated<Array<RESTOrganizationSyncState>>({
            url: '/v8/Organizations/SyncState',
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Starts synchronization of Microsoft organization objects with the organization cache database.
     * Start Synchronization
     */
    organizationSyncStart: ({ organizationId, options }: OrganizationSyncApiOrganizationSyncStartRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationSyncStart');
        throwIfNullOrUndefined(options, 'options', 'organizationSyncStart');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/Organizations/{organizationId}/Sync'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'POST',
            headers,
            body: options,
        }, option);
    },

})
