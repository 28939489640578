// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of the event.
 * @export
 * @enum {string}
 */
export enum RESTEventType {
    RestoreSessionUpdate = 'RestoreSessionUpdate',
    RestoreSessionDelete = 'RestoreSessionDelete',
    OrganizationUserUpdate = 'OrganizationUserUpdate',
    OrganizationUserDelete = 'OrganizationUserDelete',
    OrganizationGroupUpdate = 'OrganizationGroupUpdate',
    OrganizationGroupDelete = 'OrganizationGroupDelete',
    OrganizationTeamUpdate = 'OrganizationTeamUpdate',
    OrganizationTeamDelete = 'OrganizationTeamDelete',
    OrganizationSiteUpdate = 'OrganizationSiteUpdate',
    OrganizationSiteDelete = 'OrganizationSiteDelete',
    JobUpdate = 'JobUpdate',
    JobDelete = 'JobDelete',
    JobSessionUpdate = 'JobSessionUpdate',
    JobSessionDelete = 'JobSessionDelete',
    JobSelectedItemsChange = 'JobSelectedItemsChange',
    JobExcludedItemsChange = 'JobExcludedItemsChange',
    RestorePointUpdate = 'RestorePointUpdate',
    RestorePointDelete = 'RestorePointDelete',
    ProtectedUserUpdate = 'ProtectedUserUpdate',
    ProtectedUserDelete = 'ProtectedUserDelete',
    ProtectedGroupUpdate = 'ProtectedGroupUpdate',
    ProtectedGroupDelete = 'ProtectedGroupDelete',
    ProtectedSiteUpdate = 'ProtectedSiteUpdate',
    ProtectedSiteDelete = 'ProtectedSiteDelete',
    ProtectedTeamUpdate = 'ProtectedTeamUpdate',
    ProtectedTeamDelete = 'ProtectedTeamDelete',
    M365BackupStoragePolicyUpdate = 'M365BackupStoragePolicyUpdate',
    M365BackupStoragePolicyDelete = 'M365BackupStoragePolicyDelete',
    M365BackupStoragePolicySelectedItemsChange = 'M365BackupStoragePolicySelectedItemsChange',
    RestorePointObjectsChange = 'RestorePointObjectsChange'
}

