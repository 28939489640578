// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTTeamsFile
 */
export interface RESTTeamsFile {
    /**
     * File ID.
     * @type {string}
     * @memberof RESTTeamsFile
     */
    id: string;
    /**
     * Name of the file.
     * @type {string}
     * @memberof RESTTeamsFile
     */
    name?: string;
    /**
     * File size.
     * @type {number}
     * @memberof RESTTeamsFile
     */
    sizeBytes?: number;
    /**
     * Version of the file in the backup.
     * @type {number}
     * @memberof RESTTeamsFile
     */
    version?: number;
    /**
     * Version of the file.
     * @type {string}
     * @memberof RESTTeamsFile
     */
    uiVersion?: string;
    /**
     * Date and time of the last modification of the file.
     * @type {string}
     * @memberof RESTTeamsFile
     */
    modified?: string;
    /**
     * Name of the user who performed the last modification of the file.
     * @type {string}
     * @memberof RESTTeamsFile
     */
    modifiedBy?: string;
    /**
     * Channel ID.
     * @type {string}
     * @memberof RESTTeamsFile
     */
    channelId: string;
    /**
     * Team ID.
     * @type {string}
     * @memberof RESTTeamsFile
     */
    teamId?: string;
    /**
     * Type of the Microsoft Teams item.
     * @type {string}
     * @memberof RESTTeamsFile
     */
    fileType?: RESTTeamsFileFileTypeEnum;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTTeamsFile
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTTeamsFileFileTypeEnum {
    File = 'File',
    Folder = 'Folder'
}

