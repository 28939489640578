// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of the retrieval job.
 * @export
 * @enum {string}
 */
export enum RESTDataRetrievalType {
    Exchange = 'Exchange',
    OneDrive = 'OneDrive',
    SharePoint = 'SharePoint',
    Teams = 'Teams'
}

