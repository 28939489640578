// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Protection status of the backup item.
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStorageProtectionUnitStatusNullable {
    None = 'None',
    Unprotected = 'Unprotected',
    UnprotectRequested = 'UnprotectRequested',
    Protected = 'Protected',
    ProtectRequested = 'ProtectRequested',
    RemoveRequested = 'RemoveRequested'
}

