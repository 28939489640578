// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTBackupWindowSettings,
} from './';

/**
 * @export
 * @interface RESTCopyJobSchedulePolicy
 */
export interface RESTCopyJobSchedulePolicy {
    /**
     * Backup copy job schedule type. The following types are available: <ul> <li>*Immediate*. The backup copy job runs right after the latest restore point appears in a source backup repository. During the first run of the backup copy job, Veeam Backup for Microsoft 365 copies the latest restore point created by the source backup job (backup job for which you create a backup copy job). During next runs — each subsequent restore point.</li> <li>*DailyAtTime*. The backup copy job runs on the specified days at the specified hours.</li> <li>*Periodically*. The backup copy job runs repeatedly throughout a day with a specific time interval.</li> </ul> 
     * @type {string}
     * @memberof RESTCopyJobSchedulePolicy
     */
    type?: RESTCopyJobSchedulePolicyTypeEnum;
    /**
     * Time interval between the backup copy job runs.
     * @type {string}
     * @memberof RESTCopyJobSchedulePolicy
     */
    periodicallyEvery?: RESTCopyJobSchedulePolicyPeriodicallyEveryEnum;
    /**
     * Days when the backup copy job will run.
     * @type {string}
     * @memberof RESTCopyJobSchedulePolicy
     */
    dailyType?: RESTCopyJobSchedulePolicyDailyTypeEnum;
    /**
     * Time to start the backup copy job.
     * @type {string}
     * @memberof RESTCopyJobSchedulePolicy
     */
    dailyTime?: string;
    /**
     * Defines whether the backup copy window feature is enabled for this backup copy job.
     * @type {boolean}
     * @memberof RESTCopyJobSchedulePolicy
     */
    backupCopyWindowEnabled?: boolean | null;
    /**
     * @type {RESTBackupWindowSettings}
     * @memberof RESTCopyJobSchedulePolicy
     */
    backupCopyWindowSettings?: RESTBackupWindowSettings;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTCopyJobSchedulePolicyTypeEnum {
    Immediate = 'Immediate',
    Periodically = 'Periodically',
    DailyAtTime = 'DailyAtTime'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTCopyJobSchedulePolicyPeriodicallyEveryEnum {
    Minutes5 = 'Minutes5',
    Minutes10 = 'Minutes10',
    Minutes15 = 'Minutes15',
    Minutes30 = 'Minutes30',
    Hours1 = 'Hours1',
    Hours2 = 'Hours2',
    Hours4 = 'Hours4',
    Hours8 = 'Hours8',
    Hours12 = 'Hours12'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTCopyJobSchedulePolicyDailyTypeEnum {
    Everyday = 'Everyday',
    Workdays = 'Workdays',
    Weekends = 'Weekends',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

