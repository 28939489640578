// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum JobUpgradeActions {
    None = 0,
    ExchangePartUpdate = 1,
    SharePointPartUpdate = 2,
    GroupIdsUpdate = 4,
    JobEnable = 8
}
