// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTSummaryItem
 */
export interface RESTSummaryItem {
    /**
     * ID of the teams item.
     * @type {string}
     * @memberof RESTSummaryItem
     */
    readonly id?: string;
    /**
     * Name of the teams item.
     * @type {string}
     * @memberof RESTSummaryItem
     */
    readonly name?: string;
    /**
     * Title of the teams item.
     * @type {string}
     * @memberof RESTSummaryItem
     */
    readonly title?: string;
    /**
     * Type of the Microsoft Teams item.
     * @type {string}
     * @memberof RESTSummaryItem
     */
    readonly type?: RESTSummaryItemTypeEnum;
    /**
     * Array of warnings appeared during the restore operation.
     * @type {Array<string>}
     * @memberof RESTSummaryItem
     */
    readonly warnings?: Array<string>;
    /**
     * Error occurred during the restore operation.
     * @type {string}
     * @memberof RESTSummaryItem
     */
    readonly error?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTSummaryItemTypeEnum {
    Team = 'Team',
    PostsTab = 'PostsTab',
    Post = 'Post',
    TeamApp = 'TeamApp',
    Channel = 'Channel',
    Tab = 'Tab',
    File = 'File'
}

