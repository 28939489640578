// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTCommonDeviceCodeRequestOptions
 */
export interface RESTCommonDeviceCodeRequestOptions {
    /**
     * Specifies the region of the Microsoft 365 organization that you plan to add.
     * @type {string}
     * @memberof RESTCommonDeviceCodeRequestOptions
     */
    targetRegion?: RESTCommonDeviceCodeRequestOptionsTargetRegionEnum;
    /**
     * Specifies the identification number of the Microsoft 365 organization in which Microsoft Graph API application you want to log in using the device code. For more information on how to get this parameter, see [Get Organizations](#tag/Organization/operation/Organization_Get).
     * @type {string}
     * @memberof RESTCommonDeviceCodeRequestOptions
     */
    loginGraphAppOrganizationId?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTCommonDeviceCodeRequestOptionsTargetRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

