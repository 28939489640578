// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTLicense
 */
export interface RESTLicense {
    /**
     * ID of the installed license.
     * @type {string}
     * @memberof RESTLicense
     */
    licenseID?: string | null;
    /**
     * Email of a user to which the license was issued.
     * @type {string}
     * @memberof RESTLicense
     */
    email?: string;
    /**
     * Status of the installed license.
     * @type {string}
     * @memberof RESTLicense
     */
    status?: RESTLicenseStatusEnum;
    /**
     * Date and time when the license expires.
     * @type {string}
     * @memberof RESTLicense
     */
    licenseExpires?: string | null;
    /**
     * Date and time when a grace period expires. A grace period is granted after the expiration of license for purpose of renewal.
     * @type {string}
     * @memberof RESTLicense
     */
    gracePeriodExpires?: string | null;
    /**
     * Type of the license. For more information about license types, see the [Licensing and License Types](https://helpcenter.veeam.com/docs/vbo365/guide/vbo_licensing.html?ver=80) section of the Veeam Backup for Microsoft 365 User Guide.
     * @type {string}
     * @memberof RESTLicense
     */
    type?: RESTLicenseTypeEnum;
    /**
     * Specifies information if Veeam Service Provider Console or Veeam One is allowed to monitor the Veeam Backup for Microsoft 365 server.
     * @type {string}
     * @memberof RESTLicense
     */
    _package?: string;
    /**
     * Company to which the license was issued.
     * @type {string}
     * @memberof RESTLicense
     */
    licensedTo?: string;
    /**
     * Total number of units within the license.
     * @type {number}
     * @memberof RESTLicense
     */
    totalNumber?: number;
    /**
     * Number of units consumed by objects.
     * @type {number}
     * @memberof RESTLicense
     */
    usedNumber?: number;
    /**
     * Number of users with the *new user* status.
     * @type {number}
     * @memberof RESTLicense
     */
    newNumber?: number;
    /**
     * Support ID of the installed license.
     * @type {string}
     * @memberof RESTLicense
     */
    supportID?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTLicenseStatusEnum {
    Valid = 'Valid',
    Expired = 'Expired'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTLicenseTypeEnum {
    Subscription = 'Subscription',
    Evaluation = 'Evaluation',
    Nfr = 'NFR',
    Rental = 'Rental',
    Community = 'Community',
    AwsPrivateOffer = 'AwsPrivateOffer'
}

