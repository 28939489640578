// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTDeviceCodeRequestOptions
 */
export interface RESTDeviceCodeRequestOptions {
    /**
     * Specifies the ID of the Microsoft Entra application that you want to use for data restore.
     * @type {string}
     * @memberof RESTDeviceCodeRequestOptions
     */
    targetApplicationId?: string | null;
    /**
     * Specifies a region of the Microsoft 365 organization that contains data you want to restore.  **Note**: This property is only required when planning to restore to a custom location. 
     * @type {string}
     * @memberof RESTDeviceCodeRequestOptions
     */
    targetRegion?: RESTDeviceCodeRequestOptionsTargetRegionEnum;
    /**
     * Specifies a name of the Microsoft 365 organization to which you want to restore data.  **Note**: This property is only required when planning to restore to a custom location. 
     * @type {string}
     * @memberof RESTDeviceCodeRequestOptions
     */
    targetOrganizationName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTDeviceCodeRequestOptionsTargetRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

