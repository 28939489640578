// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum MonthlyDayOrder {
    First = 0,
    Second = 1,
    Third = 2,
    Fourth = 3,
    Last = 4
}
