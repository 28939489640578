// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTProtectedMailbox,
    RESTProtectedOneDrive,
    RESTProtectedSite,
} from './';

/**
 * @export
 * @interface RESTProtectedUser
 */
export interface RESTProtectedUser {
    /**
     * User ID.
     * @type {string}
     * @memberof RESTProtectedUser
     */
    id?: string;
    /**
     * ID of the protected user assigned by Microsoft.
     * @type {string}
     * @memberof RESTProtectedUser
     */
    msid?: string | null;
    /**
     * Display name of the backed-up user.
     * @type {string}
     * @memberof RESTProtectedUser
     */
    displayName?: string;
    /**
     * Type of the user account.
     * @type {string}
     * @memberof RESTProtectedUser
     */
    accountType?: RESTProtectedUserAccountTypeEnum;
    /**
     * Backed-up organization ID.
     * @type {string}
     * @memberof RESTProtectedUser
     */
    organizationId?: string | null;
    /**
     * ID of the backed-up organization in the backup.
     * @type {string}
     * @memberof RESTProtectedUser
     */
    backedUpOrganizationId?: string;
    /**
     * Array of protected mailboxes.
     * @type {Array<RESTProtectedMailbox>}
     * @memberof RESTProtectedUser
     */
    mailboxes?: Array<RESTProtectedMailbox>;
    /**
     * Array of protected OneDrives.
     * @type {Array<RESTProtectedOneDrive>}
     * @memberof RESTProtectedUser
     */
    oneDrives?: Array<RESTProtectedOneDrive>;
    /**
     * Array of protected SharePoint sites.
     * @type {Array<RESTProtectedSite>}
     * @memberof RESTProtectedUser
     */
    sites?: Array<RESTProtectedSite>;
    /**
     * Version number that Veeam Backup for Microsoft 365 assigns if the protected user was modified.
     * @type {number}
     * @memberof RESTProtectedUser
     */
    eTag?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTProtectedUserAccountTypeEnum {
    User = 'User',
    SharedMailbox = 'SharedMailbox',
    PublicMailbox = 'PublicMailbox'
}

