/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAnonymous, Optional, RequestOption, HttpHeaders } from '../runtime';
import {
    RESTExceptionInfo,
    RESTRestorePortalSettings,
} from '../models';

/**
 * no description
 */
export const restorePortalSettingsApi = ({

    /**
     * Returns a resource representation of settings used to connect to Restore Portal.
     * Get Restore Portal Settings
     */
    restorePortalSettingsGet: (option?: RequestOption): Observable<Optional<RESTRestorePortalSettings>> => {
        const headers: HttpHeaders = {
        };

        return requestAnonymous<RESTRestorePortalSettings>({
            url: '/v8/RestorePortalSettings',
            method: 'GET',
            headers,
        }, option);
    },

})
