// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTOrganizationLastSyncState
 */
export interface RESTOrganizationLastSyncState {
    /**
     * Type of the synchronization.
     * @type {string}
     * @memberof RESTOrganizationLastSyncState
     */
    type?: RESTOrganizationLastSyncStateTypeEnum;
    /**
     * Result of the synchronization.
     * @type {string}
     * @memberof RESTOrganizationLastSyncState
     */
    result?: RESTOrganizationLastSyncStateResultEnum;
    /**
     * Date and time when the synchronization was started.
     * @type {string}
     * @memberof RESTOrganizationLastSyncState
     */
    startTime?: string;
    /**
     * Date and time when the synchronization ended.
     * @type {string}
     * @memberof RESTOrganizationLastSyncState
     */
    endTime?: string;
    /**
     * Error occurred during the synchronization operation.
     * @type {string}
     * @memberof RESTOrganizationLastSyncState
     */
    error?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOrganizationLastSyncStateTypeEnum {
    Incremental = 'Incremental',
    Full = 'Full'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTOrganizationLastSyncStateResultEnum {
    Success = 'Success',
    Error = 'Error'
}

