// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTBackupMailboxData,
    RESTBackupOneDriveData,
    RESTBackupSiteData,
    RESTBackupTeamData,
} from './';

/**
 * @export
 * @interface RESTDataRetrievalFromClient
 */
export interface RESTDataRetrievalFromClient {
    /**
     * Specifies a name of the retrieval job.
     * @type {string}
     * @memberof RESTDataRetrievalFromClient
     */
    name?: string | null;
    /**
     * Specifies a description of the retrieval job.
     * @type {string}
     * @memberof RESTDataRetrievalFromClient
     */
    description?: string | null;
    /**
     * Specifies the backed-up organization unique ID.
     * @type {string}
     * @memberof RESTDataRetrievalFromClient
     */
    organizationId?: string;
    /**
     * Specifies the ID of one of the following object storage repositories: Azure Blob Storage Archive access tier, Amazon S3 Glacier Flexible Retrieval or Amazon S3 Glacier Deep Archive storage classes. 
     * @type {string}
     * @memberof RESTDataRetrievalFromClient
     */
    repositoryId?: string | null;
    /**
     * Specifies point in time that you want to retrieve from one of the following object storage repositories: Azure Blob Storage Archive access tier, Amazon S3 Glacier Flexible Retrieval or Amazon S3 Glacier Deep Archive storage classes. 
     * @type {string}
     * @memberof RESTDataRetrievalFromClient
     */
    pointInTime?: string | null;
    /**
     * Defines whether the data retrieval session will show items that have been removed by the user before the specified point in time.
     * @type {boolean}
     * @memberof RESTDataRetrievalFromClient
     */
    showDeleted?: boolean | null;
    /**
     * Defines whether the data retrieval session will show all versions of items that have been modified by the user before the specified point in time.
     * @type {boolean}
     * @memberof RESTDataRetrievalFromClient
     */
    showAllVersions?: boolean | null;
    /**
     * Specifies how many days you will be able to explore and restore the retrieved data using Veeam Explorers.
     * @type {number}
     * @memberof RESTDataRetrievalFromClient
     */
    availabilityPeriodDays?: number;
    /**
     * Defines whether Veeam Backup for Microsoft 365 will send a notification by email before the retrieved data expires.
     * @type {boolean}
     * @memberof RESTDataRetrievalFromClient
     */
    enableExpirationNotification?: boolean | null;
    /**
     * Specifies how many hours should remain before the retrieved data expires to send a notification by email.  Use this property only with the `enableExpirationNotification` property.
     * @type {number}
     * @memberof RESTDataRetrievalFromClient
     */
    expirationHoursThreshold?: number | null;
    /**
     * Specifies IDs of the mailboxes whose backed-up data you want to retrieve from object storage repository. For more information on how to get such IDs, see [Get Mailbox Data by Repository ID](#tag/BackupMailboxData/operation/BackupMailboxData_GetMailboxesData).
     * @type {Array<RESTBackupMailboxData>}
     * @memberof RESTDataRetrievalFromClient
     */
    mailboxes?: Array<RESTBackupMailboxData>;
    /**
     * Specifies IDs of OneDrives whose backed-up data you want to retrieve from object storage repository. For more information on how to get such IDs, see [Get OneDrive Data by Repository ID](#tag/BackupOneDriveData/operation/BackupOneDriveData_GetOneDrivesData).
     * @type {Array<RESTBackupOneDriveData>}
     * @memberof RESTDataRetrievalFromClient
     */
    oneDrives?: Array<RESTBackupOneDriveData>;
    /**
     * Specifies IDs of the SharePoint sites whose backed-up data you want to retrieve from object storage repository. For more information on how to get such IDs, see [Get SharePoint Data by Repository ID](#tag/BackupSiteData/operation/BackupSiteData_GetSitesData).
     * @type {Array<RESTBackupSiteData>}
     * @memberof RESTDataRetrievalFromClient
     */
    sites?: Array<RESTBackupSiteData>;
    /**
     * Specifies IDs of the teams whose backed-up data you want to retrieve from object storage repository. For more information on how to get such IDs, see [Get Teams Data by Repository ID](#tag/BackupTeamData/operation/BackupTeamData_GetTeamsData).
     * @type {Array<RESTBackupTeamData>}
     * @memberof RESTDataRetrievalFromClient
     */
    teams?: Array<RESTBackupTeamData>;
    /**
     * Specifies the retrieval policy for Amazon S3 Glacier Flexible Retrieval and Amazon S3 Glacier Deep Archive object storage repositories.
     * @type {string}
     * @memberof RESTDataRetrievalFromClient
     */
    amazonS3GlacierRetrievalPolicy?: RESTDataRetrievalFromClientAmazonS3GlacierRetrievalPolicyEnum;
    /**
     * Specifies the retrieval policy for Azure Blob Storage Archive.
     * @type {string}
     * @memberof RESTDataRetrievalFromClient
     */
    azureArchiveRetrievalPolicy?: RESTDataRetrievalFromClientAzureArchiveRetrievalPolicyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTDataRetrievalFromClientAmazonS3GlacierRetrievalPolicyEnum {
    Expedited = 'Expedited',
    Standard = 'Standard',
    Bulk = 'Bulk'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTDataRetrievalFromClientAzureArchiveRetrievalPolicyEnum {
    StandardPriority = 'StandardPriority',
    HighPriority = 'HighPriority'
}

