/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTGroup,
    RESTExceptionInfo,
    RESTGroup,
} from '../models';

export interface OrganizationGroupApiOrganizationGroupGetRequest {
    organizationId: string;
    limit: number;
    offset: number;
    setId?: string;
    displayName?: string;
    groupName?: string;
    locationFilter?: OrganizationGroupGetLocationFilterEnum;
    dataSource?: OrganizationGroupGetDataSourceEnum;
}

export interface OrganizationGroupApiOrganizationGroupGetByIdRequest {
    organizationId: string;
    groupId: string;
    dataSource?: OrganizationGroupGetByIdDataSourceEnum;
}

/**
 * no description
 */
export const organizationGroupApi = ({

    /**
     * Returns a collection of organization groups.
     * Get Organization Groups
     */
    organizationGroupGet: ({ organizationId, limit, offset, setId, displayName, groupName, locationFilter, dataSource }: OrganizationGroupApiOrganizationGroupGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTGroup>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationGroupGet');
        throwIfNullOrUndefined(limit, 'limit', 'organizationGroupGet');
        throwIfNullOrUndefined(offset, 'offset', 'organizationGroupGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (setId != null) { query['setId'] = setId; }
        if (displayName != null) { query['displayName'] = displayName; }
        if (groupName != null) { query['groupName'] = groupName; }
        if (locationFilter != null) { query['locationFilter'] = locationFilter; }
        if (dataSource != null) { query['dataSource'] = dataSource; }

        return requestAuthenticated<PageOfRESTGroup>({
            url: '/v8/Organizations/{organizationId}/Groups'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a resource representation of an organization group with the specified ID.
     * Get Organization Group
     */
    organizationGroupGetById: ({ organizationId, groupId, dataSource }: OrganizationGroupApiOrganizationGroupGetByIdRequest, option?: RequestOption): Observable<Optional<RESTGroup>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationGroupGetById');
        throwIfNullOrUndefined(groupId, 'groupId', 'organizationGroupGetById');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = {};

        if (dataSource != null) { query['dataSource'] = dataSource; }

        return requestAuthenticated<RESTGroup>({
            url: '/v8/Organizations/{organizationId}/Groups/{groupId}'.replace('{organizationId}', encodeURI(organizationId)).replace('{groupId}', encodeURI(groupId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum OrganizationGroupGetLocationFilterEnum {
    Cloud = 'Cloud',
    OnPremises = 'OnPremises',
    Hybrid = 'Hybrid',
    CloudOrHybrid = 'CloudOrHybrid',
    OnPremisesOrHybrid = 'OnPremisesOrHybrid',
    Any = 'Any'
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationGroupGetDataSourceEnum {
    PreferLocal = 'PreferLocal',
    Production = 'Production',
    PreferLocalResynced = 'PreferLocalResynced'
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationGroupGetByIdDataSourceEnum {
    PreferLocal = 'PreferLocal',
    Production = 'Production',
    PreferLocalResynced = 'PreferLocalResynced'
}
