// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of the object storage repository.
 * @export
 * @enum {string}
 */
export enum RESTDataRetrievalStorageType {
    AzureArchive = 'AzureArchive',
    AmazonS3Glacier = 'AmazonS3Glacier'
}

