// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Specifies the object storage repository type.
 * @export
 * @enum {string}
 */
export enum RESTObjectStorageType {
    AzureBlob = 'AzureBlob',
    AmazonS3 = 'AmazonS3',
    AmazonS3Compatible = 'AmazonS3Compatible',
    AzureBlobArchive = 'AzureBlobArchive',
    AmazonS3Glacier = 'AmazonS3Glacier',
    WasabiCloud = 'WasabiCloud',
    IbmCloud = 'IBMCloud'
}

