// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTTeamsChannelPreview
 */
export interface RESTTeamsChannelPreview {
    /**
     * ID of the backed-up channel.
     * @type {string}
     * @memberof RESTTeamsChannelPreview
     */
    id: string;
    /**
     * Display name of the backed-up channel.
     * @type {string}
     * @memberof RESTTeamsChannelPreview
     */
    displayName?: string;
    /**
     * Team ID.
     * @type {string}
     * @memberof RESTTeamsChannelPreview
     */
    teamId?: string;
    /**
     * Type of the backed-up channel.
     * @type {string}
     * @memberof RESTTeamsChannelPreview
     */
    type?: RESTTeamsChannelPreviewTypeEnum;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTTeamsChannelPreview
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTTeamsChannelPreviewTypeEnum {
    Standard = 'Standard',
    Private = 'Private',
    UnknownFutureValue = 'UnknownFutureValue',
    Shared = 'Shared'
}

