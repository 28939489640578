// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTRestoreToOriginal
 */
export interface RESTRestoreToOriginal {
    /**
     * Specifies the action that will be performed in case the restore destination contains the restored documents.
     * @type {string}
     * @memberof RESTRestoreToOriginal
     */
    documentAction?: RESTRestoreToOriginalDocumentActionEnum;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreToOriginal
     */
    userCode?: string;
    /**
     * Specifies the ID of the Micrisoft Entra application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreToOriginal
     */
    applicationId?: string | null;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToOriginal
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Microsoft Entra application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreToOriginal
     */
    applicationCertificate?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTRestoreToOriginal
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToOriginal
     */
    userPassword?: string;
    /**
     * Specifies the region of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToOriginal
     */
    officeRegion?: RESTRestoreToOriginalOfficeRegionEnum;
    /**
     * Specifies the name of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToOriginal
     */
    organizationName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToOriginalDocumentActionEnum {
    Overwrite = 'Overwrite',
    Keep = 'Keep'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToOriginalOfficeRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

