// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status that Veeam Backup for Microsoft assigns as a result of a health check.
 * @export
 * @enum {string}
 */
export enum RESTHealthStatus {
    Unhealthy = 'Unhealthy',
    Healthy = 'Healthy'
}

