// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum JobExtendedStatus {
    None = 0,
    Disconnected = 6,
    Queued = 7,
    Updating = 8
}
