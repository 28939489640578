// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTExceptionInfo
 */
export interface RESTExceptionInfo {
    /**
     * Error message.
     * @type {string}
     * @memberof RESTExceptionInfo
     */
    message?: string;
    /**
     * Error code.
     * @type {string}
     * @memberof RESTExceptionInfo
     */
    errorCode?: RESTExceptionInfoErrorCodeEnum;
    /**
     * Error stack trace.
     * @type {string}
     * @memberof RESTExceptionInfo
     */
    stackTrace?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTExceptionInfoErrorCodeEnum {
    ProxyOffline = 'ProxyOffline',
    OutdatedChangeToken = 'OutdatedChangeToken',
    ResourceAccessDenied = 'ResourceAccessDenied',
    ResourceNotFound = 'ResourceNotFound',
    ParameterValidationFailure = 'ParameterValidationFailure',
    OrganizationTransfer = 'OrganizationTransfer',
    RepositoryOwnerChangeTooManyLinks = 'RepositoryOwnerChangeTooManyLinks',
    Timeout = 'Timeout',
    OperationConflict = 'OperationConflict',
    HttpError = 'HttpError'
}

