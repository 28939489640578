// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTOrganizationCurrentSyncState
 */
export interface RESTOrganizationCurrentSyncState {
    /**
     * Type of the synchronization.
     * @type {string}
     * @memberof RESTOrganizationCurrentSyncState
     */
    type?: RESTOrganizationCurrentSyncStateTypeEnum;
    /**
     * Date and time when the synchronization was scheduled.
     * @type {string}
     * @memberof RESTOrganizationCurrentSyncState
     */
    scheduledTime?: string | null;
    /**
     * Date and time when the synchronization was started.
     * @type {string}
     * @memberof RESTOrganizationCurrentSyncState
     */
    startTime?: string | null;
    /**
     * Status of the synchronization.
     * @type {string}
     * @memberof RESTOrganizationCurrentSyncState
     */
    status?: RESTOrganizationCurrentSyncStateStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOrganizationCurrentSyncStateTypeEnum {
    Incremental = 'Incremental',
    Full = 'Full'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTOrganizationCurrentSyncStateStatusEnum {
    Queued = 'Queued',
    Running = 'Running'
}

