// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of a job for which the event was created.
 * @export
 * @enum {string}
 */
export enum RESTEventJobType {
    Backup = 'Backup',
    Copy = 'Copy'
}

