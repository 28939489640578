/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery } from '../runtime';
import {
    RESTExceptionInfo,
    RESTOperatorInfo,
    RESTRbacEffectiveScope,
    RESTRbacLoggedInUser,
    RESTRestorePoint,
} from '../models';

export interface OrganizationRbacApiOrganizationRbacGetLoggedInUserRestorePointsRequest {
    rbacItem?: string;
    isCopy?: boolean;
}

/**
 * no description
 */
export const organizationRbacApi = ({

    /**
     * Returns a resource representation of information about a Microsoft 365 organization users currently logged in to Restore Portal.
     * Get Logged-In Users
     */
    organizationRbacGetLoggedInUser: (option?: RequestOption): Observable<Optional<RESTRbacLoggedInUser>> => {
        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTRbacLoggedInUser>({
            url: '/v8/Organization/LoggedInUser',
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a resource representation of objects that are managed by the restore operators currently logged in to Restore Portal.
     * Get Objects to Manage
     */
    organizationRbacGetLoggedInUserEffectiveScope: (option?: RequestOption): Observable<Optional<RESTRbacEffectiveScope>> => {
        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTRbacEffectiveScope>({
            url: '/v8/Organization/LoggedInUser/EffectiveScope',
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a resource representation of information if the users currently logged in to Restore Portal act as restore operators.
     * Get Information on Logged-In Users
     */
    organizationRbacGetLoggedInUserOperatorInfo: (option?: RequestOption): Observable<Optional<RESTOperatorInfo>> => {
        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTOperatorInfo>({
            url: '/v8/Organization/LoggedInUser/OperatorInfo',
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a resource representation of restore points created by Veeam Backup for Microsoft 365 for organization users currently logged in to Restore Portal.
     * Get Restore Points
     */
    organizationRbacGetLoggedInUserRestorePoints: ({ rbacItem, isCopy }: OrganizationRbacApiOrganizationRbacGetLoggedInUserRestorePointsRequest, option?: RequestOption): Observable<Optional<Array<RESTRestorePoint>>> => {

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = {};

        if (rbacItem != null) { query['rbacItem'] = rbacItem; }
        if (isCopy != null) { query['isCopy'] = isCopy; }

        return requestAuthenticated<Array<RESTRestorePoint>>({
            url: '/v8/Organization/LoggedInUser/RestorePoints',
            method: 'GET',
            headers,
            query,
        }, option);
    },

})
