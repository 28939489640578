// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTTeamsTeamFunSettings
 */
export interface RESTTeamsTeamFunSettings {
    /**
     * Defines whether users are allowed to include custom memes.
     * @type {boolean}
     * @memberof RESTTeamsTeamFunSettings
     */
    allowCustomMemes?: boolean;
    /**
     * Defines whether usage of Giphy is allowed.
     * @type {boolean}
     * @memberof RESTTeamsTeamFunSettings
     */
    allowGiphy?: boolean;
    /**
     * Defines whether users are allowed to include stickers and memes.
     * @type {boolean}
     * @memberof RESTTeamsTeamFunSettings
     */
    allowStickersAndMemes?: boolean;
    /**
     * Giphy content rating.
     * @type {string}
     * @memberof RESTTeamsTeamFunSettings
     */
    giphyContentRating?: RESTTeamsTeamFunSettingsGiphyContentRatingEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTTeamsTeamFunSettingsGiphyContentRatingEnum {
    Moderate = 'Moderate',
    Strict = 'Strict',
    UnknownFutureValue = 'UnknownFutureValue'
}

