// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTRestoreSession
 */
export interface RESTRestoreSession {
    /**
     * Restore session ID.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    id: string;
    /**
     * Name of the restore session.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    name?: string;
    /**
     * Name of the Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    organization?: string;
    /**
     * Type of the restore session.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    type: RESTRestoreSessionTypeEnum;
    /**
     * Date and time when the restore session was started.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    creationTime: string;
    /**
     * Date and time when the restore session ended.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    endTime?: string | null;
    /**
     * State of the restore session.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    state: RESTRestoreSessionStateEnum;
    /**
     * Result of the restore session.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    result: RESTRestoreSessionResultEnum;
    /**
     * Name of the user that initiated the restore session.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    initiatedBy?: string;
    /**
     * Restore session details.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    details?: string;
    /**
     * Scope of the restore session created using Restore Portal.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    scopeName?: string;
    /**
     * DNS name or IP address of the Veeam Backup for Microsoft 365 server.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    clientHost?: string;
    /**
     * Reason to perform restore operation.
     * @type {string}
     * @memberof RESTRestoreSession
     */
    reason?: string;
    /**
     * Version number that Veeam Backup for Microsoft 365 assigns if the restore session was modified.
     * @type {number}
     * @memberof RESTRestoreSession
     */
    eTag?: number;
    /**
     * Related resources.
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTRestoreSession
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreSessionTypeEnum {
    Vex = 'Vex',
    Veod = 'Veod',
    Vesp = 'Vesp',
    Vet = 'Vet'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreSessionStateEnum {
    Working = 'Working',
    Stopped = 'Stopped'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreSessionResultEnum {
    Success = 'Success',
    Warning = 'Warning',
    Failed = 'Failed'
}

