// Copyright © Veeam Software Group GmbH

import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';

import { TeamsNodeType } from '../../../models/Teams';
import { loadPages } from '../../../../infrastructure/rxjs';
import { teamsApi } from './wrapped-teams-api';
import {
    convertChannelItemFromRest,
    convertFileItemFromRest,
    convertFilesItemFromRest,
    convertFolderItemFromRest,
    convertPostItemFromRest,
    convertPostsItemFromRest,
    convertTabItemFromRest,
    convertTabsItemFromRest,
    convertTeamItemFromRest,
} from './converters';
import { RESTTeamsFileFileTypeEnum, RESTTeamsNodeTypeEnum } from '../../../../api/rxjs';

import type { Batch, LoadPagesConfig } from '../../../../infrastructure/rxjs';
import type { TeamsNode, TeamsItem } from '../../../models/Teams';
import type { VetSession } from '../../../models';

export const loadTeamsItems = (
    restoreSessionId: VetSession,
    parent: TeamsNode,
    config: LoadPagesConfig | undefined
): Observable<Batch<TeamsItem>> => {
    switch (parent.nodeType) {
        case TeamsNodeType.Teams:
            return loadPages(teamsApi.getTeams)({
                restoreSessionId,
            })
                .pipe(
                    map(teams => teams.mapData(item => convertTeamItemFromRest(item, parent))),
                );
        case TeamsNodeType.Team:
            return loadPages(teamsApi.getTeamItems, config)({
                teamId: parent.id,
                restoreSessionId,
            })
                .pipe(
                    map(channels => channels.mapData(item => convertChannelItemFromRest(item, parent))),
                );
        case TeamsNodeType.Channel:
            return loadPages(teamsApi.getChannelItems, config)({
                teamId: parent.teamId,
                restoreSessionId,
                channelId: parent.id,
            })
                .pipe(
                    map(nodes => nodes.mapData((node) => {
                        if (node.type === RESTTeamsNodeTypeEnum.Files) return convertFilesItemFromRest(node, parent);
                        if (node.type === RESTTeamsNodeTypeEnum.Posts) return convertPostsItemFromRest(node, parent);
                        if (node.type === RESTTeamsNodeTypeEnum.Tabs) return convertTabsItemFromRest(node, parent);
                    })),
                );
        case TeamsNodeType.Files:
            return loadPages(teamsApi.getFileItems, config)({
                teamId: parent.teamId,
                restoreSessionId,
                channelId: parent.channelId,
                parentId: 'root',
            })
                .pipe(
                    map(items => items.mapData((item) => {
                        if (item.fileType === RESTTeamsFileFileTypeEnum.Folder) {
                            return convertFolderItemFromRest(item, false, parent);
                        }

                        return convertFileItemFromRest(item, false, parent);
                    })),
                );
        case TeamsNodeType.Tabs:
            return loadPages(teamsApi.getTabItems, config)({
                teamId: parent.teamId,
                restoreSessionId,
                channelId: parent.channelId,
            })
                .pipe(
                    map(tabs => tabs.mapData(item => convertTabItemFromRest(item, parent))),
                );
        case TeamsNodeType.Posts:
            return loadPages(teamsApi.getPostItems, config)({
                teamId: parent.teamId,
                channelId: parent.channelId,
                restoreSessionId,
                parentId: -1,
            })
                .pipe(
                    map(posts => posts.mapData(item => convertPostItemFromRest(item, parent))),
                );
        case TeamsNodeType.Folder:
            return loadPages(teamsApi.getFileItems, config)({
                teamId: parent.teamId,
                restoreSessionId,
                channelId: parent.channelId,
                parentId: parent.id,
            })
                .pipe(
                    map(items => items.mapData((item) => {
                        if (item.fileType === RESTTeamsFileFileTypeEnum.Folder) {
                            return convertFolderItemFromRest(item, false, parent);
                        }

                        return convertFileItemFromRest(item, false, parent);
                    })),
                );
    }
};
