// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Specifies authentication method that Veeam Backup for Microsoft 365 and Veeam Explorers use to send emails.
 * @export
 * @enum {string}
 */
export enum RESTAuthenticationType {
    CustomSmtp = 'CustomSmtp',
    GoogleGmail = 'GoogleGmail',
    Microsoft365 = 'Microsoft365'
}

