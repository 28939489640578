// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTRestoreListConfig
 */
export interface RESTRestoreListConfig {
    /**
     * Specifies the target SharePoint list.
     * @type {string}
     * @memberof RESTRestoreListConfig
     */
    list?: string;
    /**
     * Defines whether the SharePoint lists will be restored with all list views.
     * @type {boolean}
     * @memberof RESTRestoreListConfig
     */
    restoreListViews?: boolean | null;
    /**
     * Defines whether the SharePoint list/document library will be restored with all modified items.
     * @type {boolean}
     * @memberof RESTRestoreListConfig
     */
    changedItems?: boolean | null;
    /**
     * Defines whether  the SharePoint list/document library will be restored with all deleted items.
     * @type {boolean}
     * @memberof RESTRestoreListConfig
     */
    deletedItems?: boolean | null;
    /**
     * Defines whether the SharePoint lists/libraries will be restored with all permissions.
     * @type {boolean}
     * @memberof RESTRestoreListConfig
     */
    restorePermissions?: boolean;
    /**
     * Defines whether the shared links notifications will be sent.
     * @type {boolean}
     * @memberof RESTRestoreListConfig
     */
    sendSharedLinksNotification?: boolean | null;
    /**
     * Specifies what version of the SharePoint documents will be restored.
     * @type {string}
     * @memberof RESTRestoreListConfig
     */
    documentVersion?: RESTRestoreListConfigDocumentVersionEnum;
    /**
     * Specifies the action that will be performed with the last version of the restored SharePoint document on the destination server.
     * @type {string}
     * @memberof RESTRestoreListConfig
     */
    documentLastVersionAction?: RESTRestoreListConfigDocumentLastVersionActionEnum;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreListConfig
     */
    userCode?: string;
    /**
     * Specifies the ID of the Micrisoft Entra application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreListConfig
     */
    applicationId?: string | null;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreListConfig
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Microsoft Entra application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreListConfig
     */
    applicationCertificate?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTRestoreListConfig
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreListConfig
     */
    userPassword?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreListConfigDocumentVersionEnum {
    All = 'All',
    Last = 'Last'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreListConfigDocumentLastVersionActionEnum {
    Overwrite = 'Overwrite',
    Merge = 'Merge'
}

