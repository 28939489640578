// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTAccountToSend
 */
export interface RESTAccountToSend {
    /**
     * Specifies a cloud account type.
     * @type {string}
     * @memberof RESTAccountToSend
     */
    accountType?: RESTAccountToSendAccountTypeEnum;
    /**
     * Specifies a cloud account user name. Must contain only lowercase characters.
     * @type {string}
     * @memberof RESTAccountToSend
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTAccountToSend
     */
    password?: string;
    /**
     * Specifies a cloud account ID.
     * @type {string}
     * @memberof RESTAccountToSend
     */
    id?: string | null;
    /**
     * Specifies a cloud account description.
     * @type {string}
     * @memberof RESTAccountToSend
     */
    description?: string;
    /**
     * Backed-up organization ID.
     * @type {string}
     * @memberof RESTAccountToSend
     */
    organizationId?: string | null;
    /**
     * Related resources.
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTAccountToSend
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTAccountToSendAccountTypeEnum {
    AzureBlobAccount = 'azureBlobAccount',
    AmazonS3Account = 'amazonS3Account',
    AmazonS3CompatibleAccount = 'amazonS3CompatibleAccount'
}

