// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type {
    RESTSharePointItemComposed } from 'api/rxjs';
import type { Batch, LoadPagesConfig } from 'infrastructure/rxjs';
import type { Override1 } from 'infrastructure/types';
import type { SharePointItem, SharePointNode, VespSession, SharePointList, SharePointLibrary, SharePointFolder } from 'services/models';
import type { GetItemsRequest } from 'services/explore/interfaces/get-items-request';

import { convert } from './converters';
import { loadPages } from 'infrastructure/rxjs';
import { never } from 'infrastructure/never';
import {
    SharePointFolderSearchFolderByOptionsItemTypeEnum, SharePointLibrarySearchLibraryByOptionsItemTypeEnum,
    SharePointListSearchListByOptionsItemTypeEnum, SharePointSiteSearchSiteByOptionsItemTypeEnum,
} from 'api/rxjs';
import { sharePointApi } from './wrappedSharePointApi';
import { SharePointNodeType } from 'services/models';

export type GetSharePointItemsRequest = Override1<GetItemsRequest, 'parent', SharePointNode>;

const searchItemsInSite = (session: VespSession, parent: SharePointNode, search: string, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> =>
    loadPages(sharePointApi.searchIn.site, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        itemType: SharePointSiteSearchSiteByOptionsItemTypeEnum.All,
        searchOptions: {
            query: search,
        },
    });

const searchItemsInList = (session: VespSession, parent: SharePointList, search: string, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> =>
    loadPages(sharePointApi.searchIn.list, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        listId: parent.id,
        itemType: SharePointListSearchListByOptionsItemTypeEnum.All,
        searchOptions: {
            query: search,
        },
    });

const searchItemsInLibrary = (session: VespSession, parent: SharePointLibrary, search: string, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> =>
    loadPages(sharePointApi.searchIn.library, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        libraryId: parent.id,
        itemType: SharePointLibrarySearchLibraryByOptionsItemTypeEnum.All,
        searchOptions: {
            query: search,
        },
    });

const searchItemsInFolder = (session: VespSession, parent: SharePointFolder, search: string, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> =>
    loadPages(sharePointApi.searchIn.folder, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        folderId: parent.id,
        itemType: SharePointFolderSearchFolderByOptionsItemTypeEnum.All,
        searchOptions: {
            query: search,
        },
    });

const getSearchingFetch = (session: VespSession, parent: SharePointNode, search: string, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> => {
    switch (parent.nodeType) {
        case SharePointNodeType.Site:
        case SharePointNodeType.SubSites:
        case SharePointNodeType.Content: return searchItemsInSite(session, parent, search, config);
        case SharePointNodeType.List: return searchItemsInList(session, parent, search, config);
        case SharePointNodeType.Library: return searchItemsInLibrary(session, parent, search, config);
        case SharePointNodeType.ListFolder:
        case SharePointNodeType.LibraryFolder: return searchItemsInFolder(session, parent, search, config);
        default: return never(parent);
    }
};

export const searchSharePointItems = (session: VespSession, parent: SharePointNode, search: string, config: LoadPagesConfig | undefined): Observable<Batch<SharePointItem>> =>
    getSearchingFetch(session, parent, search, config).pipe(
        map(batch => batch.mapData(item => convert.composedItem.fromRest(item, parent.siteId, parent.explorePath, false))),
    );
