// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
    RESTM365BackupStoragePolicyBackupType,
    RESTM365BackupStoragePolicyRetentionSetting,
    RESTM365BackupStoragePolicyServiceType,
} from './';

/**
 * @export
 * @interface RESTM365BackupStoragePolicy
 */
export interface RESTM365BackupStoragePolicy {
    /**
     * Description of the Microsoft 365 Backup Storage policy.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicy
     */
    description?: string;
    /**
     * ID of the Microsoft 365 Backup Storage policy assigned by Veeam Backup for Microsoft 365.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicy
     */
    id?: string | null;
    /**
     * ID of the Microsoft 365 Backup Storage policy assigned by Microsoft.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicy
     */
    msid?: string | null;
    /**
     * @type {RESTM365BackupStoragePolicyBackupType}
     * @memberof RESTM365BackupStoragePolicy
     */
    backupType?: RESTM365BackupStoragePolicyBackupType;
    /**
     * @type {RESTM365BackupStoragePolicyServiceType}
     * @memberof RESTM365BackupStoragePolicy
     */
    serviceType?: RESTM365BackupStoragePolicyServiceType;
    /**
     * ID of the Microsoft 365 organization.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicy
     */
    organizationId?: string | null;
    /**
     * Name of the Microsoft 365 Backup Storage policy.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicy
     */
    name?: string;
    /**
     * Date and time of the last successful run of the Microsoft 365 Backup Storage policy.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicy
     */
    lastBackup?: string | null;
    /**
     * Defines whether the Microsoft 365 Backup Storage policy is enabled.
     * @type {boolean}
     * @memberof RESTM365BackupStoragePolicy
     */
    isEnabled?: boolean | null;
    /**
     * Latest status of a backup performed by the Microsoft 365 Backup Storage policy.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicy
     */
    lastBackupStatus?: RESTM365BackupStoragePolicyLastBackupStatusEnum;
    /**
     * Status of the Microsoft 365 Backup Storage policy.
     * @type {string}
     * @memberof RESTM365BackupStoragePolicy
     */
    status?: RESTM365BackupStoragePolicyStatusEnum;
    /**
     * Version number that Veeam Backup for Microsoft 365 assigns if the Microsoft 365 Backup Storage policy was modified.
     * @type {number}
     * @memberof RESTM365BackupStoragePolicy
     */
    eTag?: number;
    /**
     * Retention settings for protected backups.
     * @type {Array<RESTM365BackupStoragePolicyRetentionSetting>}
     * @memberof RESTM365BackupStoragePolicy
     */
    retentionSettings?: Array<RESTM365BackupStoragePolicyRetentionSetting>;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTM365BackupStoragePolicy
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStoragePolicyLastBackupStatusEnum {
    Stopped = 'Stopped',
    Running = 'Running',
    Success = 'Success',
    Failed = 'Failed',
    Warning = 'Warning',
    NotConfigured = 'NotConfigured',
    Disconnected = 'Disconnected',
    Queued = 'Queued',
    Updating = 'Updating'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStoragePolicyStatusEnum {
    None = 'None',
    Inactive = 'Inactive',
    Active = 'Active',
    ActiveWithErrors = 'ActiveWithErrors',
    Updating = 'Updating'
}

