// Copyright © Veeam Software Group GmbH

import { AjaxError } from 'rxjs/ajax';
import { MESSAGE_BOX_ICON_VIEW } from '@veeam/components';

import type { AuthError } from '@azure/msal-common';

import resources from 'infrastructure/resources';

export type UnknownError = string | Error | AjaxError;

export interface ErrorDescription {
    header?: string;
    message: string;
    iconView?: MESSAGE_BOX_ICON_VIEW;
}

const mapErrors = (message: string | undefined): string => {
    const { unknownError, errorsMapping } = resources.current.infrastructure.errorManagement;
    if (message === undefined) return unknownError;
    const mapped = Object.entries(errorsMapping)
        .map(([key, value]) => ({ key, value }))
        .find(({ key }) => message.indexOf(key) >= 0);
    return mapped?.value || message;
};

const create = (messageRaw: string, header?: string): ErrorDescription => {
    const message = mapErrors(messageRaw);
    return {
        header, message,
    };
};

interface ServerError {
    message: string;
    messageDetail?: string;
}

interface ServerError2 {
    error: string;
    error_description: string;
}

interface OAuthError {
    error: string;
}

function isOAuthError(response: any): response is OAuthError {
    if (typeof response !== 'object') return false;
    if (typeof response.error !== 'string') return false;
    return true;
}

const createFromOAuthError = (error: OAuthError): ErrorDescription => create(error.error);

function isServerError(response: any): response is ServerError {
    if (typeof response !== 'object') return false;
    if (typeof response?.message !== 'string') return false;
    return true;
}

const createFromServerError = (error: ServerError): ErrorDescription => create(error.messageDetail ? error.messageDetail : error.message);

function isServerError2(response: any): response is ServerError2 {
    if (typeof response !== 'object') return false;
    if (typeof response.error !== 'string') return false;
    if (typeof response.error_description !== 'string') return false;
    return true;
}

/**
 * AuthError is a general error class thrown by the MSAL.js library.
 */
function isMsalAuthError(error: unknown): error is AuthError {
    return (
        error instanceof Error &&
        error.hasOwnProperty('correlationId') &&
        error.hasOwnProperty('errorCode') &&
        error.hasOwnProperty('errorMessage') &&
        error.hasOwnProperty('subError')
    );
}

const createFromServerError2 = (error: ServerError2): ErrorDescription => create(error.error_description);

export function getErrorMessage(error: unknown): ErrorDescription {
    if (typeof error === 'string') {
        return create(error);
    }

    if (error instanceof AjaxError) {
        if (isServerError(error.response)) return createFromServerError(error.response);
        if (isServerError2(error.response)) return createFromServerError2(error.response);
        if (isOAuthError(error.response)) return createFromOAuthError(error.response);
        return create(error.message);
    }

    if (isMsalAuthError(error)) {
        return create(resources.current.features.Login.loginFailed); // don't show real msal auth errors in UI
    }

    if (error instanceof Error) {
        return create(error.message);
    }

    return create(resources.current.infrastructure.errorManagement.unknownError);
}

export function getInfoMessage(text: string): ErrorDescription {
    return {
        header: resources.current.infrastructure.errorManagement.InfoTitle,
        iconView: MESSAGE_BOX_ICON_VIEW.info,
        message: text,
    };
}
