// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

import { Subject } from "rxjs";
import { RestoreSessionConfig, RestoreSessionLogConfig } from "../models";
import { HubConnection } from "@microsoft/signalr";

export const operatorEventsHub = {
    restoreSessionStartedAsync: new Subject<RestoreSessionConfig>(),
    restoreSessionStoppedAsync: new Subject<RestoreSessionConfig>(),
    historyClearedAsync: new Subject<unknown>(),
    addRestoreSessionLogAsync: new Subject<RestoreSessionLogConfig>(),
    finishRestoreSessionLogAsync: new Subject<RestoreSessionLogConfig>(),
    onUnavailableExchangeDataFoundAsync: new Subject<unknown>(),
    onUnavailableSharePointDataFoundAsync: new Subject<unknown>(),
    onUnavailableOneDriveDataFoundAsync: new Subject<unknown>(),
    };

function onConnect(connection: HubConnection): void {
    connection.on('restoreSessionStartedAsync', (req) => operatorEventsHub.restoreSessionStartedAsync.next(req));
    connection.on('restoreSessionStoppedAsync', (req) => operatorEventsHub.restoreSessionStoppedAsync.next(req));
    connection.on('historyClearedAsync', (req) => operatorEventsHub.historyClearedAsync.next(req));
    connection.on('addRestoreSessionLogAsync', (req) => operatorEventsHub.addRestoreSessionLogAsync.next(req));
    connection.on('finishRestoreSessionLogAsync', (req) => operatorEventsHub.finishRestoreSessionLogAsync.next(req));
    connection.on('onUnavailableExchangeDataFoundAsync', (req) => operatorEventsHub.onUnavailableExchangeDataFoundAsync.next(req));
    connection.on('onUnavailableSharePointDataFoundAsync', (req) => operatorEventsHub.onUnavailableSharePointDataFoundAsync.next(req));
    connection.on('onUnavailableOneDriveDataFoundAsync', (req) => operatorEventsHub.onUnavailableOneDriveDataFoundAsync.next(req));
}

export const operatorEventsHubConnector = { onConnect };
