// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * Specifies a grant type that will be used to authenticate a user.
     * @type {string}
     * @memberof InlineObject
     */
    grant_type: InlineObjectGrantTypeEnum;
    /**
     * \\[Required if the `grant_type` property value is *password*\\] Specifies a user name.
     * @type {string}
     * @memberof InlineObject
     */
    username?: string;
    /**
     * \\[Required if the `grant_type` property value is *password*\\] Specifies a user password.
     * @type {string}
     * @memberof InlineObject
     */
    password?: string;
    /**
     * \\[Required if the `grant_type` property value is *refresh_token*\\] Specifies a refresh token.
     * @type {string}
     * @memberof InlineObject
     */
    refresh_token?: string;
    /**
     * \\[Required if the `grant_type` property value is *urn:ietf:params:oauth:grant-type:jwt-bearer* or *operator*\\] Specifies either an application ID or combination of a user ID and tenant ID in the following format: *userId.tenantId*. 
     * @type {string}
     * @memberof InlineObject
     */
    client_id?: string;
    /**
     * \\[Required if the `grant_type` property value is *urn:ietf:params:oauth:grant-type:jwt-bearer* or *operator*\\] Specifies an assertion.
     * @type {string}
     * @memberof InlineObject
     */
    assertion?: string;
    /**
     * Defines whether an antiforgery token is not required for Veeam Backup for Microsoft 365 REST API authorization process. The antiforgery token is stored in web browser cookies and protects an access and refresh tokens during a web browser REST API session.
     * @type {boolean}
     * @memberof InlineObject
     */
    disable_antiforgery_token?: boolean | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InlineObjectGrantTypeEnum {
    Password = 'password',
    RefreshToken = 'refresh_token',
    UrnietfparamsoauthgrantTypejwtBearer = 'urn:ietf:params:oauth:grant-type:jwt-bearer',
    Operator = 'operator'
}

