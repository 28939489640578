// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum JobResyncTypes {
    None = 0,
    Exchange = 1,
    SharePoint = 2,
    OneDrive = 4,
    Teams = 8,
    All = 15
}
