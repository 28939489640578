// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTRestoreToSiteConfig
 */
export interface RESTRestoreToSiteConfig {
    /**
     * Specifies a user name of the account that will be used as a SharePoint site owner account to restore a SharePoint site.  **Note**: This property is required if you want to use an application certificate for data restore. Use this property only with the `applicationCertificate` property. 
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    impersonationAccountName?: string;
    /**
     * Specifies an alias for the target SharePoint site. If you omit this property, the SharePoint site items will be restored to the original location.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    alias?: string;
    /**
     * Defines whether the SharePoint lists will be restored with all list views.
     * @type {boolean}
     * @memberof RESTRestoreToSiteConfig
     */
    restoreListViews?: boolean | null;
    /**
     * Defines whether the SharePoint site will be restored with all modified items.
     * @type {boolean}
     * @memberof RESTRestoreToSiteConfig
     */
    changedItems?: boolean | null;
    /**
     * Defines whether the SharePoint site will be restored with all deleted items.
     * @type {boolean}
     * @memberof RESTRestoreToSiteConfig
     */
    deletedItems?: boolean | null;
    /**
     * Defines whether the SharePoint site will be restored with all subsites.
     * @type {boolean}
     * @memberof RESTRestoreToSiteConfig
     */
    restoreSubsites?: boolean | null;
    /**
     * Defines whether the SharePoint site will be restored with all master pages.
     * @type {boolean}
     * @memberof RESTRestoreToSiteConfig
     */
    restoreMasterPages?: boolean | null;
    /**
     * Defines whether the shared links notifications will be sent.
     * @type {boolean}
     * @memberof RESTRestoreToSiteConfig
     */
    sendSharedLinksNotification?: boolean | null;
    /**
     * Specifies what version of the SharePoint documents will be restored.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    documentVersion?: RESTRestoreToSiteConfigDocumentVersionEnum;
    /**
     * Specifies the action that will be performed with the last version of the restored SharePoint document on the destination server.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    documentLastVersionAction?: RESTRestoreToSiteConfigDocumentLastVersionActionEnum;
    /**
     * Specifies the URL of the target SharePoint site.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    siteURL?: string;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    userCode?: string;
    /**
     * Specifies the ID of the Micrisoft Entra application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    applicationId?: string | null;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Microsoft Entra application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    applicationCertificate?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    userPassword?: string;
    /**
     * Specifies the region of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    officeRegion?: RESTRestoreToSiteConfigOfficeRegionEnum;
    /**
     * Specifies the name of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToSiteConfig
     */
    organizationName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToSiteConfigDocumentVersionEnum {
    All = 'All',
    Last = 'Last'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToSiteConfigDocumentLastVersionActionEnum {
    Overwrite = 'Overwrite',
    Merge = 'Merge'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToSiteConfigOfficeRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

