// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
    RESTOfficeLicense,
} from './';

/**
 * @export
 * @interface RESTUser
 */
export interface RESTUser {
    /**
     * Organization user ID.
     * @type {string}
     * @memberof RESTUser
     */
    id?: string;
    /**
     * Version number that Veeam Backup for Microsoft 365 assigns if the organization user was modified.
     * @type {number}
     * @memberof RESTUser
     */
    eTag?: number | null;
    /**
     * ID of the organization user in the on-premises organization.
     * @type {string}
     * @memberof RESTUser
     */
    onPremisesSid?: string;
    /**
     * Display name of the organization user.
     * @type {string}
     * @memberof RESTUser
     */
    displayName?: string;
    /**
     * Email address of the organization user.
     * @type {string}
     * @memberof RESTUser
     */
    name?: string;
    /**
     * Type of the organization user.
     * @type {string}
     * @memberof RESTUser
     */
    type?: RESTUserTypeEnum;
    /**
     * Microsoft 365 organization deployment type.
     * @type {string}
     * @memberof RESTUser
     */
    locationType?: RESTUserLocationTypeEnum;
    /**
     * Office location in the place of business of the organization user.
     * @type {string}
     * @memberof RESTUser
     */
    office?: string;
    /**
     * Array of licenses assigned to the organization user.
     * @type {Array<RESTOfficeLicense>}
     * @memberof RESTUser
     */
    assignedLicenses?: Array<RESTOfficeLicense> | null;
    /**
     * ID of the organization user assigned by Microsoft.
     * @type {string}
     * @memberof RESTUser
     */
    msid?: string | null;
    /**
     * Related resources.
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTUser
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTUserTypeEnum {
    User = 'User',
    Shared = 'Shared',
    Public = 'Public'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTUserLocationTypeEnum {
    OnPremises = 'OnPremises',
    Cloud = 'Cloud',
    Hybrid = 'Hybrid'
}

