// Copyright © Veeam Software Group GmbH

import { useEffect, useMemo } from 'react';
import { SORT_DIRECTION } from '@veeam/components';

import type React from 'react';
import type { TeamsPostItem } from 'services/models/Teams/TeamsItem';

import { RepliesGridModel } from './replies-grid-model';
import { EXPLORE_PAGE_LIMIT } from '../ExploreGrid/consts/explore-page-limit';
import { ExploreSortKeys } from '../../../../services/explore/enums/explore-sort-keys';

import type { SubheaderType } from '../ExploreGrid/enums/subheader-type';


export function useRepliesGridModel(
    parent: TeamsPostItem,
    setAsyncLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setSubheaderType: React.Dispatch<React.SetStateAction<SubheaderType>>
): RepliesGridModel {
    const model = useMemo(() => new RepliesGridModel({
        parent: parent,
        teamId: parent.teamId,
        channelId: parent.channelId,
        limit: EXPLORE_PAGE_LIMIT,
    }, setAsyncLoading, setSubheaderType).withSorting({
        key: ExploreSortKeys.CreatedTime,
        direction: SORT_DIRECTION.desc,
    }), []);

    useEffect(() => () => model.destroy(), []);

    return model;
}
