// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum ScheduleType {
    PeriodicallyTime = 0,
    DailyAtTime = 1,
    ManualOnly = 2,
    Immediate = 3
}
