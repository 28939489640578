/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTTeamsPost,
    RESTAsyncRestoreResponse,
    RESTExceptionInfo,
    RESTExportOptions,
    RESTOperatorRestorePostsOptions,
    RESTOperatorStartExportTaskOptions,
    RESTOperatorStartExportTaskResponse,
    RESTRestorePostsOptions,
    RESTSavePostsOptions,
    RESTSearchOptions,
    RESTSendAsMsgOptions,
    RESTSendPostsOptions,
    RESTTeamsOperatorRestoreSessionResponse,
    RESTTeamsPost,
} from '../models';

export interface TeamsPostApiTeamsPostDownloadExportedPostsRequest {
    restoreSessionId: string;
    teamId: string;
    requestExportId: string;
    authCode: string;
}

export interface TeamsPostApiTeamsPostExportRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTExportOptions;
}

export interface TeamsPostApiTeamsPostExportByIdRequest {
    restoreSessionId: string;
    teamId: string;
    postId: number;
}

export interface TeamsPostApiTeamsPostGetRequest {
    restoreSessionId: string;
    teamId: string;
    postId: number;
    channelId?: string;
}

export interface TeamsPostApiTeamsPostGetPageRequest {
    restoreSessionId: string;
    teamId: string;
    offset: number;
    limit: number;
    channelId?: string;
    parentId?: number;
}

export interface TeamsPostApiTeamsPostOperatorRestoreRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTOperatorRestorePostsOptions;
}

export interface TeamsPostApiTeamsPostOperatorStartExportTaskRequest {
    restoreSessionId: string;
    teamId: string;
    exportOptions: RESTOperatorStartExportTaskOptions;
}

export interface TeamsPostApiTeamsPostRestoreRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTRestorePostsOptions;
}

export interface TeamsPostApiTeamsPostSaveRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTSavePostsOptions;
}

export interface TeamsPostApiTeamsPostSaveByIdRequest {
    restoreSessionId: string;
    teamId: string;
    postId: number;
}

export interface TeamsPostApiTeamsPostSearchRequest {
    restoreSessionId: string;
    teamId: string;
    offset: number;
    limit: number;
    action: RESTSearchOptions;
    channelId?: string;
    parentId?: number;
}

export interface TeamsPostApiTeamsPostSendRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTSendPostsOptions;
}

export interface TeamsPostApiTeamsPostSendByIdRequest {
    restoreSessionId: string;
    teamId: string;
    postId: number;
    action: RESTSendAsMsgOptions;
}

/**
 * no description
 */
export const teamsPostApi = ({

    /**
     * Returns a file in the HTML format which contains exported posts.
     * Get Exported Posts by Restore Operator
     */
    teamsPostDownloadExportedPosts: ({ restoreSessionId, teamId, requestExportId, authCode }: TeamsPostApiTeamsPostDownloadExportedPostsRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostDownloadExportedPosts');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostDownloadExportedPosts');
        throwIfNullOrUndefined(requestExportId, 'requestExportId', 'teamsPostDownloadExportedPosts');
        throwIfNullOrUndefined(authCode, 'authCode', 'teamsPostDownloadExportedPosts');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'authCode': authCode,
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/operatorExport/{requestExportId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{requestExportId}', encodeURI(requestExportId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Exports backed-up Microsoft Teams posts.  When you export posts, the request command exports posts to an HTML file and places this file to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the HTML file is transferred as application/octet-stream media to the client. To download, read, convert to HTML or perform other actions with the octet-stream, use features of programming languages. 
     * Export Posts
     */
    teamsPostExport: ({ restoreSessionId, teamId, action }: TeamsPostApiTeamsPostExportRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostExport');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostExport');
        throwIfNullOrUndefined(action, 'action', 'teamsPostExport');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/export'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Exports a backed-up Microsoft Teams post with the specified ID.  When you export a post, the request command exports the post to an HTML file and places this file to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the HTML file is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages. 
     * Export Post
     */
    teamsPostExportById: ({ restoreSessionId, teamId, postId }: TeamsPostApiTeamsPostExportByIdRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostExportById');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostExportById');
        throwIfNullOrUndefined(postId, 'postId', 'teamsPostExportById');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/{postId}/export'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{postId}', encodeURI(postId)),
            method: 'POST',
            headers,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up Microsoft Teams post with the specified ID.
     * Get Post
     */
    teamsPostGet: ({ restoreSessionId, teamId, postId, channelId }: TeamsPostApiTeamsPostGetRequest, option?: RequestOption): Observable<Optional<RESTTeamsPost>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostGet');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostGet');
        throwIfNullOrUndefined(postId, 'postId', 'teamsPostGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = {};

        if (channelId != null) { query['channelId'] = channelId; }

        return requestAuthenticated<RESTTeamsPost>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/{postId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{postId}', encodeURI(postId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a collection of backed-up Microsoft Teams posts to explore and restore.
     * Get Posts
     */
    teamsPostGetPage: ({ restoreSessionId, teamId, offset, limit, channelId, parentId }: TeamsPostApiTeamsPostGetPageRequest, option?: RequestOption): Observable<Optional<PageOfRESTTeamsPost>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostGetPage');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostGetPage');
        throwIfNullOrUndefined(offset, 'offset', 'teamsPostGetPage');
        throwIfNullOrUndefined(limit, 'limit', 'teamsPostGetPage');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (channelId != null) { query['channelId'] = channelId; }
        if (parentId != null) { query['parentId'] = parentId; }

        return requestAuthenticated<PageOfRESTTeamsPost>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Restores backed-up Microsoft Teams posts using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=80) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong> </br> To restore data using Restore Portal, you must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div> 
     * Restore Posts by Restore Operator
     */
    teamsPostOperatorRestore: ({ restoreSessionId, teamId, action }: TeamsPostApiTeamsPostOperatorRestoreRequest, option?: RequestOption): Observable<Optional<RESTTeamsOperatorRestoreSessionResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostOperatorRestore');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostOperatorRestore');
        throwIfNullOrUndefined(action, 'action', 'teamsPostOperatorRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTTeamsOperatorRestoreSessionResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Creates a task to export posts and returns the task ID.
     * Get Export Task ID
     */
    teamsPostOperatorStartExportTask: ({ restoreSessionId, teamId, exportOptions }: TeamsPostApiTeamsPostOperatorStartExportTaskRequest, option?: RequestOption): Observable<Optional<RESTOperatorStartExportTaskResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostOperatorStartExportTask');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostOperatorStartExportTask');
        throwIfNullOrUndefined(exportOptions, 'exportOptions', 'teamsPostOperatorStartExportTask');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTOperatorStartExportTaskResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/operatorStartExportTask'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: exportOptions,
        }, option);
    },

    /**
     * Restores backed-up Microsoft Teams posts.
     * Restore Posts
     */
    teamsPostRestore: ({ restoreSessionId, teamId, action }: TeamsPostApiTeamsPostRestoreRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostRestore');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostRestore');
        throwIfNullOrUndefined(action, 'action', 'teamsPostRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Save backed-up Microsoft Teams posts.  When you save posts, the request command saves posts to MSG files and places the files to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the files are transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download files from the temporary folder. 
     * Save Posts
     */
    teamsPostSave: ({ restoreSessionId, teamId, action }: TeamsPostApiTeamsPostSaveRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostSave');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostSave');
        throwIfNullOrUndefined(action, 'action', 'teamsPostSave');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Saves a backed-up Microsoft Teams post with the specified ID.  When you save a post, the request command saves the post to a file in the MSG format and places the file to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the file is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save Post
     */
    teamsPostSaveById: ({ restoreSessionId, teamId, postId }: TeamsPostApiTeamsPostSaveByIdRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostSaveById');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostSaveById');
        throwIfNullOrUndefined(postId, 'postId', 'teamsPostSaveById');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/{postId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{postId}', encodeURI(postId)),
            method: 'POST',
            headers,
        }, option);
    },

    /**
     * Searches for backed-up Microsoft Teams posts.
     * Search for Posts
     */
    teamsPostSearch: ({ restoreSessionId, teamId, offset, limit, action, channelId, parentId }: TeamsPostApiTeamsPostSearchRequest, option?: RequestOption): Observable<Optional<PageOfRESTTeamsPost>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostSearch');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostSearch');
        throwIfNullOrUndefined(offset, 'offset', 'teamsPostSearch');
        throwIfNullOrUndefined(limit, 'limit', 'teamsPostSearch');
        throwIfNullOrUndefined(action, 'action', 'teamsPostSearch');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (channelId != null) { query['channelId'] = channelId; }
        if (parentId != null) { query['parentId'] = parentId; }

        return requestAuthenticated<PageOfRESTTeamsPost>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            query,
            body: action,
        }, option);
    },

    /**
     * Sends backed-up Microsoft Teams posts as attachments in an email message. <div class=\"note\"><strong>NOTE</strong> </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft Teams email settings. For more information, see [Edit Email Settings](#tag/VetEmailSettings/operation/VetEmailSettings_Update). </div> 
     * Send Posts
     */
    teamsPostSend: ({ restoreSessionId, teamId, action }: TeamsPostApiTeamsPostSendRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostSend');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostSend');
        throwIfNullOrUndefined(action, 'action', 'teamsPostSend');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Sends a backed-up Microsoft Teams post with the specified ID as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong> </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft Teams email settings. For more information, see [Edit Email Settings](#tag/VetEmailSettings/operation/VetEmailSettings_Update). </div> 
     * Send Post
     */
    teamsPostSendById: ({ restoreSessionId, teamId, postId, action }: TeamsPostApiTeamsPostSendByIdRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsPostSendById');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsPostSendById');
        throwIfNullOrUndefined(postId, 'postId', 'teamsPostSendById');
        throwIfNullOrUndefined(action, 'action', 'teamsPostSendById');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/posts/{postId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{postId}', encodeURI(postId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

})
