// Copyright © Veeam Software Group GmbH

import { merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { operatorEventsHub as hub, SessionActionType } from 'api/signalr';
import { convertSessionFromSignalR } from './converters';

export const onSessionChanged = merge(
    hub.restoreSessionStartedAsync,
    hub.restoreSessionStoppedAsync,
).pipe(
    filter(session => session.SessionActionType === SessionActionType.Restore),
    map(convertSessionFromSignalR),
);
