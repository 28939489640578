// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTLicensedUser
 */
export interface RESTLicensedUser {
    /**
     * ID of the licensed user.
     * @type {string}
     * @memberof RESTLicensedUser
     */
    id?: string;
    /**
     * Name of the licensed user.
     * @type {string}
     * @memberof RESTLicensedUser
     */
    name?: string;
    /**
     * Defines whether the user was processed by a backup job.
     * @type {boolean}
     * @memberof RESTLicensedUser
     */
    isBackedUp?: boolean;
    /**
     * Date and time when the user was processed by a backup job for the last time.
     * @type {string}
     * @memberof RESTLicensedUser
     */
    lastBackupDate?: string;
    /**
     * Current status of the licensed user.
     * @type {string}
     * @memberof RESTLicensedUser
     */
    licenseState?: RESTLicensedUserLicenseStateEnum;
    /**
     * Backed-up organization ID.
     * @type {string}
     * @memberof RESTLicensedUser
     */
    organizationId?: string | null;
    /**
     * ID of the backed-up organization in the backup.
     * @type {string}
     * @memberof RESTLicensedUser
     */
    backedUpOrganizationId?: string;
    /**
     * Name of the backed-up organization.
     * @type {string}
     * @memberof RESTLicensedUser
     */
    organizationName?: string;
    /**
     * Related resources.
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTLicensedUser
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTLicensedUserLicenseStateEnum {
    Licensed = 'Licensed',
    New = 'New',
    TemporarilyAssigned = 'TemporarilyAssigned',
    Exceeded = 'Exceeded'
}

