/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    RESTAccount,
    RESTAccountToSend,
    RESTExceptionInfo,
} from '../models';

export interface AccountApiAccountAddAccountRequest {
    account: RESTAccountToSend;
}

export interface AccountApiAccountGetAccountRequest {
    accountId: string;
}

export interface AccountApiAccountGetAccountsRequest {
    name?: string;
    accountType?: AccountGetAccountsAccountTypeEnum;
    organizationId?: string;
}

export interface AccountApiAccountRemoveAccountRequest {
    accountId: string;
}

export interface AccountApiAccountUpdateAccountRequest {
    accountId: string;
    account: RESTAccountToSend;
}

/**
 * no description
 */
export const accountApi = ({

    /**
     * Adds a new cloud credentials.
     * Add Account
     */
    accountAddAccount: ({ account }: AccountApiAccountAddAccountRequest, option?: RequestOption): Observable<Optional<RESTAccount>> => {
        throwIfNullOrUndefined(account, 'account', 'accountAddAccount');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAccount>({
            url: '/v8/Accounts',
            method: 'POST',
            headers,
            body: account,
        }, option);
    },

    /**
     * Returns a list of properties for the specified account.
     * Get Account Properties by Account ID
     */
    accountGetAccount: ({ accountId }: AccountApiAccountGetAccountRequest, option?: RequestOption): Observable<Optional<RESTAccount>> => {
        throwIfNullOrUndefined(accountId, 'accountId', 'accountGetAccount');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTAccount>({
            url: '/v8/Accounts/{accountId}'.replace('{accountId}', encodeURI(accountId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a list of existing cloud credentials.
     * Get Accounts
     */
    accountGetAccounts: ({ name, accountType, organizationId }: AccountApiAccountGetAccountsRequest, option?: RequestOption): Observable<Optional<Array<RESTAccount>>> => {

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = {};

        if (name != null) { query['name'] = name; }
        if (accountType != null) { query['accountType'] = accountType; }
        if (organizationId != null) { query['organizationId'] = organizationId; }

        return requestAuthenticated<Array<RESTAccount>>({
            url: '/v8/Accounts',
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Removes cloud credentials with the specified ID from the Veeam Backup for Microsoft 365 database. Consider that you cannot remove an account that is in use.
     * Remove Account
     */
    accountRemoveAccount: ({ accountId }: AccountApiAccountRemoveAccountRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(accountId, 'accountId', 'accountRemoveAccount');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<void>({
            url: '/v8/Accounts/{accountId}'.replace('{accountId}', encodeURI(accountId)),
            method: 'DELETE',
            headers,
        }, option);
    },

    /**
     * Modifies cloud credentials with the specified ID.
     * Edit Account
     */
    accountUpdateAccount: ({ accountId, account }: AccountApiAccountUpdateAccountRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(accountId, 'accountId', 'accountUpdateAccount');
        throwIfNullOrUndefined(account, 'account', 'accountUpdateAccount');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/Accounts/{accountId}'.replace('{accountId}', encodeURI(accountId)),
            method: 'PUT',
            headers,
            body: account,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum AccountGetAccountsAccountTypeEnum {
    AzureBlobAccount = 'azureBlobAccount',
    AmazonS3Account = 'amazonS3Account',
    AmazonS3CompatibleAccount = 'amazonS3CompatibleAccount'
}
