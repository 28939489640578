// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTBackupWindowSettings,
} from './';

/**
 * @export
 * @interface RESTJobSchedulePolicy
 */
export interface RESTJobSchedulePolicy {
    /**
     * Defines whether the schedule feature is enabled for this job. Use this property only with the `periodicallyEvery` property and the time interval specified in hours.
     * @type {boolean}
     * @memberof RESTJobSchedulePolicy
     */
    scheduleEnabled?: boolean | null;
    /**
     * Defines whether the backup window feature is enabled for this job.
     * @type {boolean}
     * @memberof RESTJobSchedulePolicy
     */
    backupWindowEnabled?: boolean | null;
    /**
     * @type {RESTBackupWindowSettings}
     * @memberof RESTJobSchedulePolicy
     */
    backupWindowSettings?: RESTBackupWindowSettings;
    /**
     * Defines whether this job is configured to run periodically every N hours.
     * @type {boolean}
     * @memberof RESTJobSchedulePolicy
     */
    periodicallyWindowEnabled?: boolean | null;
    /**
     * @type {RESTBackupWindowSettings}
     * @memberof RESTJobSchedulePolicy
     */
    periodicallyWindowSettings?: RESTBackupWindowSettings;
    /**
     * Number of minutes for which you want to shift starting of the backup job within an hour if several backup jobs are scheduled to be started simultaneously. Use this property only with the `periodicallyEvery` property and the time interval specified in hours.
     * @type {number}
     * @memberof RESTJobSchedulePolicy
     */
    periodicallyOffsetMinutes?: number | null;
    /**
     * Backup job schedule type. The following types are available: <ul> <li>*Daily*. The backup job runs on specific days.</li> <li>*Periodically*. The backup job runs repeatedly throughout a day with a specific time interval.</li> <li>*ManualOnly*. The backup job runs manually.</li> </ul> 
     * @type {string}
     * @memberof RESTJobSchedulePolicy
     */
    type?: RESTJobSchedulePolicyTypeEnum;
    /**
     * Time interval between the job runs.
     * @type {string}
     * @memberof RESTJobSchedulePolicy
     */
    periodicallyEvery?: RESTJobSchedulePolicyPeriodicallyEveryEnum;
    /**
     * Days when the backup job will run.
     * @type {string}
     * @memberof RESTJobSchedulePolicy
     */
    dailyType?: RESTJobSchedulePolicyDailyTypeEnum;
    /**
     * Time to start the backup job.
     * @type {string}
     * @memberof RESTJobSchedulePolicy
     */
    dailyTime?: string;
    /**
     * Defines whether Veeam Backup for Microsoft 365 will attempt to restart the backup job if it fails for some reason.
     * @type {boolean}
     * @memberof RESTJobSchedulePolicy
     */
    retryEnabled?: boolean | null;
    /**
     * Number of attempts to restart the backup job.
     * @type {number}
     * @memberof RESTJobSchedulePolicy
     */
    retryNumber?: number | null;
    /**
     * Time intervals between the backup job retry attempts in *Minutes*.
     * @type {number}
     * @memberof RESTJobSchedulePolicy
     */
    retryWaitInterval?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTJobSchedulePolicyTypeEnum {
    Periodically = 'Periodically',
    Daily = 'Daily',
    ManualOnly = 'ManualOnly'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTJobSchedulePolicyPeriodicallyEveryEnum {
    Minutes5 = 'Minutes5',
    Minutes10 = 'Minutes10',
    Minutes15 = 'Minutes15',
    Minutes30 = 'Minutes30',
    Hours1 = 'Hours1',
    Hours2 = 'Hours2',
    Hours4 = 'Hours4',
    Hours8 = 'Hours8',
    Hours12 = 'Hours12'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTJobSchedulePolicyDailyTypeEnum {
    Everyday = 'Everyday',
    Workdays = 'Workdays',
    Weekends = 'Weekends',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

