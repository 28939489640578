// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTSharePointItem,
} from './';

/**
 * @export
 * @interface RESTRestoreToItemsConfig
 */
export interface RESTRestoreToItemsConfig {
    /**
     * Specifies IDs of the SharePoint items that you want to restore. For more information on how to get such IDs, see [Get SharePoint Items](#tag/SharePointItem/operation/SharePointItem_Get).
     * @type {Array<RESTSharePointItem>}
     * @memberof RESTRestoreToItemsConfig
     */
    items?: Array<RESTSharePointItem>;
    /**
     * Specifies the target SharePoint list.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    list?: string;
    /**
     * Defines whether the shared links notifications will be sent.
     * @type {boolean}
     * @memberof RESTRestoreToItemsConfig
     */
    sendSharedLinksNotification?: boolean | null;
    /**
     * Specifies what version of the SharePoint documents will be restored.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    documentVersion?: RESTRestoreToItemsConfigDocumentVersionEnum;
    /**
     * Specifies the action that will be performed with the last version of the restored SharePoint document on the destination server.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    documentLastVersionAction?: RESTRestoreToItemsConfigDocumentLastVersionActionEnum;
    /**
     * Specifies the URL of the target SharePoint site.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    siteURL?: string;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    userCode?: string;
    /**
     * Specifies the ID of the Micrisoft Entra application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    applicationId?: string | null;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Microsoft Entra application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    applicationCertificate?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    userPassword?: string;
    /**
     * Specifies the region of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    officeRegion?: RESTRestoreToItemsConfigOfficeRegionEnum;
    /**
     * Specifies the name of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToItemsConfig
     */
    organizationName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToItemsConfigDocumentVersionEnum {
    All = 'All',
    Last = 'Last'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToItemsConfigDocumentLastVersionActionEnum {
    Overwrite = 'Overwrite',
    Merge = 'Merge'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToItemsConfigOfficeRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

