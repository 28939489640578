// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

import { authController, isAuthenticated } from "infrastructure/auth";
import { operatorEventsHub, operatorEventsHubConnector } from "./subjects";
import { HubConnectionBuilder } from "@microsoft/signalr";

let server = window.origin + '/v8/signalr/operatorEventsHub';
export let subjects = { operatorEventsHub };

async function connectToHub() {
    const connection = new HubConnectionBuilder()
        .withUrl(server)
        .withAutomaticReconnect()
        .build();

    operatorEventsHubConnector.onConnect(connection);

    await connection.start();
}
authController.events.renewToken.subscribe(connectToHub);
if(isAuthenticated(authController)) connectToHub();
