// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum RestoreItemStatus {
    Running = 0,
    Success = 1,
    Warning = 2,
    Failed = 3,
    Cancelled = 4,
    Skipped = 5
}
