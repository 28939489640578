// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTJobStatistics
 */
export interface RESTJobStatistics {
    /**
     * Average speed of data processing. This counter is a ratio between the amount of data that has actually been read and job duration. The value is given in bytes per second.
     * @type {number}
     * @memberof RESTJobStatistics
     */
    processingRateBytesPS?: number;
    /**
     * Average speed of data processing. This counter is a ratio between the amount of data that has actually been read and job duration. The value is given in items per second.
     * @type {number}
     * @memberof RESTJobStatistics
     */
    processingRateItemsPS?: number;
    /**
     * Average speed of reading data from the backup repository. The value is given in bytes per second.
     * @type {number}
     * @memberof RESTJobStatistics
     */
    readRateBytesPS?: number;
    /**
     * Average speed of writing data to the backup repository. The value is given in bytes per second.
     * @type {number}
     * @memberof RESTJobStatistics
     */
    writeRateBytesPS?: number;
    /**
     * Amount of data transferred from the source-side to the target-side after applying compression and deduplication. The value is given in bytes per second.
     * @type {number}
     * @memberof RESTJobStatistics
     */
    transferredDataBytes?: number;
    /**
     * Number of items processed by the backup or backup copy job.
     * @type {number}
     * @memberof RESTJobStatistics
     */
    processedObjects?: number;
    /**
     * Bottleneck in the data transmission process.
     * @type {string}
     * @memberof RESTJobStatistics
     */
    bottleneck?: RESTJobStatisticsBottleneckEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTJobStatisticsBottleneckEnum {
    Na = 'NA',
    Detecting = 'Detecting',
    Source = 'Source',
    Target = 'Target'
}

