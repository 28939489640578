/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    RESTAmazonBucketS3Compatible,
    RESTAmazonFolderToReceiveS3Compatible,
    RESTAmazonFolderToSend,
    RESTExceptionInfo,
} from '../models';

export interface AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageAddFolderRequest {
    bucketName: string;
    accountId: string;
    servicePoint: string;
    customRegionId: string;
    folderJson: RESTAmazonFolderToSend;
    trustedServerCertificateThumbprint?: string;
    trustServerCertificate?: boolean;
}

export interface AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageGetBucketRequest {
    name: string;
    accountId: string;
    servicePoint: string;
    customRegionId: string;
    trustedServerCertificateThumbprint?: string;
    trustServerCertificate?: boolean;
}

export interface AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageGetBucketsRequest {
    accountId: string;
    servicePoint: string;
    customRegionId: string;
    name?: string;
    trustedServerCertificateThumbprint?: string;
    trustServerCertificate?: boolean;
}

export interface AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageGetFolderRequest {
    name: string;
    bucketName: string;
    accountId: string;
    servicePoint: string;
    customRegionId: string;
    trustedServerCertificateThumbprint?: string;
    trustServerCertificate?: boolean;
}

export interface AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageGetFoldersRequest {
    bucketName: string;
    accountId: string;
    servicePoint: string;
    customRegionId: string;
    name?: string;
    trustedServerCertificateThumbprint?: string;
    trustServerCertificate?: boolean;
}

/**
 * no description
 */
export const amazonS3CompatibleObjectStorageApi = ({

    /**
     * Creates a new folder in the specified S3 Compatible, IBM Cloud or Wasabi Cloud object storage bucket.
     * Create Folders
     */
    amazonS3CompatibleObjectStorageAddFolder: ({ bucketName, accountId, servicePoint, customRegionId, folderJson, trustedServerCertificateThumbprint, trustServerCertificate }: AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageAddFolderRequest, option?: RequestOption): Observable<Optional<RESTAmazonFolderToReceiveS3Compatible>> => {
        throwIfNullOrUndefined(bucketName, 'bucketName', 'amazonS3CompatibleObjectStorageAddFolder');
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3CompatibleObjectStorageAddFolder');
        throwIfNullOrUndefined(servicePoint, 'servicePoint', 'amazonS3CompatibleObjectStorageAddFolder');
        throwIfNullOrUndefined(customRegionId, 'customRegionId', 'amazonS3CompatibleObjectStorageAddFolder');
        throwIfNullOrUndefined(folderJson, 'folderJson', 'amazonS3CompatibleObjectStorageAddFolder');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'ServicePoint': servicePoint,
            'CustomRegionId': customRegionId,
        };

        if (trustedServerCertificateThumbprint != null) { query['trustedServerCertificateThumbprint'] = trustedServerCertificateThumbprint; }
        if (trustServerCertificate != null) { query['trustServerCertificate'] = trustServerCertificate; }

        return requestAuthenticated<RESTAmazonFolderToReceiveS3Compatible>({
            url: '/v8/S3CompatibleResources/buckets/{bucketName}/folders'.replace('{bucketName}', encodeURI(bucketName)),
            method: 'POST',
            headers,
            query,
            body: folderJson,
        }, option);
    },

    /**
     * Returns information about S3 Compatible, IBM Cloud or Wasabi Cloud object storage bucket with the specified name.
     * Get Bucket by Name
     */
    amazonS3CompatibleObjectStorageGetBucket: ({ name, accountId, servicePoint, customRegionId, trustedServerCertificateThumbprint, trustServerCertificate }: AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageGetBucketRequest, option?: RequestOption): Observable<Optional<RESTAmazonBucketS3Compatible>> => {
        throwIfNullOrUndefined(name, 'name', 'amazonS3CompatibleObjectStorageGetBucket');
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3CompatibleObjectStorageGetBucket');
        throwIfNullOrUndefined(servicePoint, 'servicePoint', 'amazonS3CompatibleObjectStorageGetBucket');
        throwIfNullOrUndefined(customRegionId, 'customRegionId', 'amazonS3CompatibleObjectStorageGetBucket');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'ServicePoint': servicePoint,
            'CustomRegionId': customRegionId,
        };

        if (trustedServerCertificateThumbprint != null) { query['trustedServerCertificateThumbprint'] = trustedServerCertificateThumbprint; }
        if (trustServerCertificate != null) { query['trustServerCertificate'] = trustServerCertificate; }

        return requestAuthenticated<RESTAmazonBucketS3Compatible>({
            url: '/v8/S3CompatibleResources/buckets/{Name}'.replace('{Name}', encodeURI(name)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a list of S3 Compatible, IBM Cloud or Wasabi Cloud object storage buckets.
     * Get Buckets
     */
    amazonS3CompatibleObjectStorageGetBuckets: ({ accountId, servicePoint, customRegionId, name, trustedServerCertificateThumbprint, trustServerCertificate }: AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageGetBucketsRequest, option?: RequestOption): Observable<Optional<Array<RESTAmazonBucketS3Compatible>>> => {
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3CompatibleObjectStorageGetBuckets');
        throwIfNullOrUndefined(servicePoint, 'servicePoint', 'amazonS3CompatibleObjectStorageGetBuckets');
        throwIfNullOrUndefined(customRegionId, 'customRegionId', 'amazonS3CompatibleObjectStorageGetBuckets');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'ServicePoint': servicePoint,
            'CustomRegionId': customRegionId,
        };

        if (name != null) { query['Name'] = name; }
        if (trustedServerCertificateThumbprint != null) { query['trustedServerCertificateThumbprint'] = trustedServerCertificateThumbprint; }
        if (trustServerCertificate != null) { query['trustServerCertificate'] = trustServerCertificate; }

        return requestAuthenticated<Array<RESTAmazonBucketS3Compatible>>({
            url: '/v8/S3CompatibleResources/buckets',
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns information about S3 Compatible, IBM Cloud or Wasabi Cloud object storage folder with the specified name.
     * Get Folder by Name
     */
    amazonS3CompatibleObjectStorageGetFolder: ({ name, bucketName, accountId, servicePoint, customRegionId, trustedServerCertificateThumbprint, trustServerCertificate }: AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageGetFolderRequest, option?: RequestOption): Observable<Optional<RESTAmazonFolderToReceiveS3Compatible>> => {
        throwIfNullOrUndefined(name, 'name', 'amazonS3CompatibleObjectStorageGetFolder');
        throwIfNullOrUndefined(bucketName, 'bucketName', 'amazonS3CompatibleObjectStorageGetFolder');
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3CompatibleObjectStorageGetFolder');
        throwIfNullOrUndefined(servicePoint, 'servicePoint', 'amazonS3CompatibleObjectStorageGetFolder');
        throwIfNullOrUndefined(customRegionId, 'customRegionId', 'amazonS3CompatibleObjectStorageGetFolder');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'ServicePoint': servicePoint,
            'CustomRegionId': customRegionId,
        };

        if (trustedServerCertificateThumbprint != null) { query['trustedServerCertificateThumbprint'] = trustedServerCertificateThumbprint; }
        if (trustServerCertificate != null) { query['trustServerCertificate'] = trustServerCertificate; }

        return requestAuthenticated<RESTAmazonFolderToReceiveS3Compatible>({
            url: '/v8/S3CompatibleResources/buckets/{bucketName}/folders/{Name}'.replace('{Name}', encodeURI(name)).replace('{bucketName}', encodeURI(bucketName)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a list of S3 Compatible, IBM Cloud or Wasabi Cloud object storage folders created in the specified bucket.
     * Get Folders
     */
    amazonS3CompatibleObjectStorageGetFolders: ({ bucketName, accountId, servicePoint, customRegionId, name, trustedServerCertificateThumbprint, trustServerCertificate }: AmazonS3CompatibleObjectStorageApiAmazonS3CompatibleObjectStorageGetFoldersRequest, option?: RequestOption): Observable<Optional<Array<RESTAmazonFolderToReceiveS3Compatible>>> => {
        throwIfNullOrUndefined(bucketName, 'bucketName', 'amazonS3CompatibleObjectStorageGetFolders');
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3CompatibleObjectStorageGetFolders');
        throwIfNullOrUndefined(servicePoint, 'servicePoint', 'amazonS3CompatibleObjectStorageGetFolders');
        throwIfNullOrUndefined(customRegionId, 'customRegionId', 'amazonS3CompatibleObjectStorageGetFolders');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'ServicePoint': servicePoint,
            'CustomRegionId': customRegionId,
        };

        if (name != null) { query['Name'] = name; }
        if (trustedServerCertificateThumbprint != null) { query['trustedServerCertificateThumbprint'] = trustedServerCertificateThumbprint; }
        if (trustServerCertificate != null) { query['trustServerCertificate'] = trustServerCertificate; }

        return requestAuthenticated<Array<RESTAmazonFolderToReceiveS3Compatible>>({
            url: '/v8/S3CompatibleResources/buckets/{bucketName}/folders'.replace('{bucketName}', encodeURI(bucketName)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

})
