// Copyright © Veeam Software Group GmbH

import { useEffect, useState } from 'react';
import first from 'lodash/first';
import { DataGridModel, SORT_DIRECTION } from '@veeam/components';

import type { RbacScope, RbacScopeId } from 'services/rbac';

import { RbacScopeSortKeys, RbacScopeFilterSchema } from 'services/rbac/getRbacScopes';
import { rbacService } from 'services/rbac';
import { commonLoader, createConverter } from 'infrastructure/grid';

interface RbacScopeExtraData {
    nextReloadForce: boolean;
}

export class ScopesModel extends DataGridModel<RbacScope, RbacScopeId, RbacScopeSortKeys, RbacScopeExtraData> {
    private static idGetter(data: RbacScope): RbacScopeId {
        return data.id;
    }
    public constructor(loader: ScopesModel['loaderType']) {
        super(ScopesModel.idGetter, loader, { nextReloadForce: false });
    }
}

export type Value = ScopesModel['valueType'];

export const convertFilter = createConverter(RbacScopeFilterSchema);

const scopesLoader: ScopesModel['loaderType'] = commonLoader(
    value => ({
        sorting: first(value.sorting),
        filter: convertFilter(value.filters),
        forceReload: value.nextReloadForce,
    }),
    rbacService.getRbacScopes,
    (): RbacScopeExtraData => ({
        nextReloadForce: false,
    })
);

export function useRbacScopesModel(): ScopesModel {
    const [model] = useState(new ScopesModel(scopesLoader).withSorting({
        key: RbacScopeSortKeys.Title,
        direction: SORT_DIRECTION.asc,
    }));

    useEffect(() => () => model.destroy(), []);

    return model;
}
