// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTRestoreObject
 */
export interface RESTRestoreObject {
    /**
     * ID of the object.
     * @type {string}
     * @memberof RESTRestoreObject
     */
    id?: string;
    /**
     * Path to the object.
     * @type {string}
     * @memberof RESTRestoreObject
     */
    path?: string;
    /**
     * Object title.
     * @type {string}
     * @memberof RESTRestoreObject
     */
    title?: string;
    /**
     * Object type.
     * @type {string}
     * @memberof RESTRestoreObject
     */
    type?: RESTRestoreObjectTypeEnum;
    /**
     * Array of warnings appeared during the restore operation.
     * @type {Array<string>}
     * @memberof RESTRestoreObject
     */
    warnings?: Array<string>;
    /**
     * Error occurred during the restore operation.
     * @type {string}
     * @memberof RESTRestoreObject
     */
    error?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreObjectTypeEnum {
    Item = 'Item',
    List = 'List',
    Site = 'Site'
}

