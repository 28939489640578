// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTBackupRepositoryOwnerChangeSession
 */
export interface RESTBackupRepositoryOwnerChangeSession {
    /**
     * Change owner session ID.
     * @type {string}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    id: string;
    /**
     * Status of the change owner session.
     * @type {string}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    status: RESTBackupRepositoryOwnerChangeSessionStatusEnum;
    /**
     * Date and time when the change owner session was started.
     * @type {string}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    startTime?: string;
    /**
     * Date and time when the change owner session ended.
     * @type {string}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    endTime?: string | null;
    /**
     * Change owner session details.
     * @type {string}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    details?: string | null;
    /**
     * Timeout in *minutes*. This timeout is used to wait for the related sessions to finish before starting the current session. *Related sessions* are the sessions that Veeam Backup for Microsoft 365 creates to perform different activities: data backup and backup copy, data management, data restore, data retrieval, and data migration. 
     * @type {string}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    waitForSessionsTimeout?: string;
    /**
     * Defines action that Veeam Backup for Microsoft 365 performs if the related sessions exceed the `waitForSessionsTimeout` value to finish. The following values are available: <ul> <li>*true* - the related sessions are stopped, the change owner session is created and started.</li> <li>*false* - the change owner session is canceled.</li> </ul> 
     * @type {boolean}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    forceStopSessions?: boolean;
    /**
     * Timeout in *minutes*. This timeout is used to wait for the related sessions to stop after Veeam Backup for Microsoft 365 forced them to stop. 
     * @type {string}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    forceStopSessionsTimeout?: string;
    /**
     * ID of the backup proxy server or backup proxy poool from which backup repositories are moved.
     * @type {string}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    fromOwnerId?: string;
    /**
     * ID of the backup proxy server or backup proxy poool to which backup repositories are moved.
     * @type {string}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    toOwnerId?: string;
    /**
     * Array of backup repositories involved in the change owner session. The server returns backup repository IDs.
     * @type {Array<string>}
     * @memberof RESTBackupRepositoryOwnerChangeSession
     */
    repositoryIds: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryOwnerChangeSessionStatusEnum {
    Waiting = 'Waiting',
    Running = 'Running',
    Succeeded = 'Succeeded',
    Failed = 'Failed'
}

