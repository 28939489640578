/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTOrganizationStorageSpaceUsage,
    PageOfRestOrganizationComposed,
    RESTCheckDeviceCode,
    RESTCommonDeviceCodeRequestOptions,
    RESTDeviceCodeCheckResponse,
    RESTDeviceCodeResponse,
    RESTExceptionInfo,
    RESTExploreOptions,
    RESTLoggedInOrganization,
    RESTRestoreSession,
    RestOrganizationComposed,
} from '../models';

export interface OrganizationApiOrganizationAddRequest {
    organization: RestOrganizationComposed;
}

export interface OrganizationApiOrganizationCheckDeviceCodeRequest {
    deviceCode: RESTCheckDeviceCode;
}

export interface OrganizationApiOrganizationExploreRequest {
    organizationId: string;
    exploreOptions: RESTExploreOptions;
}

export interface OrganizationApiOrganizationGetRequest {
    limit: number;
    offset: number;
    extendedView?: boolean;
    msid?: string;
    backedUpOrganizationId?: string;
}

export interface OrganizationApiOrganizationGetByIdRequest {
    organizationId: string;
    extendedView?: boolean;
}

export interface OrganizationApiOrganizationGetStorageSpaceUsageRequest {
    organizationId: string;
    limit: number;
    offset: number;
}

export interface OrganizationApiOrganizationOrganizationDeviceCodeRequest {
    representation: RESTCommonDeviceCodeRequestOptions;
}

export interface OrganizationApiOrganizationRemoveRequest {
    organizationId: string;
}

export interface OrganizationApiOrganizationUpdateRequest {
    organizationId: string;
    organization: RestOrganizationComposed;
}

/**
 * no description
 */
export const organizationApi = ({

    /**
     * Adds a Microsoft organization to the Veeam Backup for Microsoft 365 infrastructure.  <div class=\"important\"><strong>IMPORTANT</strong> </br> Since [Microsoft deprecated basic authentication and legacy authentication protocols](https://techcommunity.microsoft.com/t5/exchange-team-blog/basic-authentication-deprecation-in-exchange-online-september/ba-p/3609437), adding Microsoft organizations using these authentication methods will be deprecated in future versions of Veeam Backup for Microsoft 365. Use the *modern app-only* authentication method to add new Microsoft organizations to Veeam Backup for Microsoft 365. </div> 
     * Add Organization
     */
    organizationAdd: ({ organization }: OrganizationApiOrganizationAddRequest, option?: RequestOption): Observable<Optional<RestOrganizationComposed>> => {
        throwIfNullOrUndefined(organization, 'organization', 'organizationAdd');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RestOrganizationComposed>({
            url: '/v8/Organizations',
            method: 'POST',
            headers,
            body: organization,
        }, option);
    },

    /**
     * Allows you to check whether a device code was used to sign in to Microsoft Identity platform.
     * Check Device Code
     */
    organizationCheckDeviceCode: ({ deviceCode }: OrganizationApiOrganizationCheckDeviceCodeRequest, option?: RequestOption): Observable<Optional<RESTDeviceCodeCheckResponse>> => {
        throwIfNullOrUndefined(deviceCode, 'deviceCode', 'organizationCheckDeviceCode');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTDeviceCodeCheckResponse>({
            url: '/v8/DeviceCode/check',
            method: 'POST',
            headers,
            body: deviceCode,
        }, option);
    },

    /**
     * Creates and starts a restore session for an organization with the specified ID.
     * Create Restore Session for Organization by Organization ID
     */
    organizationExplore: ({ organizationId, exploreOptions }: OrganizationApiOrganizationExploreRequest, option?: RequestOption): Observable<Optional<RESTRestoreSession>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationExplore');
        throwIfNullOrUndefined(exploreOptions, 'exploreOptions', 'organizationExplore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTRestoreSession>({
            url: '/v8/Organizations/{organizationId}/explore'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'POST',
            headers,
            body: exploreOptions,
        }, option);
    },

    /**
     * Returns a collection of Microsoft organizations added to the Veeam Backup for Microsoft 365 infrastructure.
     * Get Organizations
     */
    organizationGet: ({ limit, offset, extendedView, msid, backedUpOrganizationId }: OrganizationApiOrganizationGetRequest, option?: RequestOption): Observable<Optional<PageOfRestOrganizationComposed>> => {
        throwIfNullOrUndefined(limit, 'limit', 'organizationGet');
        throwIfNullOrUndefined(offset, 'offset', 'organizationGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (extendedView != null) { query['extendedView'] = extendedView; }
        if (msid != null) { query['msid'] = msid; }
        if (backedUpOrganizationId != null) { query['backedUpOrganizationId'] = backedUpOrganizationId; }

        return requestAuthenticated<PageOfRestOrganizationComposed>({
            url: '/v8/Organizations',
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a resource representation of an organization with the specified ID.
     * Get Organization by Organization ID
     */
    organizationGetById: ({ organizationId, extendedView }: OrganizationApiOrganizationGetByIdRequest, option?: RequestOption): Observable<Optional<RestOrganizationComposed>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationGetById');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = {};

        if (extendedView != null) { query['extendedView'] = extendedView; }

        return requestAuthenticated<RestOrganizationComposed>({
            url: '/v8/Organizations/{organizationId}'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns information about Microsoft 365 and on-premises Microsoft organizations that were added as tenants by service providers.  To use this resource, make sure to obtain a token using the same credentials that were used to add the organization instead of credentials of an administrative account. For more information on how to obtain a token, see the Veeam Backup for Microsoft 365 REST API Reference, section [Authorization for Organizations with Modern Authentication and Legacy Protocols or Basic Authentication](https://helpcenter.veeam.com/docs/vbo365/rest/authorization_basic_legacy.html?ver=80). 
     * Get Tenant Organization
     */
    organizationGetCurrentOrganization: (option?: RequestOption): Observable<Optional<RESTLoggedInOrganization>> => {
        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTLoggedInOrganization>({
            url: '/v8/Organization',
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a collection of used backup repositories for an organization with the specified ID.
     * Get Organization Repositories
     */
    organizationGetStorageSpaceUsage: ({ organizationId, limit, offset }: OrganizationApiOrganizationGetStorageSpaceUsageRequest, option?: RequestOption): Observable<Optional<PageOfRESTOrganizationStorageSpaceUsage>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationGetStorageSpaceUsage');
        throwIfNullOrUndefined(limit, 'limit', 'organizationGetStorageSpaceUsage');
        throwIfNullOrUndefined(offset, 'offset', 'organizationGetStorageSpaceUsage');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        return requestAuthenticated<PageOfRESTOrganizationStorageSpaceUsage>({
            url: '/v8/Organizations/{organizationId}/usedRepositories'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Allows you to obtain a device code to sign in to Microsoft Identity platform.
     * Get Device Code
     */
    organizationOrganizationDeviceCode: ({ representation }: OrganizationApiOrganizationOrganizationDeviceCodeRequest, option?: RequestOption): Observable<Optional<RESTDeviceCodeResponse>> => {
        throwIfNullOrUndefined(representation, 'representation', 'organizationOrganizationDeviceCode');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTDeviceCodeResponse>({
            url: '/v8/DeviceCode',
            method: 'POST',
            headers,
            body: representation,
        }, option);
    },

    /**
     * Removes an organization with the specified ID.
     * Remove Organization
     */
    organizationRemove: ({ organizationId }: OrganizationApiOrganizationRemoveRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationRemove');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<void>({
            url: '/v8/Organizations/{organizationId}'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'DELETE',
            headers,
        }, option);
    },

    /**
     * Modifies settings of an organization with the specified ID.
     * Edit Organization
     */
    organizationUpdate: ({ organizationId, organization }: OrganizationApiOrganizationUpdateRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationUpdate');
        throwIfNullOrUndefined(organization, 'organization', 'organizationUpdate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/Organizations/{organizationId}'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'PUT',
            headers,
            body: organization,
        }, option);
    },

})
