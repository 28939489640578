// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum RestoreItemOperation {
    None = 0,
    ItemSave = 1,
    ItemExport = 2,
    ItemSend = 3,
    ItemRestore = 4,
    ItemViewContent = 5
}
