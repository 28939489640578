/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTRestoreSession,
    RESTDeviceCodeRequestOptions,
    RESTDeviceCodeResponse,
    RESTExceptionInfo,
    RESTExploreOptions,
    RESTOperatorExploreOptions,
    RESTRestoreSession,
    RESTRestoreSessionStatistics,
} from '../models';

export interface RestoreSessionApiRestoreSessionDeviceCodeActionRequest {
    restoreSessionId: string;
    _requestOptions: RESTDeviceCodeRequestOptions;
}

export interface RestoreSessionApiRestoreSessionExploreActionRequest {
    exploreOptions: RESTExploreOptions;
}

export interface RestoreSessionApiRestoreSessionGetByRestoreSessionIdRequest {
    restoreSessionId: string;
}

export interface RestoreSessionApiRestoreSessionGetPageRequest {
    limit: number;
    offset: number;
    startTimeFrom?: string;
    startTimeTo?: string;
    endTimeFrom?: string;
    endTimeTo?: string;
    orderAsc?: boolean;
    orderBy?: RestoreSessionGetPageOrderByEnum;
    includeM365BackupStorage?: boolean;
}

export interface RestoreSessionApiRestoreSessionGetRestoreStatisticsRequest {
    restoreSessionId: string;
}

export interface RestoreSessionApiRestoreSessionOperatorExploreActionRequest {
    exploreOptions: RESTOperatorExploreOptions;
}

export interface RestoreSessionApiRestoreSessionStopActionRequest {
    restoreSessionId: string;
}

/**
 * no description
 */
export const restoreSessionApi = ({

    /**
     * Allows you to obtain a device code from Microsoft Identity platform to restore data of Microsoft 365 organizations added using modern app-only authentication.
     * Get Device Code
     */
    restoreSessionDeviceCodeAction: ({ restoreSessionId, _requestOptions }: RestoreSessionApiRestoreSessionDeviceCodeActionRequest, option?: RequestOption): Observable<Optional<RESTDeviceCodeResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'restoreSessionDeviceCodeAction');
        throwIfNullOrUndefined(_requestOptions, '_requestOptions', 'restoreSessionDeviceCodeAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTDeviceCodeResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/restoreDeviceCode'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
            body: _requestOptions,
        }, option);
    },

    /**
     * Creates and starts a restore session to explore and restore data of a tenant Microsoft organization.  To use this resource, make sure to obtain a token using the same credentials that were used to add the organization instead of credentials of an administrative account. For organizations with modern app-only authentication, obtain a token using an assertion obtained from Microsoft Identity platform. For more information, see the Veeam Backup for Microsoft 365 REST API Reference, section [Authorization for Tenants](https://helpcenter.veeam.com/docs/vbo365/rest/authorization_tenants_section.html?ver=80). 
     * Create Restore Session for Tenant Organization
     */
    restoreSessionExploreAction: ({ exploreOptions }: RestoreSessionApiRestoreSessionExploreActionRequest, option?: RequestOption): Observable<Optional<RESTRestoreSession>> => {
        throwIfNullOrUndefined(exploreOptions, 'exploreOptions', 'restoreSessionExploreAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTRestoreSession>({
            url: '/v8/Organization/Explore',
            method: 'POST',
            headers,
            body: exploreOptions,
        }, option);
    },

    /**
     * Returns a resource representation of a restore session with the specified ID.
     * Get Restore Session
     */
    restoreSessionGetByRestoreSessionId: ({ restoreSessionId }: RestoreSessionApiRestoreSessionGetByRestoreSessionIdRequest, option?: RequestOption): Observable<Optional<RESTRestoreSession>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'restoreSessionGetByRestoreSessionId');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTRestoreSession>({
            url: '/v8/RestoreSessions/{restoreSessionId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a collection of restore sessions.
     * Get Restore Sessions
     */
    restoreSessionGetPage: ({ limit, offset, startTimeFrom, startTimeTo, endTimeFrom, endTimeTo, orderAsc, orderBy, includeM365BackupStorage }: RestoreSessionApiRestoreSessionGetPageRequest, option?: RequestOption): Observable<Optional<PageOfRESTRestoreSession>> => {
        throwIfNullOrUndefined(limit, 'limit', 'restoreSessionGetPage');
        throwIfNullOrUndefined(offset, 'offset', 'restoreSessionGetPage');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (startTimeFrom != null) { query['startTimeFrom'] = startTimeFrom; }
        if (startTimeTo != null) { query['startTimeTo'] = startTimeTo; }
        if (endTimeFrom != null) { query['endTimeFrom'] = endTimeFrom; }
        if (endTimeTo != null) { query['endTimeTo'] = endTimeTo; }
        if (orderAsc != null) { query['orderAsc'] = orderAsc; }
        if (orderBy != null) { query['orderBy'] = orderBy; }
        if (includeM365BackupStorage != null) { query['includeM365BackupStorage'] = includeM365BackupStorage; }

        return requestAuthenticated<PageOfRESTRestoreSession>({
            url: '/v8/RestoreSessions',
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns statistics for a restore session with the specified ID.
     * Get Restore Session Statistics
     */
    restoreSessionGetRestoreStatistics: ({ restoreSessionId }: RestoreSessionApiRestoreSessionGetRestoreStatisticsRequest, option?: RequestOption): Observable<Optional<RESTRestoreSessionStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'restoreSessionGetRestoreStatistics');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTRestoreSessionStatistics>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Statistics'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Creates and starts a restore session to explore and restore data using Restore Portal.  To use this resource, make sure to obtain a token using an assertion obtained from Microsoft Identity platform and log in to Veeam Backup for Microsoft 365 REST API as a restore operator. For more information, see the Veeam Backup for Microsoft 365 REST API Reference, section [Authorization for Restore Operators](https://helpcenter.veeam.com/docs/vbo365/rest/authorization_restore_operator.html?ver=80). 
     * Create Restore Session for Restore Operator
     */
    restoreSessionOperatorExploreAction: ({ exploreOptions }: RestoreSessionApiRestoreSessionOperatorExploreActionRequest, option?: RequestOption): Observable<Optional<RESTRestoreSession>> => {
        throwIfNullOrUndefined(exploreOptions, 'exploreOptions', 'restoreSessionOperatorExploreAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTRestoreSession>({
            url: '/v8/Organization/OperatorExplore',
            method: 'POST',
            headers,
            body: exploreOptions,
        }, option);
    },

    /**
     * Stops a restore session with the specified ID.
     * Stop Restore Session
     */
    restoreSessionStopAction: ({ restoreSessionId }: RestoreSessionApiRestoreSessionStopActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'restoreSessionStopAction');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Stop'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum RestoreSessionGetPageOrderByEnum {
    CreationTime = 'CreationTime',
    EndTime = 'EndTime'
}
