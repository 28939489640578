// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of the Microsoft 365 Backup Storage policy.
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStoragePolicyBackupType {
    EntireOrganization = 'EntireOrganization',
    SelectedItems = 'SelectedItems'
}

