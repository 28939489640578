// Copyright © Veeam Software Group GmbH

import { doubleToolbar, SEARCH_MODE, toolbarAction } from '@veeam/components';

import type { CollectionToolbarConfig } from '@veeam/components';
import type { Item } from 'services';
import type { ExploreItemsFilter } from 'services/explore';
import type { TeamsItem, TeamsPostItem } from 'services/models/Teams/TeamsItem';

import { TeamsItemType } from 'services/models/Teams/TeamsItem';
import { downloadSrc } from 'assets';
import { restoreToolbarAction } from 'features/Restore';
import { createConfigMaker, createToolbarFilters } from 'infrastructure/grid';
import { addToRestoreListAction } from '../../../helpers/addToRestoreListAction';
import { authApi, teamsChannelApi, teamsPostApi } from '../../../../../api/rxjs';
import { exploreSessionService } from '../../../../../services/exploreSessions';
import { errorManager } from '../../../../../infrastructure/error-management';

import type { RESTAuthCode, RESTTeamsPost, ResultKeeper } from '../../../../../api/rxjs';
import type { GridResources } from '../interfaces/grid-resources';
import type { ExploreGridModel } from '../../../models/explore-grid-model';

const download = (blob: Blob, filename: string): void => {
    const element = document.createElement('a');
    const downloadUrl: string = window.URL.createObjectURL(blob);

    element.setAttribute('href', downloadUrl);
    element.setAttribute('download', filename);
    element.click();

    setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100); // cleanup
};

const getStrBetween = (s: string, prefix: string, suffix: string): string => {
    let i = s.indexOf(prefix);

    if (i < 0) {
        return '';
    }

    s = s.substring(i + prefix.length);

    if (suffix) {
        i = s.indexOf(suffix);

        return i >= 0 ? s.substring(0, i) : s.substring(0, s.length);
    }

    return s;
};

export const downloadFile = (url: string, handleErrors?: boolean): void => {
    fetch(url)
        .then((result) => {
            if (result.status === 200) {
                result.blob()
                    .then((blob: Blob) => {
                        const disposition: string = result.headers.get('Content-Disposition') || '';
                        const possibleFilename = getStrBetween(disposition, 'filename=', ';');
                        const filenameWithEncoding = decodeURIComponent(
                            possibleFilename === ''
                                ? getStrBetween(disposition, 'filename*=', ';')
                                : possibleFilename
                        );
                        const filename = filenameWithEncoding.replace(/^[a-z0-9-_]{1,15}''/i, '');

                        download(blob, filename);
                    });
            } else {
                result.json()
                    .then((resultJson) => {
                        if (resultJson?.message && handleErrors) {
                            errorManager.register(resultJson?.message);
                        }
                    });
            }

        });
};

export function getToolbar(
    { toolbar: resources }: GridResources,
    model: ExploreGridModel,
    searchAvailable: boolean,
    exportAvailable: boolean,
    exportAllPostsAvailable: boolean,
): CollectionToolbarConfig {
    const searchConfig = createConfigMaker<ExploreItemsFilter>().search('search', {
        fieldConfig: {
            mode: SEARCH_MODE.compulsory,
        },
        disabled: !searchAvailable,
    });
    const filters = createToolbarFilters(searchConfig);
    const getItems = () => model.getValue().selection
        .map(id => model.getItemById(id))
        .filter((item): item is Item => !!item);
    const restoreAction = restoreToolbarAction(getItems);
    const addToBucketAction = addToRestoreListAction(getItems, model.unselectAll);
    const exportAction = toolbarAction({
        title: resources.exportToHtml,
        icon: downloadSrc,
        decorators: [
            (payload) => {
                let disabled = false;

                if (payload.selectedItems.length === 0) {
                    disabled = true;
                }

                if (payload.selectedItems.length > 0) {
                    const selectedItems = payload.selectedItems as unknown as TeamsItem[];

                    disabled = selectedItems.every(
                        item => item.itemType !== TeamsItemType.Post || (item as TeamsPostItem).isDeleted
                    );
                }

                return {
                    disabled,
                };
            },
        ],
        hidden: !exportAvailable,
        handler: async() => {
            const { vetSession } = exploreSessionService.getSessions();
            const posts = getItems().filter(
                (item: TeamsPostItem) => item.itemType === TeamsItemType.Post && !item.isDeleted
            ) as RESTTeamsPost[];
            const teamId = (posts[0] as RESTTeamsPost).teamId;

            const { data } = await authApi.authCode().toPromise() as ResultKeeper<RESTAuthCode>;
            const { authCode } = data;

            const { exportTaskId } = await teamsPostApi.teamsPostOperatorStartExportTask({
                restoreSessionId: vetSession,
                teamId,
                exportOptions: {
                    posts,
                },
            }).toPromise()
                .then(res => res.getResultOrThrow());

            downloadFile(
                `/v8/RestoreSessions/${vetSession}/organization/teams/${teamId}/posts/operatorExport/${exportTaskId}?authCode=${authCode}`
            );
        },
    });

    const exportPostsAction = toolbarAction({
        title: resources.exportToHtml,
        icon: downloadSrc,
        decorators: [
            (payload) => {
                let disabled = false;

                if (payload.selectedItems.length === 0) {
                    disabled = true;
                }

                if (payload.selectedItems.length > 0) {
                    const selectedItems = payload.selectedItems as unknown as TeamsItem[];

                    disabled = !selectedItems.some(item => item.itemType === TeamsItemType.Posts);
                }

                return {
                    disabled,
                };
            },
        ],
        hidden: !exportAllPostsAvailable,
        handler: async() => {
            const { vetSession } = exploreSessionService.getSessions();
            const posts = getItems().filter(
                item => item.itemType === TeamsItemType.Posts
            );
            const teamId = (posts[0] as RESTTeamsPost).teamId;
            const channelId = (posts[0] as RESTTeamsPost).channelId;

            const { data } = await authApi.authCode().toPromise() as ResultKeeper<RESTAuthCode>;
            const { authCode } = data;

            const { exportTaskId } = await teamsChannelApi.teamsChannelOperatorStartExportTask({
                restoreSessionId: vetSession,
                teamId,
                channelId,
            }).toPromise()
                .then(res => res.getResultOrThrow());

            downloadFile(
                `/v8/RestoreSessions/${vetSession}/organization/teams/${teamId}/channels/${channelId}/operatorExport/${exportTaskId}?authCode=${authCode}`,
                true,
            );
        },
    });

    return {
        decorator: doubleToolbar(),
        items: [...filters, restoreAction, addToBucketAction, exportAction, exportPostsAction],
    };
}
