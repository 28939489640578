// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

import { Observable } from "rxjs";
import { subjects } from "../runtime";
import { RestoreSessionConfig, RestoreSessionLogConfig } from "../models";

export interface OperatorEventsHub {
    restoreSessionStartedAsync: Observable<RestoreSessionConfig>;
    restoreSessionStoppedAsync: Observable<RestoreSessionConfig>;
    historyClearedAsync: Observable<unknown>;
    addRestoreSessionLogAsync: Observable<RestoreSessionLogConfig>;
    finishRestoreSessionLogAsync: Observable<RestoreSessionLogConfig>;
    onUnavailableExchangeDataFoundAsync: Observable<unknown>;
    onUnavailableSharePointDataFoundAsync: Observable<unknown>;
    onUnavailableOneDriveDataFoundAsync: Observable<unknown>;
}

export const operatorEventsHub: OperatorEventsHub = subjects.operatorEventsHub;
