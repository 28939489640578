// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTSharePointDocument,
} from './';

/**
 * @export
 * @interface RESTRestoreDocumentsConfig
 */
export interface RESTRestoreDocumentsConfig {
    /**
     * Specifies IDs of the SharePoint documents that you want to restore. For more information on how to get such IDs, see [Get SharePoint Documents](#tag/SharePointDocument/operation/SharePointDocument_Get).
     * @type {Array<RESTSharePointDocument>}
     * @memberof RESTRestoreDocumentsConfig
     */
    documents?: Array<RESTSharePointDocument>;
    /**
     * Specifies the target SharePoint list.
     * @type {string}
     * @memberof RESTRestoreDocumentsConfig
     */
    list?: string;
    /**
     * Defines whether the SharePoint documents will be restored with all permissions.
     * @type {boolean}
     * @memberof RESTRestoreDocumentsConfig
     */
    restorePermissions?: boolean;
    /**
     * Defines whether the shared links notifications will be sent.
     * @type {boolean}
     * @memberof RESTRestoreDocumentsConfig
     */
    sendSharedLinksNotification?: boolean | null;
    /**
     * Specifies what version of the SharePoint documents will be restored.
     * @type {string}
     * @memberof RESTRestoreDocumentsConfig
     */
    documentVersion?: RESTRestoreDocumentsConfigDocumentVersionEnum;
    /**
     * Specifies the action that will be performed with the last version of the restored SharePoint document on the destination server.
     * @type {string}
     * @memberof RESTRestoreDocumentsConfig
     */
    documentLastVersionAction?: RESTRestoreDocumentsConfigDocumentLastVersionActionEnum;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreDocumentsConfig
     */
    userCode?: string;
    /**
     * Specifies the ID of the Micrisoft Entra application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreDocumentsConfig
     */
    applicationId?: string | null;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreDocumentsConfig
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Microsoft Entra application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreDocumentsConfig
     */
    applicationCertificate?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTRestoreDocumentsConfig
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreDocumentsConfig
     */
    userPassword?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreDocumentsConfigDocumentVersionEnum {
    All = 'All',
    Last = 'Last'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreDocumentsConfigDocumentLastVersionActionEnum {
    Overwrite = 'Overwrite',
    Merge = 'Merge'
}

