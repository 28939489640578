// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
    RESTTeamsTeamSettings,
} from './';

/**
 * @export
 * @interface RESTTeamsTeam
 */
export interface RESTTeamsTeam {
    /**
     * ID of the backed-up team.
     * @type {string}
     * @memberof RESTTeamsTeam
     */
    id: string;
    /**
     * Display name of the backed-up team.
     * @type {string}
     * @memberof RESTTeamsTeam
     */
    displayName?: string;
    /**
     * Description of the backed-up team.
     * @type {string}
     * @memberof RESTTeamsTeam
     */
    description?: string;
    /**
     * Email address of the backed-up team.
     * @type {string}
     * @memberof RESTTeamsTeam
     */
    groupEmail?: string;
    /**
     * Alias of the backed-up team.
     * @type {string}
     * @memberof RESTTeamsTeam
     */
    alias?: string;
    /**
     * Type of the backed-up team.
     * @type {string}
     * @memberof RESTTeamsTeam
     */
    privacy?: RESTTeamsTeamPrivacyEnum;
    /**
     * @type {RESTTeamsTeamSettings}
     * @memberof RESTTeamsTeam
     */
    settings?: RESTTeamsTeamSettings;
    /**
     * Defines whether the team is archived.
     * @type {boolean}
     * @memberof RESTTeamsTeam
     */
    isArchived?: boolean;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTTeamsTeam
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTTeamsTeamPrivacyEnum {
    Public = 'Public',
    Private = 'Private',
    HiddenMembership = 'HiddenMembership'
}

