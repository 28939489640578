// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of the restore operation performed with the object.
 * @export
 * @enum {string}
 */
export enum RESTItemRestoreDetailsType {
    Item = 'Item',
    BulkMailbox = 'BulkMailbox',
    BulkOneDrive = 'BulkOneDrive'
}

