// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTM365BackupStorageApiErrorNullable,
} from './';

/**
 * @export
 * @interface RESTM365BackupStorageOrganizationStatus
 */
export interface RESTM365BackupStorageOrganizationStatus {
    /**
     * Status of protection provided by Microsoft 365 Backup Storage for the Microsoft 365 organization.
     * @type {string}
     * @memberof RESTM365BackupStorageOrganizationStatus
     */
    serviceStatus?: RESTM365BackupStorageOrganizationStatusServiceStatusEnum;
    /**
     * Status of the Microsoft 365 Backup Storage service Microsoft Entra application.
     * @type {string}
     * @memberof RESTM365BackupStorageOrganizationStatus
     */
    serviceAppStatus?: RESTM365BackupStorageOrganizationStatusServiceAppStatusEnum;
    /**
     * Defines whether management of the Microsoft 365 Backup Storage settings is enabled for the Microsoft 365 organization.
     * @type {boolean}
     * @memberof RESTM365BackupStorageOrganizationStatus
     */
    m365BackupStorageEnabled?: boolean;
    /**
     * Date and time till when the service Microsoft Entra application will be used if another service application is already configured. The server returns this property if billing profile is enabled for Microsoft 365 Backup Storage to protect the Microsoft 365 organization. For more information, see [Enable Billing Profile](#tag/M365BackupStorage/operation/M365BackupStorage_EnableBilling).
     * @type {string}
     * @memberof RESTM365BackupStorageOrganizationStatus
     */
    readonly gracePeriodTime?: string | null;
    /**
     * Date and time received from Microsoft till when restore operation is allowed.
     * @type {string}
     * @memberof RESTM365BackupStorageOrganizationStatus
     */
    readonly restoreAllowedTillTime?: string | null;
    /**
     * Reason why usage of the service Microsoft Entra application was disabled by Microsoft.
     * @type {string}
     * @memberof RESTM365BackupStorageOrganizationStatus
     */
    readonly disableReason?: string | null;
    /**
     * @type {RESTM365BackupStorageApiErrorNullable}
     * @memberof RESTM365BackupStorageOrganizationStatus
     */
    error?: RESTM365BackupStorageApiErrorNullable | null;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStorageOrganizationStatusServiceStatusEnum {
    None = 'None',
    Enabled = 'Enabled',
    Disabled = 'Disabled',
    ProtectionChangeLocked = 'ProtectionChangeLocked',
    RestoreLocked = 'RestoreLocked'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTM365BackupStorageOrganizationStatusServiceAppStatusEnum {
    NotFound = 'NotFound',
    Active = 'Active',
    Inactive = 'Inactive',
    PendingActive = 'PendingActive',
    PendingInactive = 'PendingInactive'
}

