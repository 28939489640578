// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTRestoreSharePointItemConfig,
} from './';

/**
 * @export
 * @interface RESTOperatorRestoreSharePointItemsConfig
 */
export interface RESTOperatorRestoreSharePointItemsConfig {
    /**
     * Specifies settings to restore the SharePoint item.
     * @type {Array<RESTRestoreSharePointItemConfig>}
     * @memberof RESTOperatorRestoreSharePointItemsConfig
     */
    itemsRestoreConfigs: Array<RESTRestoreSharePointItemConfig>;
    /**
     * Specifies the target SharePoint list.
     * @type {string}
     * @memberof RESTOperatorRestoreSharePointItemsConfig
     */
    list?: string;
    /**
     * Defines whether the SharePoint items will be restored with all permissions.
     * @type {boolean}
     * @memberof RESTOperatorRestoreSharePointItemsConfig
     */
    restorePermissions?: boolean | null;
    /**
     * Defines whether the shared links notifications will be sent.
     * @type {boolean}
     * @memberof RESTOperatorRestoreSharePointItemsConfig
     */
    sendSharedLinksNotification?: boolean | null;
    /**
     * Specifies what version of the SharePoint documents will be restored.
     * @type {string}
     * @memberof RESTOperatorRestoreSharePointItemsConfig
     */
    documentVersion?: RESTOperatorRestoreSharePointItemsConfigDocumentVersionEnum;
    /**
     * Specifies the action that will be performed with the last version of the restored SharePoint document on the destination server.
     * @type {string}
     * @memberof RESTOperatorRestoreSharePointItemsConfig
     */
    documentLastVersionAction?: RESTOperatorRestoreSharePointItemsConfigDocumentLastVersionActionEnum;
    /**
     * Defines whether to restore the items that have been modified in the original location.
     * @type {boolean}
     * @memberof RESTOperatorRestoreSharePointItemsConfig
     */
    restoreChangedItems?: boolean | null;
    /**
     * Defines whether to restore the items that have been deleted in the original location.
     * @type {boolean}
     * @memberof RESTOperatorRestoreSharePointItemsConfig
     */
    restoreDeletedItems?: boolean | null;
    /**
     * Specifies a reason for the restore operation.
     * @type {string}
     * @memberof RESTOperatorRestoreSharePointItemsConfig
     */
    reason?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOperatorRestoreSharePointItemsConfigDocumentVersionEnum {
    All = 'All',
    Last = 'Last'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTOperatorRestoreSharePointItemsConfigDocumentLastVersionActionEnum {
    Overwrite = 'Overwrite',
    Merge = 'Merge'
}

