// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum JobType {
    Backup = 0,
    Move = 1,
    Remove = 2,
    Copy = 3,
    Retrieve = 4,
    ChangeDataRetrievalAvailabilityPeriod = 5,
    RemoveDataRetrieval = 6,
    Migrate = 7,
    M365Backup = 8
}
