// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}
