// Copyright © Veeam Software Group GmbH

import type { EnumMap } from 'infrastructure/types';
import type { ExchangeFolderCategory, ExchangeNode, Node, OneDriveNodeType, SharePointNodeType } from 'services';
import type { TeamsNode } from 'services/models/Teams';

import { ExchangeFolderType, ExchangeNodeType, Product } from 'services';
import {
    appointmentSrc,
    channelSrc,
    sharedChannelSrc,
    privateChannelSrc,
    contactsSrc,
    deletedItemsSrc,
    draftsSrc,
    fileSrc,
    folderSrc,
    inboxSrc,
    journalSrc,
    junkEmailSrc,
    librarySrc,
    listSrc,
    listsSrc,
    mailboxSrc,
    notesSrc,
    oneDriveSrc,
    otherTabSrc,
    outboxSrc,
    permanentlyDeletedItemsSrc,
    postSrc,
    sentItemsSrc,
    spSiteSrc,
    spSubsitesSrc,
    tasksSrc,
    teamFolderSrc,
    teamRootSrc,
} from 'assets';
import { never } from 'infrastructure/never';
import { TeamsChannelNode, TeamsNodeType } from 'services/models/Teams';
import { RESTTeamsChannelPreviewTypeEnum } from 'api/rxjs';


const exchangeFolderIconsByType: EnumMap<ExchangeFolderType, string> = {
    Appointment: appointmentSrc,
    Contact: contactsSrc,
    Journal: journalSrc,
    Task: tasksSrc,
    StickyNote: notesSrc,
    None: folderSrc,
};

const exchangeFolderIconsByCategory: EnumMap<ExchangeFolderCategory, string> = {
    Inbox: inboxSrc,
    Outbox: outboxSrc,
    Sent: sentItemsSrc,
    Deleted: deletedItemsSrc,
    Drafts: draftsSrc,
    PermanentlyDeletedItems: permanentlyDeletedItemsSrc,
    Purges: folderSrc,
    DiscoveryHolds: folderSrc,
    Conflicts: folderSrc,
    SyncIssues: folderSrc,
    LocalFailures: folderSrc,
    ServerFailures: folderSrc,
    Junk: junkEmailSrc,
    Versions: folderSrc,
    TeamsMessagesData: folderSrc,
    None: folderSrc,
};

const getExchangeNodeIcons = (node: ExchangeNode): string => {
    switch (node.nodeType) {
        case ExchangeNodeType.Mailbox:
            return mailboxSrc;
        case ExchangeNodeType.Folder:
            if (node.folderType !== ExchangeFolderType.None) {
                return exchangeFolderIconsByType[node.folderType];
            }
            return exchangeFolderIconsByCategory[node.folderCategory];
        default:
            return never(node);
    }
};

const getTeamsNodeIcons = (node: TeamsNode): string => {
    if (node.nodeType === TeamsNodeType.Channel) {
        const channelNode: TeamsChannelNode = node;

        switch (channelNode.type) {
            case RESTTeamsChannelPreviewTypeEnum.Shared:
                return sharedChannelSrc;
            case RESTTeamsChannelPreviewTypeEnum.Private:
                return privateChannelSrc;
            default:
                return channelSrc;
        }
    } else {
        return teamsNodeIcons[node.nodeType];
    }
};

const oneDriveNodeIcons: EnumMap<OneDriveNodeType, string> = {
    OneDrive: oneDriveSrc,
    Folder: folderSrc,
};

const sharePointNodeIcons: EnumMap<SharePointNodeType, string> = {
    Site: spSiteSrc,
    SubSites: spSubsitesSrc,
    Content: listsSrc,
    List: listSrc,
    Library: librarySrc,
    ListFolder: folderSrc,
    LibraryFolder: folderSrc,
};

const teamsNodeIcons: EnumMap<TeamsNodeType, string> = {
    Teams: teamRootSrc,
    Team: teamFolderSrc,
    Channel: channelSrc,
    Files: fileSrc,
    Posts: postSrc,
    Tabs: otherTabSrc,
    Folder: folderSrc,
};

export const getIcon = (node: Node): string => {
    switch (node.product) {
        case Product.Exchange:
            return getExchangeNodeIcons(node);
        case Product.OneDrive:
            return oneDriveNodeIcons[node.nodeType];
        case Product.SharePoint:
            return sharePointNodeIcons[node.nodeType];
        case Product.Teams:
            return getTeamsNodeIcons(node);
        default:
            return never(node);
    }
};
