/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTChannelEntity,
    PageOfRESTTeamsTeam,
    RESTAsyncRestoreResponse,
    RESTExceptionInfo,
    RESTOperatorRestoreTeamOptions,
    RESTRestoreTeamOptions,
    RESTTeamsOperatorRestoreSessionResponse,
    RESTTeamsSearchOptions,
    RESTTeamsTeam,
} from '../models';

export interface TeamsTeamApiTeamsTeamGetRequest {
    restoreSessionId: string;
    offset: number;
    limit: number;
    displayName?: string;
}

export interface TeamsTeamApiTeamsTeamGetByIdRequest {
    restoreSessionId: string;
    teamId: string;
}

export interface TeamsTeamApiTeamsTeamOperatorRestoreRequest {
    restoreSessionId: string;
    action: RESTOperatorRestoreTeamOptions;
}

export interface TeamsTeamApiTeamsTeamRestoreRequest {
    restoreSessionId: string;
    action: RESTRestoreTeamOptions;
}

export interface TeamsTeamApiTeamsTeamRestoreByIdRequest {
    restoreSessionId: string;
    teamId: string;
    action: RESTRestoreTeamOptions;
}

export interface TeamsTeamApiTeamsTeamSearchRequest {
    restoreSessionId: string;
    teamId: string;
    offset: number;
    limit: number;
    action: RESTTeamsSearchOptions;
}

/**
 * no description
 */
export const teamsTeamApi = ({

    /**
     * Returns a collection of backed-up teams to explore and restore Microsoft Teams data.
     * Get Teams
     */
    teamsTeamGet: ({ restoreSessionId, offset, limit, displayName }: TeamsTeamApiTeamsTeamGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTTeamsTeam>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTeamGet');
        throwIfNullOrUndefined(offset, 'offset', 'teamsTeamGet');
        throwIfNullOrUndefined(limit, 'limit', 'teamsTeamGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (displayName != null) { query['displayName'] = displayName; }

        return requestAuthenticated<PageOfRESTTeamsTeam>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up team with the specified ID.
     * Get Team
     */
    teamsTeamGetById: ({ restoreSessionId, teamId }: TeamsTeamApiTeamsTeamGetByIdRequest, option?: RequestOption): Observable<Optional<RESTTeamsTeam>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTeamGetById');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTeamGetById');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTTeamsTeam>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Restores backed-up teams using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=80) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong> </br> To restore data using Restore Portal, you must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div> 
     * Restore Teams by Restore Operator
     */
    teamsTeamOperatorRestore: ({ restoreSessionId, action }: TeamsTeamApiTeamsTeamOperatorRestoreRequest, option?: RequestOption): Observable<Optional<RESTTeamsOperatorRestoreSessionResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTeamOperatorRestore');
        throwIfNullOrUndefined(action, 'action', 'teamsTeamOperatorRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTTeamsOperatorRestoreSessionResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Restores backed-up teams.
     * Restore Teams
     */
    teamsTeamRestore: ({ restoreSessionId, action }: TeamsTeamApiTeamsTeamRestoreRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTeamRestore');
        throwIfNullOrUndefined(action, 'action', 'teamsTeamRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Restores a backed-up team with the specified ID.
     * Restore Team
     */
    teamsTeamRestoreById: ({ restoreSessionId, teamId, action }: TeamsTeamApiTeamsTeamRestoreByIdRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTeamRestoreById');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTeamRestoreById');
        throwIfNullOrUndefined(action, 'action', 'teamsTeamRestoreById');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            body: action,
        }, option);
    },

    /**
     * Searches for Microsoft Teams items of a backed-up team with the specified ID.
     * Search for Teams Items in Team
     */
    teamsTeamSearch: ({ restoreSessionId, teamId, offset, limit, action }: TeamsTeamApiTeamsTeamSearchRequest, option?: RequestOption): Observable<Optional<PageOfRESTChannelEntity>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsTeamSearch');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsTeamSearch');
        throwIfNullOrUndefined(offset, 'offset', 'teamsTeamSearch');
        throwIfNullOrUndefined(limit, 'limit', 'teamsTeamSearch');
        throwIfNullOrUndefined(action, 'action', 'teamsTeamSearch');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTChannelEntity>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'POST',
            headers,
            query,
            body: action,
        }, option);
    },

})
