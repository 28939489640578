// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of the backed-up data.
 * @export
 * @enum {string}
 */
export enum RESTDataRetrievalDataState {
    Retrieving = 'Retrieving',
    Retrieved = 'Retrieved',
    ChangingAvailabilityPeriod = 'ChangingAvailabilityPeriod',
    Removing = 'Removing'
}

