/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTOneDrive,
    RESTExceptionInfo,
    RESTOneDrive,
} from '../models';

export interface OrganizationUserOneDriveApiOrganizationUserOneDriveGetOneRequest {
    organizationId: string;
    userId: string;
    onedriveId: string;
}

export interface OrganizationUserOneDriveApiOrganizationUserOneDriveGetPageRequest {
    organizationId: string;
    userId: string;
    limit: number;
    offset: number;
    setId?: string;
}

/**
 * no description
 */
export const organizationUserOneDriveApi = ({

    /**
     * Returns a resource representation of OneDrive with the specified ID that belongs to an organization user with the specified ID.
     * Get OneDrive of Organization User
     */
    organizationUserOneDriveGetOne: ({ organizationId, userId, onedriveId }: OrganizationUserOneDriveApiOrganizationUserOneDriveGetOneRequest, option?: RequestOption): Observable<Optional<RESTOneDrive>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationUserOneDriveGetOne');
        throwIfNullOrUndefined(userId, 'userId', 'organizationUserOneDriveGetOne');
        throwIfNullOrUndefined(onedriveId, 'onedriveId', 'organizationUserOneDriveGetOne');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTOneDrive>({
            url: '/v8/Organizations/{organizationId}/Users/{userId}/OneDrives/{onedriveId}'.replace('{organizationId}', encodeURI(organizationId)).replace('{userId}', encodeURI(userId)).replace('{onedriveId}', encodeURI(onedriveId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a collection of an organization user OneDrives. <div class=\"note\"><strong>NOTE</strong> </br> A user can have two OneDrives if it is a member of a hybrid Microsoft 365 organization. </div> 
     * Get OneDrives of Organization User
     */
    organizationUserOneDriveGetPage: ({ organizationId, userId, limit, offset, setId }: OrganizationUserOneDriveApiOrganizationUserOneDriveGetPageRequest, option?: RequestOption): Observable<Optional<PageOfRESTOneDrive>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationUserOneDriveGetPage');
        throwIfNullOrUndefined(userId, 'userId', 'organizationUserOneDriveGetPage');
        throwIfNullOrUndefined(limit, 'limit', 'organizationUserOneDriveGetPage');
        throwIfNullOrUndefined(offset, 'offset', 'organizationUserOneDriveGetPage');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (setId != null) { query['setId'] = setId; }

        return requestAuthenticated<PageOfRESTOneDrive>({
            url: '/v8/Organizations/{organizationId}/Users/{userId}/OneDrives'.replace('{organizationId}', encodeURI(organizationId)).replace('{userId}', encodeURI(userId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

})
