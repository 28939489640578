// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of the restored item.
 * @export
 * @enum {string}
 */
export enum RESTRestoreItemType {
    TeamsTeam = 'TeamsTeam',
    TeamsPostsTab = 'TeamsPostsTab',
    TeamsPost = 'TeamsPost',
    TeamsTeamApp = 'TeamsTeamApp',
    TeamsChannel = 'TeamsChannel',
    TeamsTab = 'TeamsTab',
    TeamsFile = 'TeamsFile',
    SharePointSite = 'SharePointSite',
    SharePointList = 'SharePointList',
    SharePointItem = 'SharePointItem',
    OneDriveItem = 'OneDriveItem',
    ExchangeMailbox = 'ExchangeMailbox'
}

