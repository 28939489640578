// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
    RESTObjectStorageComposed,
} from './';

/**
 * @export
 * @interface RESTBackupRepository
 */
export interface RESTBackupRepository {
    /**
     * Defines whether the object storage encryption is enabled.
     * @type {boolean}
     * @memberof RESTBackupRepository
     */
    objectStorageEncryptionEnabled?: boolean | null;
    /**
     * Encryption key ID.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    encryptionKeyId?: string | null;
    /**
     * Size of the backup repository in *Bytes*.
     * @type {number}
     * @memberof RESTBackupRepository
     */
    capacityBytes?: number | null;
    /**
     * Available space on the backup repository in *Bytes*.
     * @type {number}
     * @memberof RESTBackupRepository
     */
    freeSpaceBytes?: number | null;
    /**
     * Backup repository ID.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    id?: string | null;
    /**
     * Name of the backup repository.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    name?: string;
    /**
     * Description of the backup repository.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    description?: string;
    /**
     * Path to the directory where backups are stored.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    path?: string;
    /**
     * Type of the retention policy.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    retentionType?: RESTBackupRepositoryRetentionTypeEnum;
    /**
     * Type of the retention period.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    retentionPeriodType?: RESTBackupRepositoryRetentionPeriodTypeEnum;
    /**
     * Retention period in days. This property is valid if `retentionPeriodType` is set to *Daily*.
     * @type {number}
     * @memberof RESTBackupRepository
     */
    dailyRetentionPeriod?: number | null;
    /**
     * Retention period in months. This property is valid if `retentionPeriodType` is set to *Monthly*.
     * @type {number}
     * @memberof RESTBackupRepository
     */
    monthlyRetentionPeriod?: number | null;
    /**
     * Retention period in years. This property is valid if `retentionPeriodType` is set to *Yearly*. If set to *Keep*, the backup job will back up all selected items and will never remove them.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    yearlyRetentionPeriod?: RESTBackupRepositoryYearlyRetentionPeriodEnum;
    /**
     * Type of the clean-up schedule. The following types are available: <ul> <li>*Daily*. Veeam Backup for Microsoft 365 checks and removes the outdated backups once a day. Use the `dailyTime` property to set the time of the day for performing clean-up. Use the `dailyType` property to set the days for performing clean-up.</li> <li>*Monthly*. Veeam Backup for Microsoft 365 checks and removes the outdated backups once a month. Use the `monthlyTime` property to set the time of the day for performing clean-up. Use the `monthlyDaynumber` and `monthlyDayofweek` properties to set the day for performing clean-up.</li> </ul> 
     * @type {string}
     * @memberof RESTBackupRepository
     */
    retentionFrequencyType?: RESTBackupRepositoryRetentionFrequencyTypeEnum;
    /**
     * Time of the day when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    dailyTime?: string;
    /**
     * Days when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    dailyType?: RESTBackupRepositoryDailyTypeEnum;
    /**
     * Time of the day when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    monthlyTime?: string;
    /**
     * Order number for the day of the week when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    monthlyDaynumber?: RESTBackupRepositoryMonthlyDaynumberEnum;
    /**
     * Day of the week when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    monthlyDayofweek?: RESTBackupRepositoryMonthlyDayofweekEnum;
    /**
     * Backup proxy server ID.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    proxyId?: string | null;
    /**
     * Backup proxy pool ID.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    proxyPoolId?: string | null;
    /**
     * Backed-up organization ID.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    organizationId?: string | null;
    /**
     * Defines whether object storage repository is one of the following: Azure Blob Storage Archive access tier, Amazon S3 Glacier Instant Retrieval, Amazon S3 Glacier Flexible Retrieval or Amazon S3 Glacier Deep Archive storage classes. 
     * @type {boolean}
     * @memberof RESTBackupRepository
     */
    isLongTerm?: boolean | null;
    /**
     * Defines whether a backup repository has the *Out of Date* state and must be upgraded.
     * @type {boolean}
     * @memberof RESTBackupRepository
     */
    isOutdated?: boolean;
    /**
     * Defines whether object storage repository has the *Out of Sync* state. Synchronization of cache between object storage repository and the *PersistentCache* is required.
     * @type {boolean}
     * @memberof RESTBackupRepository
     */
    isOutOfSync?: boolean;
    /**
     * Defines whether a backup repository was indexed.
     * @type {boolean}
     * @memberof RESTBackupRepository
     */
    isIndexed?: boolean;
    /**
     * Defines whether a backup repository has the *Invalid* state.
     * @type {boolean}
     * @memberof RESTBackupRepository
     */
    isOutOfOrder?: boolean;
    /**
     * Reason why a backup repository has the *Invalid* state.
     * @type {string}
     * @memberof RESTBackupRepository
     */
    outOfOrderReason?: string | null;
    /**
     * Related resources.
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTBackupRepository
     */
    _links?: { [key: string]: RESTLinkHAL; };
    /**
     * @type {RESTObjectStorageComposed}
     * @memberof RESTBackupRepository
     */
    objectStorage?: RESTObjectStorageComposed;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryRetentionTypeEnum {
    ItemLevel = 'ItemLevel',
    SnapshotBased = 'SnapshotBased'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryRetentionPeriodTypeEnum {
    Monthly = 'Monthly',
    Daily = 'Daily',
    Yearly = 'Yearly'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryYearlyRetentionPeriodEnum {
    Year1 = 'Year1',
    Years2 = 'Years2',
    Years3 = 'Years3',
    Years5 = 'Years5',
    Years7 = 'Years7',
    Years10 = 'Years10',
    Years25 = 'Years25',
    Keep = 'Keep'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryRetentionFrequencyTypeEnum {
    Daily = 'Daily',
    Monthly = 'Monthly'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryDailyTypeEnum {
    Everyday = 'Everyday',
    Workdays = 'Workdays',
    Weekends = 'Weekends',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryMonthlyDaynumberEnum {
    First = 'First',
    Second = 'Second',
    Third = 'Third',
    Fourth = 'Fourth',
    Last = 'Last'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryMonthlyDayofweekEnum {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}

