// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTAccount
 */
export interface RESTAccount {
    /**
     * Cloud account type.
     * @type {string}
     * @memberof RESTAccount
     */
    accountType?: RESTAccountAccountTypeEnum;
    /**
     * Cloud account user name.
     * @type {string}
     * @memberof RESTAccount
     */
    userName?: string;
    /**
     * Cloud account ID.
     * @type {string}
     * @memberof RESTAccount
     */
    id?: string | null;
    /**
     * Cloud account description.
     * @type {string}
     * @memberof RESTAccount
     */
    description?: string;
    /**
     * Backed-up organization ID.
     * @type {string}
     * @memberof RESTAccount
     */
    organizationId?: string | null;
    /**
     * Related resources.
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTAccount
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTAccountAccountTypeEnum {
    AzureBlobAccount = 'azureBlobAccount',
    AmazonS3Account = 'amazonS3Account',
    AmazonS3CompatibleAccount = 'amazonS3CompatibleAccount'
}

