// Copyright © Veeam Software Group GmbH
/* eslint-disable */
// Autogenerated

export enum JobStatus {
    Stopped = 0,
    Running = 1,
    Succeeded = 2,
    Failed = 3,
    Warning = 4,
    NotConfigured = 5
}
