// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTRestoreToVersionConfig
 */
export interface RESTRestoreToVersionConfig {
    /**
     * Specifies the target SharePoint list.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    list?: string;
    /**
     * Defines whether the shared links notifications will be sent.
     * @type {boolean}
     * @memberof RESTRestoreToVersionConfig
     */
    sendSharedLinksNotification?: boolean | null;
    /**
     * Specifies the action that will be performed with the last version of the restored SharePoint document on the destination server.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    documentLastVersionAction?: RESTRestoreToVersionConfigDocumentLastVersionActionEnum;
    /**
     * Specifies the URL of the target SharePoint site.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    siteURL?: string;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    userCode?: string;
    /**
     * Specifies the ID of the Micrisoft Entra application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    applicationId?: string | null;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Microsoft Entra application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    applicationCertificate?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    userPassword?: string;
    /**
     * Specifies the region of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    officeRegion?: RESTRestoreToVersionConfigOfficeRegionEnum;
    /**
     * Specifies the name of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToVersionConfig
     */
    organizationName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToVersionConfigDocumentLastVersionActionEnum {
    Overwrite = 'Overwrite',
    Merge = 'Merge'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToVersionConfigOfficeRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

