// Copyright © Veeam Software Group GmbH

import { forkJoin, of } from 'rxjs';
import { delayWhen, filter, map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type {
    RESTRestoreOneDriveDocumentConfig } from 'api/rxjs';
import type { OneDriveId, OneDriveItem, VeodSession } from 'services';

import {
    oneDriveDocumentApi,
    RESTOneDriveDocument,
    RESTOperatorRestoreToOriginalOneDriveDocumentsDocumentActionEnum as OneDriveRestoreType,
    RESTRestoreOneDriveDocumentConfigDocumentVersionEnum,
} from 'api/rxjs';
import { groupBy } from 'infrastructure/helpers';
import { getDatabase } from 'services/database';

import type { RestoreSessionId } from '../models';


export { OneDriveRestoreType };

export interface OneDriveRestoreOptions {
    restoreType: OneDriveRestoreType;
    reason: string;
}

const restoreByOneDrive = (veodSession: VeodSession, oneDriveId: OneDriveId, items: OneDriveItem[], options: OneDriveRestoreOptions): Observable<RestoreSessionId | undefined> =>
    oneDriveDocumentApi.oneDriveDocumentOperatorRestore({
        oneDriveId,
        restoreSessionId: veodSession,
        options: {
            documentsRestoreConfigs: createDocumentsRestoreConfigs(items),
            documentAction: options.restoreType,
            reason: options.reason,
        },
    }).pipe(
        map(response => response.map(undefined)?.sessionId as RestoreSessionId),
        filter((response): response is RestoreSessionId => !!response),
        delayWhen(sessionId => of(Promise.all(items.map(async item => await(await getDatabase()).recoveryList.setSessionId(item.uniqId, sessionId)))))
    );

const createDocumentsRestoreConfigs = (documents: OneDriveItem[]): RESTRestoreOneDriveDocumentConfig[] => documents.map(document =>
    ({
        document: document._raw_rest as RESTOneDriveDocument,
        documentVersion: RESTRestoreOneDriveDocumentConfigDocumentVersionEnum.Last,
    })
);

export const restoreOneDrive = (veodSession: VeodSession, items: OneDriveItem[], options: OneDriveRestoreOptions): Observable<RestoreSessionId[]> => {
    const groups = groupBy(items, item => item.oneDriveId);
    const requests = groups.map(([oneDriveId, items]) => restoreByOneDrive(veodSession, oneDriveId, items, options));
    return forkJoin(requests).pipe(map(responses => responses.filter((xx): xx is RestoreSessionId => !!xx)));
};
