/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTJobSessionObject,
    RESTExceptionInfo,
} from '../models';

export interface JobSessionObjectsApiJobSessionGetObjectsBySessionIdRequest {
    sessionId: string;
    offset: number;
    limit: number;
}

/**
 * no description
 */
export const jobSessionObjectsApi = ({

    /**
     * Returns a collection of objects processed by a job session with the specified ID.
     * Get Processed Objects
     */
    jobSessionGetObjectsBySessionId: ({ sessionId, offset, limit }: JobSessionObjectsApiJobSessionGetObjectsBySessionIdRequest, option?: RequestOption): Observable<Optional<PageOfRESTJobSessionObject>> => {
        throwIfNullOrUndefined(sessionId, 'sessionId', 'jobSessionGetObjectsBySessionId');
        throwIfNullOrUndefined(offset, 'offset', 'jobSessionGetObjectsBySessionId');
        throwIfNullOrUndefined(limit, 'limit', 'jobSessionGetObjectsBySessionId');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTJobSessionObject>({
            url: '/v8/JobSessions/{sessionId}/ProcessedObjects'.replace('{sessionId}', encodeURI(sessionId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

})
