// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTUserProtectionOptions
 */
export interface RESTUserProtectionOptions {
    /**
     * Specifies the ID of an organization for which the report on protected and unprotected users will be generated.
     * @type {string}
     * @memberof RESTUserProtectionOptions
     */
    organizationId?: string | null;
    /**
     * Specifies the format in which to save a report.
     * @type {string}
     * @memberof RESTUserProtectionOptions
     */
    format?: RESTUserProtectionOptionsFormatEnum;
    /**
     * Specifies a time zone for the reporting interval. If you do not specify this property, the server will generate a report for the UTC time zone.
     * @type {string}
     * @memberof RESTUserProtectionOptions
     */
    timezone?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTUserProtectionOptionsFormatEnum {
    Pdf = 'PDF',
    Csv = 'CSV'
}

