// Copyright © Veeam Software Group GmbH

import { DataGridModel } from '@veeam/components';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import type { Observable, Subscription } from 'rxjs';
import type React from 'react';
import type { UniqueId } from 'services';
import type { TeamsPostItem } from 'services/models/Teams/TeamsItem';

import { commonLoaderOrDefault, createConverter } from 'infrastructure/grid';
import { teamsApi } from 'services/explore/products/teams/wrapped-teams-api';
import { Batch, loadPages, LoadPagesMode } from 'infrastructure/rxjs';
import { exploreSessionService } from 'services/exploreSessions';
import { convertPostItemFromRest } from 'services/explore/products/teams/converters';
import { SubheaderType } from '../ExploreGrid/enums/subheader-type';
import { EXPLORE_PAGE_LIMIT } from '../ExploreGrid/consts/explore-page-limit';
import { EXPLORE_MAX_LIMIT } from '../ExploreGrid/consts/explore-max-limit';
import { ExploreItemsFilterSchema } from '../../../../services/explore';
import { ServiceMemoryCache } from 'infrastructure/serviceCache';
import { sortExploreItems } from '../../../../services/explore/getItems';

import type { ExploreSortKeys } from '../../../../services/explore/enums/explore-sort-keys';
import type { Extra } from './extra';

const getReplies = (request: Extra): Observable<Batch<TeamsPostItem>> => {
    if (request.config?.mode === LoadPagesMode.Async) {
        return getRepliesItems(request);
    }

    return getRepliesCached.getValue(request)
        .pipe(
            map((batch) => {
                const clonedItems = cloneDeep(batch.data);

                const data = sortExploreItems(request.sorting, clonedItems) as TeamsPostItem[];

                return { ...batch, data };
            })
        );
};

const getRepliesItems = (request: Extra): Observable<Batch<TeamsPostItem>> => {
    const { teamId, parent, channelId, filter, config } = request;

    const { vetSession } = exploreSessionService.getSessions();

    if (!vetSession) {
        return of(Batch.empty());
    }

    if (filter?.search) {
        return loadPages(teamsApi.searchPostItems, config)({
            restoreSessionId: vetSession,
            teamId,
            parentId: parent.postId,
            channelId,
            action: {
                query: filter.search,
            },
        }).pipe(
            map(posts => posts.mapData(item => convertPostItemFromRest(item, parent as any, true))),
        );
    }

    return loadPages(teamsApi.getPostItems, config)({
        restoreSessionId: vetSession,
        teamId,
        parentId: parent.postId,
        channelId,
    }).pipe(
        map(posts => posts.mapData(item => convertPostItemFromRest(item, parent as any, true))),
    );
};

const isSortingChanged = (
    prevParams: Extra | undefined,
    nextParams: Extra | undefined
): boolean => isEqual(prevParams?.filter, nextParams?.filter);

export const getRepliesCached = new ServiceMemoryCache(getRepliesItems, isSortingChanged);

export class RepliesGridModel extends DataGridModel<TeamsPostItem, UniqueId, ExploreSortKeys, Extra> {
    private subscriptions: Subscription[];
    private asyncLoading: Subscription | undefined;

    public constructor(
        extra: Extra,
        private setAsyncLoading: React.Dispatch<React.SetStateAction<boolean>>,
        private setSubheaderType: React.Dispatch<React.SetStateAction<SubheaderType>>
    ) {
        super(RepliesGridModel.idGetter, RepliesGridModel.versionsLoader, extra);

        this.unselectAll = this.unselectAll.bind(this);
        this.getItem = this.getItem.bind(this);

        this.subscriptions = [
            this.asObservable().subscribe(({ totalCount, limit, loadState, filters }) => {
                if (!limit || !totalCount) return;

                if (loadState?.hasNextBatch) {
                    const searchFilterValue = filters.getFilter('search')?.value;

                    this.setSubheaderType(searchFilterValue ? SubheaderType.SearchIsUsed : SubheaderType.LargeNumbersOfItems);
                }
            }),

            this.fieldChanges('filters').subscribe(() => getRepliesCached.clearValue()),
        ];
    }

    unselectAll(): void  {
        this.update((mutable) => {
            mutable.selection = [];
        });
    }

    getItem(): TeamsPostItem[] {
        const selection = this.getValue().selection;

        if (selection.length !== 1) return [];

        const selected = this.getItemById(selection[0]);

        if (!selected) return [];

        return [selected];
    }

    getItems(): TeamsPostItem[] {
        return this.getValue()
            .selection
            .map(id => this.getItemById(id));
    }

    private static idGetter(data: TeamsPostItem): UniqueId {
        return data.uniqId;
    }

    private static versionsLoader: RepliesGridModel['loaderType'] = commonLoaderOrDefault(
        value => ({
            parent: value.parent,
            teamId: value.teamId,
            channelId: value.channelId,
            filter: createConverter(ExploreItemsFilterSchema)(value.filters),
            sorting: value.sorting[0],
            config: {
                mode: value.limit === undefined ? LoadPagesMode.Async : LoadPagesMode.Sync,
                stopLimit: value.limit,
                prevState: value.loadState,
                pageSize: EXPLORE_PAGE_LIMIT,
                maxLimit: EXPLORE_MAX_LIMIT,
            },
        }),
        getReplies,
        []
    );

    public cancelAsyncLoading = () => {
        if (this.asyncLoading && !this.asyncLoading.closed) {
            this.asyncLoading.unsubscribe();
        }

        this.setAsyncLoading(false);
    };

    public load = async(): Promise<void> => {
        this.update((mutable) => {
            mutable.limit = EXPLORE_PAGE_LIMIT;
            mutable.loadState = undefined;
            mutable.totalCount = 0;
        });

        this.cancelAsyncLoading();

        this.setSubheaderType(SubheaderType.Default);

        return super.load();
    };

    public loadAllItems(): void {
        this.update((mutable) => {
            mutable.limit = undefined;
            mutable.selection = [];
        });

        this.setAsyncLoading(true);

        let batchedItems: TeamsPostItem[] = [];

        this.asyncLoading = this.loader(this.getValue()).subscribe({
            next: ({ data: batch }) => {
                this.update((mutable) => {
                    batchedItems = [...batchedItems, ...batch.items];
                    mutable.loadState = batch.loadState;
                });
            },
            complete: () => {
                this.update((mutable) => {
                    const items = [...mutable.items, ...batchedItems];
                    mutable.items = sortExploreItems(mutable.sorting[0], items) as TeamsPostItem[];
                    mutable.totalCount = mutable.items.length;
                    getRepliesCached.setValue(Batch.from(mutable.items));
                });

                if (this.getValue().loadState?.totalCount === EXPLORE_MAX_LIMIT && this.getValue().loadState?.hasNextBatch) {
                    this.setSubheaderType(SubheaderType.LimitIsReached);
                } else {
                    this.setSubheaderType(SubheaderType.Default);
                }

                this.cancelAsyncLoading();
            },
            error: () => {
                batchedItems = [];

                this.cancelAsyncLoading();
            },
        });
    }

    public destroy(): void {
        super.destroy();

        this.setSubheaderType(SubheaderType.Default);

        this.cancelAsyncLoading();

        this.subscriptions.filter(sub => !sub.closed).forEach(sub => sub.unsubscribe());

        getRepliesCached.clearValue();
    }
}
