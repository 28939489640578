// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTOneDrive,
    RESTSharePointCommonRestoreStatistics,
} from './';

/**
 * @export
 * @interface RESTExtendedCommonStatistic
 */
export interface RESTExtendedCommonStatistic {
    /**
     * @type {RESTOneDrive}
     * @memberof RESTExtendedCommonStatistic
     */
    oneDrive?: RESTOneDrive;
    /**
     * Status of the restore operation.
     * @type {string}
     * @memberof RESTExtendedCommonStatistic
     */
    status?: RESTExtendedCommonStatisticStatusEnum;
    /**
     * Error occurred during the restore operation.
     * @type {string}
     * @memberof RESTExtendedCommonStatistic
     */
    error?: string;
    /**
     * @type {RESTSharePointCommonRestoreStatistics}
     * @memberof RESTExtendedCommonStatistic
     */
    details?: RESTSharePointCommonRestoreStatistics;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTExtendedCommonStatisticStatusEnum {
    Failed = 'Failed',
    Warning = 'Warning',
    Success = 'Success'
}

