/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTItemComposed,
    PageOfRESTSharePointDocument,
    RESTAsyncRestoreResponse,
    RESTExceptionInfo,
    RESTOperatorRestoreSessionResponse,
    RESTOperatorRestoreSharePointDocumentsConfig,
    RESTRestoreDocumentsConfig,
    RESTRestoreItemConfig,
    RESTRestoreToDocumentsConfig,
    RESTRestoreToItemConfig,
    RESTRestoreToVersionConfig,
    RESTRestoreVersionConfig,
    RESTSaveSharePointDocumentOptions,
    RESTSendAsMsgOptionsV6,
    RESTSharePointDocument,
    RESTSharePointSaveDocumentsOptions,
    RESTSharePointSendDocumentsAsMsgOptions,
} from '../models';

export interface SharePointDocumentApiSharePointDocumentGetRequest {
    restoreSessionId: string;
    siteId: string;
    offset: number;
    limit: number;
    parentId?: string;
    includeFolders?: boolean;
}

export interface SharePointDocumentApiSharePointDocumentGetByIdRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
}

export interface SharePointDocumentApiSharePointDocumentGetByIdByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    versionId: number;
}

export interface SharePointDocumentApiSharePointDocumentGetVersionsRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    offset: number;
    limit: number;
}

export interface SharePointDocumentApiSharePointDocumentOperatorRestoreRequest {
    restoreSessionId: string;
    siteId: string;
    config: RESTOperatorRestoreSharePointDocumentsConfig;
}

export interface SharePointDocumentApiSharePointDocumentRestoreDocumentActionRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    restRestoreConfig: RESTRestoreItemConfig;
}

export interface SharePointDocumentApiSharePointDocumentRestoreDocumentActionByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    versionId: number;
    restRestoreConfig: RESTRestoreVersionConfig;
}

export interface SharePointDocumentApiSharePointDocumentRestoreDocumentActionByVersionIdToRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    versionId: number;
    restRestoreConfig: RESTRestoreToVersionConfig;
}

export interface SharePointDocumentApiSharePointDocumentRestoreDocumentActionToRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    restRestoreConfig: RESTRestoreToItemConfig;
}

export interface SharePointDocumentApiSharePointDocumentRestoreDocumentsActionRequest {
    restoreSessionId: string;
    siteId: string;
    restRestoreConfig: RESTRestoreDocumentsConfig;
}

export interface SharePointDocumentApiSharePointDocumentRestoreDocumentsActionToRequest {
    restoreSessionId: string;
    siteId: string;
    restRestoreConfig: RESTRestoreToDocumentsConfig;
}

export interface SharePointDocumentApiSharePointDocumentSaveDocumentActionRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    options: RESTSaveSharePointDocumentOptions;
}

export interface SharePointDocumentApiSharePointDocumentSaveDocumentActionByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    versionId: number;
    options: RESTSaveSharePointDocumentOptions;
}

export interface SharePointDocumentApiSharePointDocumentSaveDocumentsActionRequest {
    restoreSessionId: string;
    siteId: string;
    saveOptions: RESTSharePointSaveDocumentsOptions;
}

export interface SharePointDocumentApiSharePointDocumentSendDocumentActionRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    options: RESTSendAsMsgOptionsV6;
}

export interface SharePointDocumentApiSharePointDocumentSendDocumentActionByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    documentId: string;
    versionId: number;
    options: RESTSendAsMsgOptionsV6;
}

export interface SharePointDocumentApiSharePointDocumentSendDocumentsActionRequest {
    restoreSessionId: string;
    siteId: string;
    options: RESTSharePointSendDocumentsAsMsgOptions;
}

/**
 * no description
 */
export const sharePointDocumentApi = ({

    /**
     * Returns a collection of backed-up SharePoint documents to explore and restore.
     * Get SharePoint Documents
     */
    sharePointDocumentGet: ({ restoreSessionId, siteId, offset, limit, parentId, includeFolders }: SharePointDocumentApiSharePointDocumentGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentGet');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentGet');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointDocumentGet');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointDocumentGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (parentId != null) { query['parentId'] = parentId; }
        if (includeFolders != null) { query['includeFolders'] = includeFolders; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up SharePoint document with the specified ID.
     * Get SharePoint Document
     */
    sharePointDocumentGetById: ({ restoreSessionId, siteId, documentId }: SharePointDocumentApiSharePointDocumentGetByIdRequest, option?: RequestOption): Observable<Optional<RESTSharePointDocument>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentGetById');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentGetById');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentGetById');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTSharePointDocument>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a resource representation of a specific version of a backed-up SharePoint document with the specified ID.
     * Get Specific Version of SharePoint Document
     */
    sharePointDocumentGetByIdByVersionId: ({ restoreSessionId, siteId, documentId, versionId }: SharePointDocumentApiSharePointDocumentGetByIdByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTSharePointDocument>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentGetByIdByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentGetByIdByVersionId');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentGetByIdByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointDocumentGetByIdByVersionId');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTSharePointDocument>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/Versions/{versionId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Returns a collection of versions of a backed-up SharePoint document with the specified ID.  When you get SharePoint document versions, the server returns information about previous versions of the document. To get the latest version, use either [Get SharePoint Document](#tag/SharePointDocument/operation/SharePointDocument_GetById) or [Get Specific Version of SharePoint Document](#tag/SharePointDocument/operation/SharePointDocument_GetByIdByVersionId). 
     * Get Previous Versions of SharePoint Document
     */
    sharePointDocumentGetVersions: ({ restoreSessionId, siteId, documentId, offset, limit }: SharePointDocumentApiSharePointDocumentGetVersionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTSharePointDocument>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentGetVersions');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentGetVersions');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentGetVersions');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointDocumentGetVersions');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointDocumentGetVersions');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTSharePointDocument>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/Versions'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Restores backed-up SharePoint documents using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=80) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong> </br> To restore data using Restore Portal, you must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div> 
     * Restore SharePoint Documents by Restore Operator
     */
    sharePointDocumentOperatorRestore: ({ restoreSessionId, siteId, config }: SharePointDocumentApiSharePointDocumentOperatorRestoreRequest, option?: RequestOption): Observable<Optional<RESTOperatorRestoreSessionResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentOperatorRestore');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentOperatorRestore');
        throwIfNullOrUndefined(config, 'config', 'sharePointDocumentOperatorRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTOperatorRestoreSessionResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: config,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint document with the specified ID to the original location.
     * Restore SharePoint Document to Original Location
     */
    sharePointDocumentRestoreDocumentAction: ({ restoreSessionId, siteId, documentId, restRestoreConfig }: SharePointDocumentApiSharePointDocumentRestoreDocumentActionRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentRestoreDocumentAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentRestoreDocumentAction');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentRestoreDocumentAction');
        throwIfNullOrUndefined(restRestoreConfig, 'restRestoreConfig', 'sharePointDocumentRestoreDocumentAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)),
            method: 'POST',
            headers,
            body: restRestoreConfig,
        }, option);
    },

    /**
     * Restores a specific version of a backed-up SharePoint document to the original location.
     * Restore Version of SharePoint Document to Original Location
     */
    sharePointDocumentRestoreDocumentActionByVersionId: ({ restoreSessionId, siteId, documentId, versionId, restRestoreConfig }: SharePointDocumentApiSharePointDocumentRestoreDocumentActionByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentRestoreDocumentActionByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentRestoreDocumentActionByVersionId');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentRestoreDocumentActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointDocumentRestoreDocumentActionByVersionId');
        throwIfNullOrUndefined(restRestoreConfig, 'restRestoreConfig', 'sharePointDocumentRestoreDocumentActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/Versions/{versionId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: restRestoreConfig,
        }, option);
    },

    /**
     * Restores a specific version of a backed-up SharePoint document to another location.
     * Restore Version of SharePoint Document to Another Location
     */
    sharePointDocumentRestoreDocumentActionByVersionIdTo: ({ restoreSessionId, siteId, documentId, versionId, restRestoreConfig }: SharePointDocumentApiSharePointDocumentRestoreDocumentActionByVersionIdToRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentRestoreDocumentActionByVersionIdTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentRestoreDocumentActionByVersionIdTo');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentRestoreDocumentActionByVersionIdTo');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointDocumentRestoreDocumentActionByVersionIdTo');
        throwIfNullOrUndefined(restRestoreConfig, 'restRestoreConfig', 'sharePointDocumentRestoreDocumentActionByVersionIdTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/Versions/{versionId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: restRestoreConfig,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint document with the specified ID to another location.
     * Restore SharePoint Document to Another Location
     */
    sharePointDocumentRestoreDocumentActionTo: ({ restoreSessionId, siteId, documentId, restRestoreConfig }: SharePointDocumentApiSharePointDocumentRestoreDocumentActionToRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentRestoreDocumentActionTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentRestoreDocumentActionTo');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentRestoreDocumentActionTo');
        throwIfNullOrUndefined(restRestoreConfig, 'restRestoreConfig', 'sharePointDocumentRestoreDocumentActionTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)),
            method: 'POST',
            headers,
            body: restRestoreConfig,
        }, option);
    },

    /**
     * Restores backed-up SharePoint documents to the original location.
     * Restore SharePoint Documents to Original Location
     */
    sharePointDocumentRestoreDocumentsAction: ({ restoreSessionId, siteId, restRestoreConfig }: SharePointDocumentApiSharePointDocumentRestoreDocumentsActionRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentRestoreDocumentsAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentRestoreDocumentsAction');
        throwIfNullOrUndefined(restRestoreConfig, 'restRestoreConfig', 'sharePointDocumentRestoreDocumentsAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: restRestoreConfig,
        }, option);
    },

    /**
     * Restores backed-up SharePoint documents to another location.
     * Restore SharePoint Documents to Another Location
     */
    sharePointDocumentRestoreDocumentsActionTo: ({ restoreSessionId, siteId, restRestoreConfig }: SharePointDocumentApiSharePointDocumentRestoreDocumentsActionToRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentRestoreDocumentsActionTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentRestoreDocumentsActionTo');
        throwIfNullOrUndefined(restRestoreConfig, 'restRestoreConfig', 'sharePointDocumentRestoreDocumentsActionTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: restRestoreConfig,
        }, option);
    },

    /**
     * Saves a backed-up SharePoint document with the specified ID.  When you save a document, the request command places the document to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the document is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save SharePoint Document
     */
    sharePointDocumentSaveDocumentAction: ({ restoreSessionId, siteId, documentId, options }: SharePointDocumentApiSharePointDocumentSaveDocumentActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentSaveDocumentAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentSaveDocumentAction');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentSaveDocumentAction');
        throwIfNullOrUndefined(options, 'options', 'sharePointDocumentSaveDocumentAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)),
            method: 'POST',
            headers,
            body: options,
        }, option);
    },

    /**
     * Saves a specific version of a backed-up SharePoint document with the specified ID. When you save a document, the request command places the document to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the document is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save Version of SharePoint Document
     */
    sharePointDocumentSaveDocumentActionByVersionId: ({ restoreSessionId, siteId, documentId, versionId, options }: SharePointDocumentApiSharePointDocumentSaveDocumentActionByVersionIdRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentSaveDocumentActionByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentSaveDocumentActionByVersionId');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentSaveDocumentActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointDocumentSaveDocumentActionByVersionId');
        throwIfNullOrUndefined(options, 'options', 'sharePointDocumentSaveDocumentActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/Versions/{versionId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: options,
        }, option);
    },

    /**
     * Saves backed-up SharePoint documents.  SharePoint documents are always saved in a ZIP archive. When you save documents, the request command archives the documents and places the ZIP archive to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save SharePoint Documents
     */
    sharePointDocumentSaveDocumentsAction: ({ restoreSessionId, siteId, saveOptions }: SharePointDocumentApiSharePointDocumentSaveDocumentsActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentSaveDocumentsAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentSaveDocumentsAction');
        throwIfNullOrUndefined(saveOptions, 'saveOptions', 'sharePointDocumentSaveDocumentsAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: saveOptions,
        }, option);
    },

    /**
     * Sends a backed-up SharePoint document with the specified ID as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong> </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft SharePoint email settings. For more information, see [Edit Email Settings](#tag/VespEmailSettings/operation/VespEmailSettings_Update). </div> 
     * Send SharePoint Document
     */
    sharePointDocumentSendDocumentAction: ({ restoreSessionId, siteId, documentId, options }: SharePointDocumentApiSharePointDocumentSendDocumentActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentSendDocumentAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentSendDocumentAction');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentSendDocumentAction');
        throwIfNullOrUndefined(options, 'options', 'sharePointDocumentSendDocumentAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)),
            method: 'POST',
            headers,
            body: options,
        }, option);
    },

    /**
     * Sends a specific version of a backed-up SharePoint document as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong> </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft SharePoint email settings. For more information, see [Edit Email Settings](#tag/VespEmailSettings/operation/VespEmailSettings_Update). </div> 
     * Send Version of SharePoint Document
     */
    sharePointDocumentSendDocumentActionByVersionId: ({ restoreSessionId, siteId, documentId, versionId, options }: SharePointDocumentApiSharePointDocumentSendDocumentActionByVersionIdRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentSendDocumentActionByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentSendDocumentActionByVersionId');
        throwIfNullOrUndefined(documentId, 'documentId', 'sharePointDocumentSendDocumentActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointDocumentSendDocumentActionByVersionId');
        throwIfNullOrUndefined(options, 'options', 'sharePointDocumentSendDocumentActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/{documentId}/Versions/{versionId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: options,
        }, option);
    },

    /**
     * Sends backed-up SharePoint documents as attachments in an email message. <div class=\"note\"><strong>NOTE</strong> </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft SharePoint email settings. For more information, see [Edit Email Settings](#tag/VespEmailSettings/operation/VespEmailSettings_Update). </div> 
     * Send SharePoint Documents
     */
    sharePointDocumentSendDocumentsAction: ({ restoreSessionId, siteId, options }: SharePointDocumentApiSharePointDocumentSendDocumentsActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointDocumentSendDocumentsAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointDocumentSendDocumentsAction');
        throwIfNullOrUndefined(options, 'options', 'sharePointDocumentSendDocumentsAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Documents/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: options,
        }, option);
    },

})
