/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTExchangeItemsComposed,
    RESTAsyncRestoreResponse,
    RESTExceptionInfo,
    RESTExchangeItemsComposed,
    RESTExportToPstOptions,
    RESTOperatorRestoreOptions,
    RESTOperatorRestoreResponse,
    RESTRestoreToDifferentLocation,
    RESTRestoreToDifferentLocationSelectedItems,
    RESTRestoreToOriginalLocation,
    RESTRestoreToOriginalLocationSelectedItems,
    RESTSaveToMsgOptions,
    RESTSendAsMsgOptions,
    RESTSendToDefaultAddressOptions,
    RESTSendToDifferentAddressOptions,
    RESTSeveralItemsExportOptions,
} from '../models';

export interface ExchangeItemApiExchangeItemExportItemToPstActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    itemId: string;
    options: RESTExportToPstOptions;
}

export interface ExchangeItemApiExchangeItemExportItemsToPstActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    options: RESTSeveralItemsExportOptions;
}

export interface ExchangeItemApiExchangeItemGetRequest {
    restoreSessionId: string;
    mailboxId: string;
    offset: number;
    limit: number;
    parentId?: string;
}

export interface ExchangeItemApiExchangeItemGetByIdRequest {
    restoreSessionId: string;
    mailboxId: string;
    itemId: string;
}

export interface ExchangeItemApiExchangeItemOperatorRestoreRequest {
    restoreSessionId: string;
    mailboxId: string;
    options: RESTOperatorRestoreOptions;
}

export interface ExchangeItemApiExchangeItemRestoreItemActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    itemId: string;
    restoreOptions: RESTRestoreToOriginalLocation;
}

export interface ExchangeItemApiExchangeItemRestoreItemToActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    itemId: string;
    restoreOptions: RESTRestoreToDifferentLocation;
}

export interface ExchangeItemApiExchangeItemRestoreItemsActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    restoreOptions: RESTRestoreToOriginalLocationSelectedItems;
}

export interface ExchangeItemApiExchangeItemRestoreItemsToActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    restoreOptions: RESTRestoreToDifferentLocationSelectedItems;
}

export interface ExchangeItemApiExchangeItemSaveItemActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    itemId: string;
}

export interface ExchangeItemApiExchangeItemSaveItemsRequest {
    restoreSessionId: string;
    mailboxId: string;
    saveToMsgOptions: RESTSaveToMsgOptions;
}

export interface ExchangeItemApiExchangeItemSendItemToDefaultAddressActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    itemId: string;
}

export interface ExchangeItemApiExchangeItemSendItemToDifferentAddressActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    itemId: string;
    sendToMsgOptions: RESTSendAsMsgOptions;
}

export interface ExchangeItemApiExchangeItemSendItemsToDefaultAddressActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    sendOptions: RESTSendToDefaultAddressOptions;
}

export interface ExchangeItemApiExchangeItemSendItemsToDifferentAddressActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    sendOptions: RESTSendToDifferentAddressOptions;
}

/**
 * no description
 */
export const exchangeItemApi = ({

    /**
     * Exports a backed-up item with the specified ID to a PST file. <div class=\"important\"><strong>IMPORTANT</strong> </br> To export data to PST (Personal Storage Table) files, you must have a 64-bit version of Microsoft Outlook 2016, Microsoft Outlook 2013 or Microsoft Outlook 2010 installed on a computer running restore sessions. </div> The request command will export a backed-up item to a PST file and place it to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the PST file will be transferred as application/octet-stream media to the client. To download, read, convert to PST or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Export Mailbox Item
     */
    exchangeItemExportItemToPstAction: ({ restoreSessionId, mailboxId, itemId, options }: ExchangeItemApiExchangeItemExportItemToPstActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemExportItemToPstAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemExportItemToPstAction');
        throwIfNullOrUndefined(itemId, 'itemId', 'exchangeItemExportItemToPstAction');
        throwIfNullOrUndefined(options, 'options', 'exchangeItemExportItemToPstAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/{itemId}/exportToPst'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{itemId}', encodeURI(itemId)),
            method: 'POST',
            headers,
            body: options,
        }, option);
    },

    /**
     * Exports backed-up items to a PST file. <div class=\"important\"><strong>IMPORTANT</strong> </br> To export data to PST (Personal Storage Table) files, you must have a 64-bit version of Microsoft Outlook 2016, Microsoft Outlook 2013 or Microsoft Outlook 2010 installed on a computer running restore sessions. </div> The backed-up data will be exported to a PST file and placed to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the PST file will be transferred as application/octet-stream media to the client. To download, read, convert to PST or perform other actions with the octet-stream, use features of programming languages.  If the size of the exported data exceeds the limit specified for the PST file, Veeam Backup for Microsoft 365 splits the PST file into separate files and exports these files to an archive file of the ZIP format. For example, you specified 1 GB as the PST file size limit. The size of the exported data is 1.5 GB. In this case, Veeam Backup for Microsoft 365 will export data to a ZIP archive. The archive will contain two PST files: one PST file of the 1 GB size and another PST file of the 0.5 GB size.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Export Mailbox Items
     */
    exchangeItemExportItemsToPstAction: ({ restoreSessionId, mailboxId, options }: ExchangeItemApiExchangeItemExportItemsToPstActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemExportItemsToPstAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemExportItemsToPstAction');
        throwIfNullOrUndefined(options, 'options', 'exchangeItemExportItemsToPstAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/exportToPst'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: options,
        }, option);
    },

    /**
     * Returns a collection of organization mailbox items to explore and restore.
     * Get Mailbox Items
     */
    exchangeItemGet: ({ restoreSessionId, mailboxId, offset, limit, parentId }: ExchangeItemApiExchangeItemGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTExchangeItemsComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemGet');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemGet');
        throwIfNullOrUndefined(offset, 'offset', 'exchangeItemGet');
        throwIfNullOrUndefined(limit, 'limit', 'exchangeItemGet');

        const headers: HttpHeaders = {
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (parentId != null) { query['parentId'] = parentId; }

        return requestAuthenticated<PageOfRESTExchangeItemsComposed>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'GET',
            headers,
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up mailbox item with the specified ID.
     * Get Mailbox Item
     */
    exchangeItemGetById: ({ restoreSessionId, mailboxId, itemId }: ExchangeItemApiExchangeItemGetByIdRequest, option?: RequestOption): Observable<Optional<RESTExchangeItemsComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemGetById');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemGetById');
        throwIfNullOrUndefined(itemId, 'itemId', 'exchangeItemGetById');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<RESTExchangeItemsComposed>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/{itemId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{itemId}', encodeURI(itemId)),
            method: 'GET',
            headers,
        }, option);
    },

    /**
     * Restores backed-up mailbox items using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=80) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong> </br> You must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div> 
     * Restore Mailbox Items by Restore Operator
     */
    exchangeItemOperatorRestore: ({ restoreSessionId, mailboxId, options }: ExchangeItemApiExchangeItemOperatorRestoreRequest, option?: RequestOption): Observable<Optional<RESTOperatorRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemOperatorRestore');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemOperatorRestore');
        throwIfNullOrUndefined(options, 'options', 'exchangeItemOperatorRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTOperatorRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: options,
        }, option);
    },

    /**
     * Restores a backed-up item with the specified ID to the original mailbox.
     * Restore Mailbox Item to Original Mailbox
     */
    exchangeItemRestoreItemAction: ({ restoreSessionId, mailboxId, itemId, restoreOptions }: ExchangeItemApiExchangeItemRestoreItemActionRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemRestoreItemAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemRestoreItemAction');
        throwIfNullOrUndefined(itemId, 'itemId', 'exchangeItemRestoreItemAction');
        throwIfNullOrUndefined(restoreOptions, 'restoreOptions', 'exchangeItemRestoreItemAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/{itemId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{itemId}', encodeURI(itemId)),
            method: 'POST',
            headers,
            body: restoreOptions,
        }, option);
    },

    /**
     * Restores a backed-up item with the specified ID to another mailbox.
     * Restore Mailbox Item to Another Mailbox
     */
    exchangeItemRestoreItemToAction: ({ restoreSessionId, mailboxId, itemId, restoreOptions }: ExchangeItemApiExchangeItemRestoreItemToActionRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemRestoreItemToAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemRestoreItemToAction');
        throwIfNullOrUndefined(itemId, 'itemId', 'exchangeItemRestoreItemToAction');
        throwIfNullOrUndefined(restoreOptions, 'restoreOptions', 'exchangeItemRestoreItemToAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/{itemId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{itemId}', encodeURI(itemId)),
            method: 'POST',
            headers,
            body: restoreOptions,
        }, option);
    },

    /**
     * Restores backed-up items to the original mailbox.
     * Restore Mailbox Items to Original Mailbox
     */
    exchangeItemRestoreItemsAction: ({ restoreSessionId, mailboxId, restoreOptions }: ExchangeItemApiExchangeItemRestoreItemsActionRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemRestoreItemsAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemRestoreItemsAction');
        throwIfNullOrUndefined(restoreOptions, 'restoreOptions', 'exchangeItemRestoreItemsAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: restoreOptions,
        }, option);
    },

    /**
     * Restores backed-up items to another mailbox.
     * Restore Mailbox Items to Another Mailbox
     */
    exchangeItemRestoreItemsToAction: ({ restoreSessionId, mailboxId, restoreOptions }: ExchangeItemApiExchangeItemRestoreItemsToActionRequest, option?: RequestOption): Observable<Optional<RESTAsyncRestoreResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemRestoreItemsToAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemRestoreItemsToAction');
        throwIfNullOrUndefined(restoreOptions, 'restoreOptions', 'exchangeItemRestoreItemsToAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTAsyncRestoreResponse>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: restoreOptions,
        }, option);
    },

    /**
     * Saves a backed-up item with the specified ID to a file in the MSG format (Outlook Message File).  When you save a backed-up item, the request command saves the item to a file in the MSG format and transfers it to application/octet-stream media.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save Mailbox Item
     */
    exchangeItemSaveItemAction: ({ restoreSessionId, mailboxId, itemId }: ExchangeItemApiExchangeItemSaveItemActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemSaveItemAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemSaveItemAction');
        throwIfNullOrUndefined(itemId, 'itemId', 'exchangeItemSaveItemAction');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/{itemId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{itemId}', encodeURI(itemId)),
            method: 'POST',
            headers,
        }, option);
    },

    /**
     * Saves backed-up items to MSG files (Outlook Message Files).  When you save backed-up items, the request command saves the items to MSG files and transfers them to application/octet-stream media.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download files from the temporary folder. 
     * Save Mailbox Items
     */
    exchangeItemSaveItems: ({ restoreSessionId, mailboxId, saveToMsgOptions }: ExchangeItemApiExchangeItemSaveItemsRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemSaveItems');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemSaveItems');
        throwIfNullOrUndefined(saveToMsgOptions, 'saveToMsgOptions', 'exchangeItemSaveItems');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/Save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: saveToMsgOptions,
        }, option);
    },

    /**
     * Sends a backed-up item with the specified ID as an attachment in an email message to the original mailbox. <div class=\"note\"><strong>NOTE</strong> </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft Exchange email settings. For more information, see [Edit Email Settings](#tag/VexEmailSettings/operation/VexEmailSettings_Update). </div> 
     * Send Mailbox Item to Original Mailbox
     */
    exchangeItemSendItemToDefaultAddressAction: ({ restoreSessionId, mailboxId, itemId }: ExchangeItemApiExchangeItemSendItemToDefaultAddressActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemSendItemToDefaultAddressAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemSendItemToDefaultAddressAction');
        throwIfNullOrUndefined(itemId, 'itemId', 'exchangeItemSendItemToDefaultAddressAction');

        const headers: HttpHeaders = {
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/{itemId}/sendToDefaultAddress'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{itemId}', encodeURI(itemId)),
            method: 'POST',
            headers,
        }, option);
    },

    /**
     * Sends a backed-up mailbox item with the specified ID as an attachment in an email message to a specified email address. <div class=\"note\"><strong>NOTE</strong> </br> If you omit the `from` property, the default *from* email address specified in Veeam Explorer for Microsoft Exchange email settings will be used. For more information, see [Edit Email Settings](#tag/VexEmailSettings/operation/VexEmailSettings_Update). </div> 
     * Send Mailbox Item to Another Mailbox
     */
    exchangeItemSendItemToDifferentAddressAction: ({ restoreSessionId, mailboxId, itemId, sendToMsgOptions }: ExchangeItemApiExchangeItemSendItemToDifferentAddressActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemSendItemToDifferentAddressAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemSendItemToDifferentAddressAction');
        throwIfNullOrUndefined(itemId, 'itemId', 'exchangeItemSendItemToDifferentAddressAction');
        throwIfNullOrUndefined(sendToMsgOptions, 'sendToMsgOptions', 'exchangeItemSendItemToDifferentAddressAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/{itemId}/sendToDifferentAddress'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{itemId}', encodeURI(itemId)),
            method: 'POST',
            headers,
            body: sendToMsgOptions,
        }, option);
    },

    /**
     * Sends backed-up items as attachments in an email message to the original mailbox. <div class=\"note\"><strong>NOTE</strong> </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft Exchange email settings. For more information, see [Edit Email Settings](#tag/VexEmailSettings/operation/VexEmailSettings_Update). </div> 
     * Send Mailbox Items to Original Mailbox
     */
    exchangeItemSendItemsToDefaultAddressAction: ({ restoreSessionId, mailboxId, sendOptions }: ExchangeItemApiExchangeItemSendItemsToDefaultAddressActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemSendItemsToDefaultAddressAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemSendItemsToDefaultAddressAction');
        throwIfNullOrUndefined(sendOptions, 'sendOptions', 'exchangeItemSendItemsToDefaultAddressAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/sendToDefaultAddress'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: sendOptions,
        }, option);
    },

    /**
     * Sends backed-up items as attachments in an email message to a specified email address. <div class=\"note\"><strong>NOTE</strong> </br> If you omit the `from` property, the default *from* email address specified in Veeam Explorer for Microsoft Exchange email settings will be used. For more information, see [Edit Email Settings](#tag/VexEmailSettings/operation/VexEmailSettings_Update). </div> 
     * Send Mailbox Items to Another Mailbox
     */
    exchangeItemSendItemsToDifferentAddressAction: ({ restoreSessionId, mailboxId, sendOptions }: ExchangeItemApiExchangeItemSendItemsToDifferentAddressActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeItemSendItemsToDifferentAddressAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeItemSendItemsToDifferentAddressAction');
        throwIfNullOrUndefined(sendOptions, 'sendOptions', 'exchangeItemSendItemsToDifferentAddressAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v8/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/items/sendToDifferentAddress'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: sendOptions,
        }, option);
    },

})
