// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTJobItemComposed,
    RESTJobSchedulePolicy,
} from './';

/**
 * @export
 * @interface RESTCreateBackupJob
 */
export interface RESTCreateBackupJob {
    /**
     * Description of the backup job.
     * @type {string}
     * @memberof RESTCreateBackupJob
     */
    description?: string;
    /**
     * Type of the backup job.
     * @type {string}
     * @memberof RESTCreateBackupJob
     */
    backupType: RESTCreateBackupJobBackupTypeEnum;
    /**
     * Defines whether the job will run right after it is created.
     * @type {boolean}
     * @memberof RESTCreateBackupJob
     */
    runNow?: boolean | null;
    /**
     * Array of objects that you want to back up.
     * @type {Array<RESTJobItemComposed>}
     * @memberof RESTCreateBackupJob
     */
    selectedItems?: Array<RESTJobItemComposed>;
    /**
     * Array of objects that you want to exclude from a backup job scope.
     * @type {Array<RESTJobItemComposed>}
     * @memberof RESTCreateBackupJob
     */
    excludedItems?: Array<RESTJobItemComposed>;
    /**
     * @type {RESTJobSchedulePolicy}
     * @memberof RESTCreateBackupJob
     */
    schedulePolicy?: RESTJobSchedulePolicy;
    /**
     * ID of the Microsoft 365 organization.
     * @type {string}
     * @memberof RESTCreateBackupJob
     */
    organizationId: string;
    /**
     * Backup repository ID.
     * @type {string}
     * @memberof RESTCreateBackupJob
     */
    repositoryId: string;
    /**
     * Name of the backup job.
     * @type {string}
     * @memberof RESTCreateBackupJob
     */
    name?: string;
    /**
     * Defines whether the backup job is enabled.
     * @type {boolean}
     * @memberof RESTCreateBackupJob
     */
    isEnabled?: boolean | null;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTCreateBackupJobBackupTypeEnum {
    EntireOrganization = 'EntireOrganization',
    SelectedItems = 'SelectedItems'
}

