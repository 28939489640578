// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTJobSchedulePolicy,
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTJob
 */
export interface RESTJob {
    /**
     * Description of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    description?: string;
    /**
     * Type of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    backupType?: RESTJobBackupTypeEnum;
    /**
     * @type {RESTJobSchedulePolicy}
     * @memberof RESTJob
     */
    schedulePolicy?: RESTJobSchedulePolicy;
    /**
     * Backup job ID.
     * @type {string}
     * @memberof RESTJob
     */
    id?: string | null;
    /**
     * ID of the Microsoft 365 organization.
     * @type {string}
     * @memberof RESTJob
     */
    organizationId?: string | null;
    /**
     * Backup repository ID.
     * @type {string}
     * @memberof RESTJob
     */
    repositoryId?: string | null;
    /**
     * Name of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    name?: string;
    /**
     * Date and time of the last run of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    lastRun?: string | null;
    /**
     * Date and time of the next run of the backup job per schedule.
     * @type {string}
     * @memberof RESTJob
     */
    nextRun?: string | null;
    /**
     * Date and time of the last successful run of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    lastBackup?: string | null;
    /**
     * Defines whether the backup job is enabled.
     * @type {boolean}
     * @memberof RESTJob
     */
    isEnabled?: boolean | null;
    /**
     * Latest status of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    lastStatus?: RESTJobLastStatusEnum;
    /**
     * Version number that Veeam Backup for Microsoft 365 assigns if the backup job was modified.
     * @type {number}
     * @memberof RESTJob
     */
    eTag?: number;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTJob
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTJobBackupTypeEnum {
    EntireOrganization = 'EntireOrganization',
    SelectedItems = 'SelectedItems'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTJobLastStatusEnum {
    Stopped = 'Stopped',
    Running = 'Running',
    Success = 'Success',
    Failed = 'Failed',
    Warning = 'Warning',
    NotConfigured = 'NotConfigured',
    Disconnected = 'Disconnected',
    Queued = 'Queued'
}

