// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTStorageConsumptionOptions
 */
export interface RESTStorageConsumptionOptions {
    /**
     * Specifies date and time when the reporting interval starts.
     * @type {string}
     * @memberof RESTStorageConsumptionOptions
     */
    startTime?: string | null;
    /**
     * Specifies date and time when the reporting interval ends.
     * @type {string}
     * @memberof RESTStorageConsumptionOptions
     */
    endTime?: string | null;
    /**
     * Specifies the format in which to save a report.
     * @type {string}
     * @memberof RESTStorageConsumptionOptions
     */
    format?: RESTStorageConsumptionOptionsFormatEnum;
    /**
     * Specifies a time zone for the reporting interval. If you do not specify this property, the server will generate a report for the UTC time zone.
     * @type {string}
     * @memberof RESTStorageConsumptionOptions
     */
    timezone?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTStorageConsumptionOptionsFormatEnum {
    Pdf = 'PDF',
    Csv = 'CSV'
}

