// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTLogItem
 */
export interface RESTLogItem {
    /**
     * ID of the operation performed during the backup/backup copy/retrieval job.
     * @type {string}
     * @memberof RESTLogItem
     */
    id?: string;
    /**
     * Order number of the operation performed during the backup/backup copy/retrieval job.
     * @type {number}
     * @memberof RESTLogItem
     */
    usn?: number;
    /**
     * Type of the operation performed during the backup/backup copy/retrieval job.
     * @type {string}
     * @memberof RESTLogItem
     */
    type?: RESTLogItemTypeEnum;
    /**
     * Message of the operation performed during the backup/backup copy/retrieval job.
     * @type {string}
     * @memberof RESTLogItem
     */
    message?: string;
    /**
     * Date and time when the operation was started.
     * @type {string}
     * @memberof RESTLogItem
     */
    creationTime?: string;
    /**
     * Date and time when the operation ended.
     * @type {string}
     * @memberof RESTLogItem
     */
    endTime?: string;
    /**
     * Related resources.
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTLogItem
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTLogItemTypeEnum {
    Running = 'Running',
    Warning = 'Warning',
    Error = 'Error',
    Success = 'Success',
    Stop = 'Stop'
}

