// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTOneDriveFolder,
} from './';

/**
 * @export
 * @interface RESTRestoreToOriginalFolders
 */
export interface RESTRestoreToOriginalFolders {
    /**
     * Specifies IDs of the OneDrive folders that you want to restore. For more information on how to get such IDs, see [Get OneDrive Folders](#tag/OneDriveFolder/operation/OneDriveFolder_Get).
     * @type {Array<RESTOneDriveFolder>}
     * @memberof RESTRestoreToOriginalFolders
     */
    folders?: Array<RESTOneDriveFolder>;
    /**
     * Specifies the action that will be performed in case the restore destination contains the restored folder.
     * @type {string}
     * @memberof RESTRestoreToOriginalFolders
     */
    documentAction?: RESTRestoreToOriginalFoldersDocumentActionEnum;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreToOriginalFolders
     */
    userCode?: string;
    /**
     * Specifies the ID of the Micrisoft Entra application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreToOriginalFolders
     */
    applicationId?: string | null;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToOriginalFolders
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Microsoft Entra application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreToOriginalFolders
     */
    applicationCertificate?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTRestoreToOriginalFolders
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToOriginalFolders
     */
    userPassword?: string;
    /**
     * Specifies the region of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToOriginalFolders
     */
    officeRegion?: RESTRestoreToOriginalFoldersOfficeRegionEnum;
    /**
     * Specifies the name of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTRestoreToOriginalFolders
     */
    organizationName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToOriginalFoldersDocumentActionEnum {
    Overwrite = 'Overwrite',
    Keep = 'Keep'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToOriginalFoldersOfficeRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

