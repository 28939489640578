// Copyright © Veeam Software Group GmbH

import type { TeamsChannelItem, TeamsItem } from 'services/models/Teams/TeamsItem';

import { TeamsItemType } from 'services/models/Teams/TeamsItem';
import { checkItemsType } from './check-items-type';
import { RESTOperatorRestoreFilesOptionsFileVersionEnum } from 'api/rxjs';
import { hasPrivateOrSharedChannels } from '../components/RestoreTypeStep';

import type { TeamsRestoreForm } from '../interfaces/teams-restore-form';

export const getInitialFormValue = (items: TeamsItem[]): TeamsRestoreForm => {
    const itemType = checkItemsType(items);

    if (itemType === TeamsItemType.File || itemType === TeamsItemType.Files || itemType === TeamsItemType.Folder) {
        return {
            items,
            reason: '',
            restoreChangedItems: true,
            restoreMissingItems: true,
            restoreOnlyLatest: true,
            fileVersion: RESTOperatorRestoreFilesOptionsFileVersionEnum.Last,
        };
    }

    if (itemType === TeamsItemType.Tab || itemType === TeamsItemType.Tabs) {
        return {
            items,
            reason: '',
            restoreChangedTabs: true,
            restoreMissingTabs: true,
        };
    }

    if (itemType === TeamsItemType.Channel) {
        return {
            items,
            reason: '',
            restoreChangedItems: true,
            restoreMissingItems: true,
            restoreMembers: hasPrivateOrSharedChannels(items as TeamsChannelItem[]) ? true : undefined,
        };
    }

    if (itemType === TeamsItemType.Team) {
        return {
            items,
            reason: '',
            restoreChangedItems: true,
            restoreMissingItems: true,
            restoreMembers: true,
            restoreSettings: true,
        };
    }

    return {
        items,
        reason: '',
    };
};
