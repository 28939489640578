// Copyright © Veeam Software Group GmbH

import { forkJoin, of } from 'rxjs';
import { delayWhen, filter, map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { ExchangeItem, MailboxId, VexSession } from 'services/models';

import { exchangeItemApi } from 'api/rxjs';
import { groupBy } from 'infrastructure/helpers';
import { getDatabase } from 'services/database';
import { removeRestorePointIdFromUniqueId } from 'services/models';

import type { RestoreSessionId } from '../models';


export interface ExchangeRestoreOptions {
    folder?: string;
    changedItems?: boolean;
    deletedItems?: boolean;
    markRestoredAsUnread?: boolean;
    reason: string;
}

const restoreByMailbox = (vexSession: VexSession, mailboxId: MailboxId, items: ExchangeItem[], options: ExchangeRestoreOptions): Observable<RestoreSessionId | undefined> =>
    exchangeItemApi.exchangeItemOperatorRestore({
        mailboxId,
        restoreSessionId: vexSession,
        options: {
            items: items.map(item => ({ id: removeRestorePointIdFromUniqueId(item.uniqId) })),
            ...options,
        },
    }).pipe(
        map(response => response.map(undefined)?.sessionId as RestoreSessionId),
        filter((response): response is RestoreSessionId => !!response),
        delayWhen(sessionId => of(Promise.all(items.map(async item => await (await getDatabase()).recoveryList.setSessionId(item.uniqId, sessionId)))))
    );

export const restoreExchange = (vexSession: VexSession, items: ExchangeItem[], options: ExchangeRestoreOptions): Observable<RestoreSessionId[]> => {
    const groups = groupBy(items, item => item.mailboxId);
    const requests = groups.map(([mailboxId, items]) => restoreByMailbox(vexSession, mailboxId, items, options));
    return forkJoin(requests).pipe(map(responses => responses.filter((xx): xx is RestoreSessionId => !!xx)));
};
