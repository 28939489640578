// tslint:disable
﻿/**
 * The version of the OpenAPI document: v8
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTOrganizationTransferRepository
 */
export interface RESTOrganizationTransferRepository {
    /**
     * Repository id.
     * @type {string}
     * @memberof RESTOrganizationTransferRepository
     */
    id: string;
    /**
     * Repository type.
     * @type {string}
     * @memberof RESTOrganizationTransferRepository
     */
    type: RESTOrganizationTransferRepositoryTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOrganizationTransferRepositoryTypeEnum {
    Local = 'Local',
    ObjectStorage = 'ObjectStorage',
    ArchiveObjectStorage = 'ArchiveObjectStorage'
}

