// Copyright © Veeam Software Group GmbH


import { isEqual } from 'lodash';
import moment from 'moment';

import type {
    ExchangeAppointment,
    ExchangeContact,
    ExchangeMail,
    ExchangeStickyNote,
    ExchangeTask,
    Item,
    OneDriveDocument,
    OneDriveItem,
    SharePointItem,
} from 'services/models';
import type { GetItemsRequest } from 'services/explore/interfaces/get-items-request';
import type { ExploreSortKeys } from 'services/explore/enums/explore-sort-keys';

import { SharePointItemType } from 'services/models';
import { comparators, createSortEngine } from 'infrastructure/client-side-operations';
import { ServiceMemoryCache } from 'infrastructure/serviceCache';
// eslint-disable-next-line import/no-cycle
import { exploreService } from './exploreService';
import { ExchangeMailImportance } from 'services';

import type { TeamsFileItem, TeamsPostItem, TeamsTabItem, TeamsTeamItem } from '../models/Teams';
import type { RESTTeamsPost } from '../../api/rxjs';

const isSortingChanged = (prevParams: GetItemsRequest | undefined, nextParams: GetItemsRequest | undefined): boolean =>
    prevParams?.parent.uniqId === nextParams?.parent.uniqId &&
    isEqual(prevParams?.filter, nextParams?.filter) &&
    isEqual(prevParams?.parent.product, nextParams?.parent.product);

export const exploreItemsCache = new ServiceMemoryCache(exploreService.getProductItems, isSortingChanged);

export const sortExploreItems = createSortEngine<Item, typeof ExploreSortKeys>({
    Title: (one, two) => comparators.string(one.title, two.title),
    SizeBytes: (one, two) => comparators.number((one as OneDriveItem).sizeBytes, (two as OneDriveItem).sizeBytes),
    ModificationTime: (one, two) => comparators.date((one as OneDriveItem).modificationTime, (two as OneDriveItem).modificationTime),
    Version: (one, two) => comparators.number(parseFloat((one as OneDriveItem).version), parseFloat((two as OneDriveItem).version)),
    Subject: (one, two) => comparators.string((one as ExchangeStickyNote).subject, (two as ExchangeStickyNote).subject),
    Date: (one, two) => comparators.date((one as ExchangeStickyNote).date, (two as ExchangeStickyNote).date),
    StartTime: (one, two) => comparators.date((one as ExchangeAppointment).startTime, (two as ExchangeAppointment).startTime),
    EndTime: (one, two) => comparators.date((one as ExchangeAppointment).endTime, (two as ExchangeAppointment).endTime),
    Organizer: (one, two) => comparators.string((one as ExchangeAppointment).organizer, (two as ExchangeAppointment).organizer),
    Location: (one, two) => comparators.string((one as ExchangeAppointment).location, (two as ExchangeAppointment).location),
    Attendees: (one, two) => comparators.string((one as ExchangeAppointment).attendees, (two as ExchangeAppointment).attendees),
    Status: (one, two) => comparators.string((one as ExchangeTask).status, (two as ExchangeTask).status),
    PercentComplete: (one, two) => comparators.number((one as ExchangeTask).percentComplete, (two as ExchangeTask).percentComplete),
    Owner: (one, two) => comparators.string((one as ExchangeTask).owner, (two as ExchangeTask).owner),
    FullName: (one, two) => comparators.string((one as ExchangeContact).fullName, (two as ExchangeContact).fullName),
    Company: (one, two) => comparators.string((one as ExchangeContact).company, (two as ExchangeContact).company),
    JobTitle: (one, two) => comparators.string((one as ExchangeContact).jobTitle, (two as ExchangeContact).jobTitle),
    FileAs: (one, two) => comparators.string((one as ExchangeContact).fileAs, (two as ExchangeContact).fileAs),
    Email: (one, two) => comparators.string((one as ExchangeContact).email, (two as ExchangeContact).email),
    DisplayAs: (one, two) => comparators.string((one as ExchangeContact).displayAs, (two as ExchangeContact).displayAs),
    WebPage: (one, two) => comparators.string((one as ExchangeContact).webPage, (two as ExchangeContact).webPage),
    ImAddress: (one, two) => comparators.string((one as ExchangeContact).imAddress, (two as ExchangeContact).imAddress),
    BusinessPhone: (one, two) => comparators.string((one as ExchangeContact).businessPhone, (two as ExchangeContact).businessPhone),
    From: (one, two) => comparators.string((one as ExchangeMail).from, (two as ExchangeMail).from),
    To: (one, two) => comparators.string((one as ExchangeMail).to, (two as ExchangeMail).to),
    Cc: (one, two) => comparators.string((one as ExchangeMail).cc, (two as ExchangeMail).cc),
    Bcc: (one, two) => comparators.string((one as ExchangeMail).bcc, (two as ExchangeMail).bcc),
    Received: (one, two) => comparators.date((one as ExchangeMail).received, (two as ExchangeMail).received),
    CreatedTime: (one, two) => comparators.date(moment((one as RESTTeamsPost).createdTime), moment((two as RESTTeamsPost).createdTime)),
    Importance: (one, two) => {
        const comparatorWeights = {
            [ExchangeMailImportance.High]: 2,
            [ExchangeMailImportance.Normal]: 1,
            [ExchangeMailImportance.Low]: 0,
        };

        const valOne = comparatorWeights[(one as ExchangeMail).importance];
        const valTwo = comparatorWeights[(two as ExchangeMail).importance];

        return valOne - valTwo;
    },
    IsImportant: (one, two) => {
        const comparatorWeights = {
            'true': 1,
            'false': 0,
        };

        const valOne = comparatorWeights[(one as TeamsPostItem).isImportant.toString()];
        const valTwo = comparatorWeights[(two as TeamsPostItem).isImportant.toString()];

        return valOne - valTwo;
    },
    Attachments: (one, two) => {
        const comparatorWeights = {
            'true': 1,
            'false': 0,
        };

        const hasOneAttachments = (one as TeamsPostItem).attachments.length > 0;
        const hasTwoAttachments = (two as TeamsPostItem).attachments.length > 0;

        const valOne = comparatorWeights[hasOneAttachments.toString()];
        const valTwo = comparatorWeights[hasTwoAttachments.toString()];

        return valOne - valTwo;
    },
    FileType: (one, two) => comparators.string((one as TeamsFileItem).fileType, (two as TeamsFileItem).fileType),
    Type: (one, two) => comparators.string((one as TeamsTabItem).type, (two as TeamsTabItem).type),
    ModifiedBy: (one, two) => comparators.string((one as TeamsFileItem).modifiedBy, (two as TeamsFileItem).modifiedBy),
    Author: (one, two) => comparators.string((one as TeamsPostItem).author, (two as TeamsPostItem).author),
    ContentUrl: (one, two) => comparators.string((one as TeamsTabItem).contentUrl, (two as TeamsTabItem).contentUrl),
    Description: (one, two) => comparators.string((one as TeamsTeamItem).description, (two as TeamsTeamItem).description),
    ItemType: (one, two) => {
        const comparatorWeights = {
            [SharePointItemType.ListFolderItem]: 3,
            [SharePointItemType.ListItem]: 2,
            [SharePointItemType.Folder]: 1,
            [SharePointItemType.Document]: 0,
        };

        const valOne = comparatorWeights[(one as SharePointItem).itemType];
        const valTwo = comparatorWeights[(two as SharePointItem).itemType];

        return valOne - valTwo;
    },
    IsFolder: (one, two) => {
        const comparatorWeights = {
            'true': 1,
            'false': 0,
        };

        const valOne = comparatorWeights[(one as OneDriveDocument)._raw_rest?.isFolder.toString() || 'false'];
        const valTwo = comparatorWeights[(two as OneDriveDocument)._raw_rest?.isFolder.toString() || 'false'];

        return valOne - valTwo;
    },
    Modified: (one, two) => comparators.date(moment((one as TeamsFileItem).modified), moment((two as TeamsFileItem).modified)),
    LastModifiedTime: (one, two) =>
        comparators.date(moment((one as TeamsPostItem).lastModifiedTime), moment((two as TeamsPostItem).lastModifiedTime)),
});

